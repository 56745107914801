import {Component, OnInit, ViewChild} from '@angular/core';
import {CalendarOptions} from '@fullcalendar/core';
import * as moment from 'moment';
import {EventNewComponent} from './modals/event-new/event-new.component';
import {EventEditComponent} from './modals/event-edit/event-edit.component';
import {EventService} from '../_services/event.service';
import {BetweenDates, Event, EventTO, ExtendedProps, Monitor} from '../models/eventRequest';
import {MonitorService} from '../_services/monitor.service';
import {EventsMonitorComponent} from './modals/events-monitor/events-monitor.component';
import {Router} from '@angular/router';

import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'; // a plugin
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // a plugin

import {
  COLOR_AUTOMATIC_AVAILABLE,
  COLOR_AUTOMATIC_RESERVED,
  COLOR_MANUAL_AVAILABLE,
  COLOR_MANUAL_RESERVED,
  COLOR_PERMIS
} from '../global.variables';

declare let $: any;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  popupError?: string;
  calendarEvents: any[] = [];
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, resourceTimelinePlugin, resourceTimeGridPlugin, interactionPlugin],
  };
  newEventDriving: any;
  editEventDriving: any;
  monitorId: string = "";
  monitors: Monitor[] = [];
  calendarInfos: any[] = [];

  @ViewChild(EventNewComponent) eventNewComponent: EventNewComponent | undefined
  @ViewChild(EventEditComponent) eventEditComponent: EventEditComponent | undefined
  @ViewChild(EventsMonitorComponent) eventsMonitorComponent: EventsMonitorComponent | undefined


  handleContentHeight() : number {
      if(screen.width < 577) {
        return 500
      } else {
        return 600
      }
  }

  constructor(private eventService: EventService, private monitorService: MonitorService, private router: Router) {

    if (this.router.url.match("/monitor-calendar")) {
      monitorService.getMyProfile().subscribe((data:Monitor) => {
        this.monitors = [data];
      }, error => {
        console.log(error)
      });
    } else if (this.router.url.match("/monitors/[0-9]+")) {
      this.monitorId = this.router.url.split("/")[2];
      monitorService.getMonitorById(this.monitorId).subscribe((data:Monitor) => {
        this.monitors = [data];
      }, error => {
        console.log(error)
      });
    } else {
      monitorService.getAllMonitors().subscribe((data:Monitor[]) => {
        this.monitors = data.filter(monitor => monitor.enabled === true);
      }, error => {
        console.log(error)
      })
    }
  }

  ngOnInit(): void {
    if (this.monitors.length == 1) {
      this.monitorId = String(this.monitors[0].id);
    }

    this.calendarOptions = {
      plugins: [dayGridPlugin, resourceTimelinePlugin, resourceTimeGridPlugin, interactionPlugin],
      contentHeight: this.handleContentHeight(),
      initialView: 'dayGridWeek',
      businessHours: {
        // days of week. an array of zero-based day of week integers (0=Sunday)
        daysOfWeek: [ 1, 2, 3, 4, 5, 6, 7 ],
        startTime: '08:00',
        endTime: '20:00'
      },
      allDaySlot: false,
      eventClick: this.handleEventClick.bind(this),
      dateClick: this.handleDateClick.bind(this), // bind is important!
      events: this.calendarEvents,
      weekends: true,
      locale: "fr",
      firstDay: 1,
      //businessHours: true,
      fixedWeekCount:false,
      editable:false, // drag and drop,
      dayMaxEvents: true,
      customButtons: {
        generateMonitorCalendar: {
          text: 'Créer des heures',
          click: this.handleMonitorEvents.bind(this)
        },
        getCalendarInformations: {
          text: 'Informations',
          click: this.handleGetInformations.bind(this)
        }
      },
      headerToolbar: {
        left: 'prev,next today dayGridWeek getCalendarInformations',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      },
      datesSet: this.handleDateChange.bind(this)
    }

    if(this.isMobilePhone()) {
      this.calendarOptions.headerToolbar = {}
      this.calendarOptions.initialView = "timeGridDay"
      this.calendarOptions.customButtons = {
        generateMonitorCalendar: {
          text: 'Créer des heures',
            click: this.handleMonitorEvents.bind(this)
        }
      }
    }
  }

  isMobilePhone() : boolean {
    return window.screen.width < 600;
  }

  handleDateClick(event:any) {
    $("#newEventModal").modal("show");
    let date = moment(event.date).format('YYYY-MM-DD');
    let hour = moment(event.date).format('HH:mm');
    $(".modal").find("#date").val(date);
    $(".modal").find("#hour").val(hour);
    this.eventNewComponent?.initNewEventForm(date, hour, this.monitors);
  }

  handleMonitorEvents(event:any) {
    $("#calendarMonitorModal").modal("show");

    this.eventsMonitorComponent?.initEventsMonitorForm(this.monitors);
  }

  handleGetInformations() {
    $("#calendarInformationsModal").modal("show");
  }

  handleDateChange(eventDates:any) {
    let dates : BetweenDates = new BetweenDates(eventDates.start, eventDates.end);

    this.eventService.getEventsBetweenDates(dates, this.monitorId).subscribe(
      (data: any[]) => {
        let events = data.map((item:EventTO) => {
          let props = new ExtendedProps(item.boxType, item.monitor, item.student, item.active, item.description);
          let student = item.student ? item.student.firstname + " " + item.student.lastname : "";
          let monitorName = item.monitor.firstname;
          let eventTitle = student != "" ? student + " | " + monitorName : monitorName;
          let color = student == "" ? COLOR_MANUAL_AVAILABLE : COLOR_MANUAL_RESERVED;
          if (item.boxType == "AUTOMATIQUE") {
            color = student == "" ? COLOR_AUTOMATIC_AVAILABLE : COLOR_AUTOMATIC_RESERVED;
          } else if (item.boxType == "PERMIS") {
            color = COLOR_PERMIS;
          }

          return new Event(
            item.id,
            eventTitle,
            moment.utc(item.startAt).format('YYYY-MM-DD HH:mm'),
            moment.utc(item.startAt).add(1, "hours").format('YYYY-MM-DD HH:mm'),
            props, color);
        });
        this.calendarEvents = events;
        this.calendarOptions.events = [...this.calendarEvents]
      },
      err => {
        console.log(err);
      }
    );

    this.eventService.getInfosBetweenDates(dates, this.monitorId).subscribe(
      (data: any[]) => {
        this.calendarInfos = data;
      },
      err => {
        console.log(err);
      })

  }

  handleEventClick(data:any) {
    let event = data.event;
    $("#editEventModal").modal("show");
    this.eventEditComponent?.initEventEditForm(event, this.monitors);
  }

  addNewEvent($event: EventTO) {
    let item = $event;
    let student = item.student ? item.student.firstname + " " + item.student.lastname : "";
    let monitorName = item.monitor.firstname;
    let eventTitle = student != "" ? student + " | " + monitorName : monitorName;
    let color = student == "" ? COLOR_MANUAL_AVAILABLE : COLOR_MANUAL_RESERVED;
    if ($event.boxType == "AUTOMATIQUE") {
      color = student == "" ? COLOR_AUTOMATIC_AVAILABLE : COLOR_AUTOMATIC_RESERVED;
    } else if ($event.boxType == "PERMIS") {
      color = COLOR_PERMIS;
    }
    let props = new ExtendedProps($event.boxType, $event.monitor, $event.student, $event.active, $event.description);
    let newEvent = new Event(
      $event.id,
      eventTitle,
      moment.utc($event.startAt).format('YYYY-MM-DDTHH:mm'),
      moment.utc($event.startAt).add(1, "hours").format('YYYY-MM-DDTHH:mm'),
      props,
      color
    );

    this.calendarEvents = [...this.calendarEvents, newEvent]
    this.calendarOptions.events = [...this.calendarEvents]

    $('#newEventModal').modal("hide")
  }


  updateEvent($event: any) {
    let item = $event;
    let props = new ExtendedProps($event.boxType, $event.monitor, $event.student, $event.active, $event.description);
    let student = item.student ? item.student.firstname + " " + item.student.lastname : "";
    let monitorName = item.monitor.firstname;
    let eventTitle = student != "" ? student + " | " + monitorName : monitorName;
    let color = student == "" ? COLOR_MANUAL_AVAILABLE : COLOR_MANUAL_RESERVED;
    if ($event.boxType == "AUTOMATIQUE") {
      color = student == "" ? COLOR_AUTOMATIC_AVAILABLE : COLOR_AUTOMATIC_RESERVED;
    } else if ($event.boxType == "PERMIS") {
      color = COLOR_PERMIS;
    }
    let eventUpdated = new Event(
      $event.id,
      eventTitle,
      moment.utc($event.startAt).format('YYYY-MM-DDTHH:mm'),
      moment.utc($event.startAt).add(1, "hours").format('YYYY-MM-DDTHH:mm'),
      props,
      color
    );

    let index = this.calendarEvents.findIndex(e => e.id == $event.id);
    this.calendarEvents[index] = eventUpdated;
    this.calendarOptions.events = [...this.calendarEvents]
    $('#editEventModal').modal("hide")
  }

  deleteEvent($eventId: string) {
    let index = this.calendarEvents.findIndex(e => e.id == $eventId);
    this.calendarEvents.splice(index, 1);
    this.calendarEvents = this.calendarEvents;
    this.calendarOptions.events = [...this.calendarEvents]
    $('#editEventModal').modal("hide")
  }

  getHoursTotal() {
    $('.fc-toolbar-title').mouseover()
  }


}
