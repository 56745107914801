import { Component, OnInit } from '@angular/core';
import {Event, EventTO, ExtendedProps} from '../../models/eventRequest';
import {UserService} from '../../_services/user.service';
import {ProfileService} from '../../_services/profile.service';
import {TokenStorageService} from '../../_services/token-storage.service';
import {COLOR_MANUAL_AVAILABLE, COLOR_MANUAL_RESERVED} from '../../global.variables';
import * as moment from 'moment';

@Component({
  selector: 'app-hours',
  templateUrl: './hours.component.html',
  styleUrls: ['./hours.component.css']
})
export class HoursComponent implements OnInit {
  content?: string;
  studentId = this.myProfileId();
  drivingEvents: Event[] = [];
  hoursConsumedTotal: number = 0;
  pageDrivingEvent: any;

  constructor(private userService: UserService, private profileService: ProfileService,
              private token: TokenStorageService) {
    this.getStudentDrivingEvents(this.studentId, 0, 5);
  }

  ngOnInit(): void {
    this.userService.getUserBoard().subscribe(
      data => {
        this.content = data;
      },
      err => {
        this.content = JSON.parse(err.error).message;
      }
    );
  }

  onChangeDrivingEventPage(pageNumber: number) {
    this.getStudentDrivingEvents(this.studentId, pageNumber, 5);
  }

  getStudentDrivingEvents(studentId: string, page: number, size: number) {
    this.profileService.getMyDrivingEvents(page, size).subscribe(
      (data: any) => {
        let drivingEvents = data.content.map((item:EventTO) => {
          let props = new ExtendedProps(item.boxType, item.monitor, item.student, item.active, item.description);
          let student = item.student ? item.student.firstname + " " + item.student.lastname : "";
          let monitorName = item.monitor.firstname;
          let eventTitle = student != "" ? student + " | " + monitorName : monitorName;
          let color = student == "" ? COLOR_MANUAL_AVAILABLE : COLOR_MANUAL_RESERVED;
          if (new Date(item.startAt).getTime() < new Date().getTime()) {
            this.hoursConsumedTotal++;
          }
          return new Event(
            item.id,
            eventTitle,
            moment.utc(item.startAt).format('YYYY-MM-DD HH:mm'),
            moment.utc(item.startAt).add(1, "hours").format('YYYY-MM-DD HH:mm'),
            props, color);
        });
        this.drivingEvents = drivingEvents;
        this.pageDrivingEvent = data;
      },
      err => {
        console.log(err);
      }
    )
  }

  private myProfileId() : string {
    return this.token.getUser()?.id;
  }

}
