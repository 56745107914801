import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StudentService} from '../../../_services/student.service';
import {EventRequest, Monitor, Student} from '../../../models/eventRequest';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MonitorService} from '../../../_services/monitor.service';
import {EventService} from '../../../_services/event.service';
import * as moment from 'moment';
import {Router} from '@angular/router';

declare let $: any;

@Component({
  selector: 'app-event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.css']
})
export class EventEditComponent implements OnInit {

  currentEvent?: Event;
  monitors?: Monitor[];
  editEventStudents?: Student[];
  student?: Student;
  popupError?: string;
  editEventForm = this.fb.group({
    id: [null, [Validators.required, Validators.min(0)]],
    dateAt: [null, Validators.required],
    hourAt: [null, Validators.required],
    boxType: [null, Validators.required],
    monitorId: [null, Validators.required],
    studentId: [null],
    description: [null]
  });
  hours: string[] = ["07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00"]
  submited: boolean = false;
  removeActive: boolean = false;
  isActive: boolean = false;
  isMobilePhone : boolean = true;

  @Input() editEventDriving: any;
  @Output() editEventDrivingChanged: EventEmitter<any> = new EventEmitter();
  @Output() deleteEventChanged: EventEmitter<string> = new EventEmitter();


  constructor(private fb:UntypedFormBuilder, private studentService: StudentService, private monitorService: MonitorService,
              private eventService: EventService, private router: Router) {
    if(screen.width < 577) {
      this.isMobilePhone = true;
    } else {
      this.isMobilePhone = false;
    }
  }

  ngOnInit(): void {
  }

  initEventEditForm(event: any, monitors: Monitor[]) {
    this.isActive = event.extendedProps.active;
    setTimeout(() => {
      $(".update-modal").prop("disabled", "disabled");
      $("#monitor").prop('disabled', "disabled");
    }, 0);

    let date = moment.utc(event.start).format('YYYY-MM-DD');
    let hour = moment(event.start).format('HH:mm');
    let boxType = event.extendedProps.boxType;
    let monitor = event.extendedProps.monitor;
    let student = event.extendedProps.student;
    let description = event.extendedProps.description;
    this.editEventForm = this.fb.group({
      id: [event.id, Validators.min(0)],
      dateAt: [date, Validators.required],
      hourAt: [hour, [Validators.required, Validators.minLength(4)]],
      boxType: [boxType, Validators.required],
      monitorId: [monitor.id, Validators.required],
      studentId: [student?.id],
      description: [description]
    })

    this.monitors = monitors;
    if (student != null) {
      this.editEventStudents = [student];
      this.student = student;
    }
  }

  searchStudent(name: string) {
    if (name == null || name.length == 0) {
      this.popupError = "Merci de définir un nom !"
    } else {
      this.popupError = "";
      this.editEventForm.value.studentId = "";
      this.studentService.searchStudentByName(name, 0, false).subscribe(
        data => {
          this.editEventStudents = data.content
        },
        err => {
          this.popupError = err;
        }
      );

    }
  }

  onEditEvent(): void {
    let event = this.editEventForm?.value;
    let isPermis = event.boxType == "PERMIS";
    let eventRequest: EventRequest = new EventRequest(event.id, moment.utc(event.dateAt + "T" + event.hourAt),
      event.boxType, event.monitorId, isPermis ? null : event.studentId, event.description);
    $("#inputStudent").val("")
    this.eventService.updateEvent(eventRequest).subscribe(
      data => {
        this.editEventDrivingChanged.emit(data);
      },
      err => {
        this.popupError = err.error.message;
      }
    );

  }

  isSubmited(form: UntypedFormGroup) {
    this.submited = true;

  }

  onEditActive() {
    $(".update-modal").removeAttr('disabled');
  }

  onRemoveActive() {
    this.removeActive = true;
  }

  onDeleteEvent(eventId: string) {
    this.eventService.deleteEvent(eventId).subscribe(
      data => {
        this.deleteEventChanged.emit(eventId);
      },
      err => {
        this.popupError = err.error.message;
      }
    );
  }

  ngAfterViewInit() {
    $("#editEventModal").on("hidden.bs.modal", () => {
      $("#inputStudent").val("")
      this.popupError = "";
      this.removeActive = false;
    });
  }

  goToDriveBook(studentId: bigint) {
    this.router.navigate(['students/' + studentId + "/drive-book"]);
  }
}
