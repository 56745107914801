<div class="modal fade" id="formulaModal" tabindex="-1" role="dialog" aria-labelledby="formulaLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="formulaTitle" name="startAt">
          CREER UNE NOUVELLE FORMULE
        </h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div id="newEventError" class="alert alert-danger mt-4 ml-4 mr-4" role="alert" *ngIf="popupError">
        {{popupError}}
      </div>

      <form [formGroup]="formulaForm" #f="ngForm" name="newEventForm" (ngSubmit)="f.form.valid && onSaveFormula()" novalidate>
        <div>
          <div class="form-row ml-4 p-1 mt-4">
            <div class="input-group raw mb-3">
              <div class="col-6">
                <label for="name" class="col-form-label">NOM DE LA FORMULE</label>
                <input id="name" class="form-control form-control-lg form-control-borderless"
                       name="name" formControlName="name" required aria-describedby="basic-addon2">
                <p class="alert alert-danger p-0" role="alert" *ngIf="submited && f.form.controls.name.status == 'INVALID'">
                  Merci de définir le nom de la formule !
                </p>
              </div>
              <div class="col-4">
                <label for="boxType" class="col-form-label">SELECTIONNER LA BOITE</label>
                <select id="boxType" name="hourAt" formControlName="boxType"
                        class="form-control form-control-lg form-control-borderless overflow-auto update-modal">
                  <option value="MANUELLE">MANUELLE</option>
                  <option value="AUTOMATIQUE">AUTOMATIQUE</option>
                </select>
                <p class="alert alert-danger p-0" role="alert" *ngIf="submited && f.form.controls.boxType.status == 'INVALID'">
                  Merci de définir la boîte
                </p>
              </div>
            </div>
          </div>

        <div class="form-row ml-4 p-1 mt-1">
          <div class="col-4">
            <label for="nbHours" class="col-form-label">NOMBRE D'HEURES</label>
            <input id="nbHours" class="form-control form-control-lg form-control-borderless"
                   name="nbHours" formControlName="nbHours" required aria-describedby="basic-addon2">
            <p class="alert alert-danger p-0" role="alert" *ngIf="submited && f.form.controls.nbHours.status == 'INVALID'">
              Merci de définir le nombre d'heure de conduite !
            </p>
          </div>
          <div class="col-4">
            <label for="price" class="col-form-label">PRIX</label>
            <input id="price" type="number" class="form-control form-control-lg form-control-borderless"
                   name="price" formControlName="price" required aria-describedby="basic-addon2" min="1">
            <p class="alert alert-danger p-0" role="alert" *ngIf="submited && f.form.controls.price.status == 'INVALID'">
              Merci de définir un prix !
            </p>
          </div>
        </div>

        <div class="form-row ml-4 p-1 mt-1">
          <div class="col-4">
            <label for="formula-description" class="col-form-label">AUTORISER</label>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="roomAccess" formControlName="roomAccess">
              <label class="form-check-label" for="roomAccess">
                L'accès à la salle de code
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="aac" formControlName="aac">
              <label class="form-check-label" for="aac">
                La conduite Accompagnée
              </label>
            </div>
          </div>
          <div class="col-6">
            <label for="formula-description" class="col-form-label">DESCRIPTION</label>
            <textarea class="form-control" id="formula-description" name="description" formControlName="description"  rows="3"></textarea>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">ANNULER</button>
          <button type="button" class="btn btn-primary" type="submit" (click)="isSubmited(f.form)">VALIDER</button>
        </div>
        </div>
      </form>
    </div>
  </div>
</div>
