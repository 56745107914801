<div class="container mt-4">
  <div class="bg-light p-2 pl-5 mb-4 rounded" >
    <h3>CONFIGURATIONS DE L'AUTO ECOLE</h3>
  </div>
  <div class="bg-light p-2 pl-5 mb-4 rounded" >
    <button type="button" class="ml-5 btn" [ngClass]="showFormulas ? 'btn-outline-primary' : 'btn-primary'"
            (click)="showFormulas = !showFormulas">
      FORMULES
    </button>
    <button type="button" class="ml-5 btn" [ngClass]="showArticle ? 'btn-outline-primary' : 'btn-primary'"
            (click)="showArticle = !showArticle">
      ARTICLES
    </button>
  </div>
  <app-article-list [iArticles]="articles" [iShowArticle]="showArticle"></app-article-list>
  <app-formula-list [iFormulas]="formulas" [iShowFormulas]="showFormulas"></app-formula-list>
</div>
