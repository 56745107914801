import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

const MY_PROFILE_API = environment.APIEndpoint + '/api/my-profile';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private http: HttpClient) { }

  saveProfile(studentRequest: any): Observable<any> {
    return this.http.post(MY_PROFILE_API, studentRequest, httpOptions);
  }

  getMyProfile() : Observable<any> {
    return this.http.get(MY_PROFILE_API, httpOptions);
  }

  getMyPayments(page: number, size: number) : Observable<any> {
    return this.http.get(MY_PROFILE_API + "/payments?page=" + page + "&size=" + size + "&sort=date,DESC", httpOptions);
  }

  getMyTotalPayments() : Observable<any> {
    return this.http.get(MY_PROFILE_API + "/payments/total", httpOptions);
  }

  getMyConsumptions(page: number, size: number) : Observable<any> {
    return this.http.get(MY_PROFILE_API + "/consumptions?page=" + page + "&size=" + size + "&sort=date,DESC", httpOptions);
  }

  getMyDrivingEvents(page: number, size: number) : Observable<any> {
    return this.http.get(MY_PROFILE_API + "/driving-events?page=" + page + "&size=" + size + "&sort=startAt,DESC", httpOptions);
  }

  getMyTotalConsumptions() : Observable<any> {
    return this.http.get(MY_PROFILE_API + "/consumptions/total", httpOptions);
  }

  getMyTotalHoursConsumed() : Observable<any> {
    return this.http.get(MY_PROFILE_API + "/number-hours-consumed", httpOptions);
  }

  getMyNumberHoursPurchased()  : Observable<any> {
    return this.http.get(MY_PROFILE_API + "/number_hours", httpOptions);
  }

  getMySuiviDrive(): Observable<any> {
    return this.http.get(MY_PROFILE_API + '/drive-book', httpOptions);
  }

  getDriveBookScore(): Observable<any> {
    return this.http.get(MY_PROFILE_API + '/drive-book-score', httpOptions);
  }

  getMyDrivingComments() {
    return this.http.get(MY_PROFILE_API + '/driving-comments', httpOptions);
  }
}
