import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import {DateService} from './date.service';
import {dateComparator} from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-tools';

const NOTES_API = environment.APIEndpoint + '/api/notes';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class NoteService {
  constructor(private dateService: DateService, private http: HttpClient) { }

  createNote(noteRequest: any): Observable<any> {
    return this.http.post(NOTES_API, noteRequest, httpOptions);
  }

  getMyNotes(page: number, size: number): Observable<any> {
    return this.http.get(NOTES_API + "/user?page=" + page + "&size=" + size + "&sort=date,DESC", httpOptions);
  }

  getNotesByStudentId(studentId: bigint | string, page: number, size: number): Observable<any> {
    return this.http.get(NOTES_API + "/student/" + studentId + "?page=" + page + "&size=" + size + "&sort=date,DESC", httpOptions);
  }

  deleteNoteById(id: bigint) : Observable<any> {
    return this.http.delete(NOTES_API + "/" + id, httpOptions);
  }
}
