import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Article, Consumption, Student} from '../../models/eventRequest';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ConsumptionService} from '../../_services/consumption.service';
import * as moment from 'moment';

declare let $ : any;

@Component({
  selector: 'app-consumption-form',
  templateUrl: './consumption-form.component.html',
  styleUrls: ['./consumption-form.component.css']
})
export class ConsumptionFormComponent implements OnInit {

  consumptionForm = this.resetForm();

  @Input()
  iStudent?: Student;

  @Input()
  iArticles: Article[] = [];

  @Input()
  iNavMobile: boolean = false;

  @Output()
  oNewConsumption: EventEmitter<Consumption> = new EventEmitter();

  consumptionSubmited: boolean = false;
  popupError: string = "";

  constructor(private fb:UntypedFormBuilder, private consumptionService: ConsumptionService) { }

  ngOnInit(): void {
  }

  onCreateConsumption() {
    let consumption = this.consumptionForm?.value;
    this.consumptionService.createConsumption(consumption).subscribe(
      consumption => {
        this.oNewConsumption.emit(consumption);
        this.consumptionForm = this.resetForm();
        this.consumptionSubmited = false;
        $("#newConsumptionModal").modal("hide");
      },
      err => {
        this.popupError = err.error.message;
      }
    );
  }

  isConsumptionSubmited(form: UntypedFormGroup) {
    this.consumptionSubmited = true;
    this.consumptionForm.controls['studentId'].setValue(this.iStudent?.id)
  }

  resetForm() : UntypedFormGroup{
    return this.fb.group({
      date: [moment(new Date()).format('YYYY-MM-DD'), Validators.required],
      studentId: [null, Validators.required],
      articleId: [null, Validators.required],
      quantity: [1, Validators.required],
      description: [null]
    });
  }

}
