import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'circle-percentage',
  templateUrl: './circle-percentage.component.html',
  styleUrls: ['./circle-percentage.component.css']
})
export class CirclePercentageComponent implements OnInit {

  /*
  BLEU   : #26c4ec
  ROSE   : #fd3f92
  VERT   : #57d53b
  ORANGE : #ff7f14
  */

  @Input()
  title: string = "";

  @Input()
  progress: number = 0;

  @Input()
  color: string = "#4882c2";

  @Input()
  text: string = "";

  constructor() { }

  ngOnInit(): void {
  }

}
