import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BetweenDates, Purchase} from '../models/eventRequest';
import {environment} from './../../environments/environment';
import * as moment from 'moment/moment';
import {DateService} from './date.service';

const PURCHASE_API = environment.APIEndpoint + '/api/purchases';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {
  constructor(private dateService: DateService, private http: HttpClient) { }


  addPurchase(purchase: Purchase): Observable<any> {
    return this.http.post(PURCHASE_API , purchase, httpOptions);
  }

  getPurchasesBetweenDates(dates: BetweenDates): Observable<any> {
    const datesString = this.dateService.getBetweenDatesString(dates);
    return this.http.post(PURCHASE_API + '/between-dates', datesString, httpOptions);
  }

}
