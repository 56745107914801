import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';


declare let $: any;

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  @Input()
  iEmpty: EventEmitter<boolean> = new EventEmitter();

  @Input()
  iFirst: EventEmitter<boolean> = new EventEmitter();

  @Input()
  iLast: EventEmitter<boolean> = new EventEmitter();

  @Input()
  iNumber: number = 0;

  @Input()
  iTotalPages: number = 0;

  @Output() currentPageChanged: EventEmitter<number> = new EventEmitter();



  constructor() { }

  ngOnInit(): void {
  }

  changePage(page: string) {
    let pageNumber = this.iNumber;
    switch (page) {
      case 'first': pageNumber = 0; break;
      case 'previous': pageNumber -= 1; break;
      case 'next': pageNumber += 1; break;
      case  'last': pageNumber = this.iTotalPages-1;
    }
    this.currentPageChanged.emit(pageNumber);
  }
}
