import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ToolsService} from '../../../_services/tools.service';

declare let $ : any;

@Component({
  selector: 'app-article-form',
  templateUrl: './article-form.component.html',
  styleUrls: ['./article-form.component.css']
})
export class ArticleFormComponent implements OnInit {

  articleForm = this.resetForm();
  popupError: string = "";
  submited: boolean = false;

  @Output() oArticleSaved: EventEmitter<any> = new EventEmitter();

  constructor(private fb:UntypedFormBuilder, private toolsService: ToolsService) { }

  ngOnInit(): void {
  }

  onSaveArticle() {
    this.toolsService.saveArticle(this.articleForm.value).subscribe(data => {
      this.oArticleSaved.emit(data);
    })
    return true;
  }

  isSubmited(form: UntypedFormGroup) {
    this.submited = true;
  }

  ngAfterViewInit() {
    $("#articleModal").on("hidden.bs.modal", () => {
      this.articleForm = this.resetForm();
      this.submited = false;
      this.popupError = "";
    });
  }

  resetForm() : UntypedFormGroup{
    return this.fb.group({
      name: [null, Validators.required],
      price: [null, Validators.required],
      type: ['ARTICLE', Validators.required],
      nbHours: [0, Validators.required],
      description: [null],
      roomAccess: [false, Validators.required],
      aac: [false, Validators.required],
      boxType: [null, Validators.required],
    });
  }
}
