import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BetweenDates, Consumption, ConsumptionTO} from '../models/eventRequest';
import {environment} from './../../environments/environment';
import {DateService} from './date.service';

const CONSUMPTION_API = environment.APIEndpoint + '/api/consumptions';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ConsumptionService {
  constructor(private dateService: DateService, private http: HttpClient) { }

  createConsumption(consumptionRequest: ConsumptionTO): Observable<any> {
    return this.http.post(CONSUMPTION_API, consumptionRequest, httpOptions);
  }

  getConsumptionsBetweenDates(dates: BetweenDates, page: number, size: number): Observable<any> {
    const datesString = this.dateService.getBetweenDatesString(dates);
    return this.http.post(CONSUMPTION_API + '/between-dates?page=' + page + '&size=' + size + '&sort=date,DESC' , datesString, httpOptions);
  }

  getTotalConsumptionsBetweenDates(dates: BetweenDates): Observable<any> {
    const datesString = this.dateService.getBetweenDatesString(dates);
    return this.http.post(CONSUMPTION_API + '/total' , datesString, httpOptions);
  }

  updateConsumption(consumptionToUpdate: Consumption): Observable<any> {
    return this.http.put(CONSUMPTION_API + '/' + consumptionToUpdate.id, consumptionToUpdate, httpOptions);
  }
}
