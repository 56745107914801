import {Component, ElementRef, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MonitorService} from '../../_services/monitor.service';
import {Router} from '@angular/router';
import {Monitor} from '../../models/eventRequest';

declare let $: any;

@Component({
  selector: 'app-monitor-save',
  templateUrl: './monitor-save.component.html',
  styleUrls: ['./monitor-save.component.css']
})
export class MonitorSaveComponent implements OnInit {

  monitorForm = this.fb.group({});
  monitorName = "";
  messageError: string = "";
  isSaved: boolean = false;
  canSubmit: boolean = false;
  showMonitorPlug: boolean = false;
  monitorId = this.router.url.split("/")[2];
  monitor?: Monitor;


  constructor(private fb:UntypedFormBuilder, private monitorService: MonitorService, private elementRef: ElementRef, private router: Router) {

    $(".input-form").prop('disabled', true);
  }

  ngOnInit(): void {
    this.monitorService.getMonitorById(this.monitorId).subscribe(
      (data: Monitor) => {
        this.monitor = data
        this.monitorForm = this.getMonitorForm(data)
        this.monitorName = data.firstname + " " + data.lastname
      },
      err => {
        this.messageError = err.error.message;
      })
  }

  onEditMonitor() {
    let monitorForm = this.monitorForm.value;
      this.monitorService.saveMonitor(monitorForm, monitorForm.id).subscribe(
        data => {
          this.messageError = "";
          this.isSaved = true;
          this.canSubmit = false;
          this.monitorForm = this.getMonitorForm(data)
        },
        err => {
          this.messageError = err.error.message;
        }
      );
    this.monitorForm.disable()
    $(".input-form").prop('disabled', true);
    return true;
  }

  private getMonitorForm(data: any) {
    return this.fb.group({
      id: data.id,
      sexe: [{value:data.sexe, disabled: true}, Validators.required],
      firstname: [{value: data.firstname, disabled: true}, Validators.required],
      lastname: [{value:data.lastname, disabled: true}, Validators.required],
      email: [{value:data.email, disabled: true}, [Validators.required, Validators.email]],
      phoneNumber: [{value:data.phoneNumber, disabled: true}, [Validators.required, Validators.pattern('0[0-9]{9}')]],
      color: [{value:data.color, disabled: true}]
    });
  }

  isSubmited(form: UntypedFormGroup) {
  }

  onEditActive() {
    this.monitorForm.enable();
    this.isSaved = false;
    this.canSubmit = true;
    $(".input-form").removeAttr('disabled');
  }

  onShowMoniteurPlug() {
    this.showMonitorPlug = !this.showMonitorPlug;
    this.isSaved = false;
    this.messageError = "";
  }

  onOpenUserForm() {
    $("#updateUserForm").modal("show");
  }
}

