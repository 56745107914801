<div>

  <div class="bg-light p-2 pl-5 mb-4 rounded" *ngIf="!iNavMobile">
    <h4>Mes Rappels <span *ngIf="pageNote">({{pageNote.totalElements}})</span>
      <button type="button" class="ml-5 btn" [ngClass]="showNotes ? 'btn-outline-primary' : 'btn-primary'"
              (click)="showNotes = !showNotes">
        {{showNotes ? 'CACHER' : 'AFFICHER'}}
      </button>
      <button type="button" class="btn btn-outline-primary ml-3" (click)="onAddNote()" [disabled]="!showNotes">AJOUTER</button>
      <button type="button" class="ml-5 btn"
              [ngClass]="{'btn-warning': iFolderStatus === 0, 'btn-info': iFolderStatus === 1, 'btn-success': iFolderStatus === 2, 'btn-danger': iFolderStatus === 3}"
              (click)="onUpdateStudentFolderStatus(iFolderStatus+1)">
        {{
          iFolderStatus === 1 ? 'DOSSIER EN COURS' :
          iFolderStatus === 2 ? 'DOSSIER VALIDER' :
          iFolderStatus === 3 ? 'DOSSIER A REVOIR' : 'DOSSIER A TRAITER'
        }}
      </button>
    </h4>
  </div>
  <div *ngIf="showNotes">
    <table class="table">
      <thead>
      <tr class="row">
        <th scope="col" class="col-auto">#</th>
        <th scope="col" class="col-2">DATE</th>
        <th scope="col" class="col-8 align-content-center">DESCRIPTION</th>
        <th scope="col" class="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let n of notes" class="row">
        <th class="col-auto" scope="row">{{n.id}}</th>
        <td class="col-2">{{n.date | date:'dd/MM/YYYY HH:mm'}}</td>
        <td class="col-8 align-content-center">{{n.description}}</td>
        <td class="col-1">
          <button class="btn btn-danger btn-sm rounded-0" type="button" (click)="onDelete(n.id)" data-toggle="tooltip" data-placement="top" title="Delete">
            <i class="fa fa-trash"></i>
          </button>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <app-pagination *ngIf="pageNote" [iEmpty]="pageNote.empty" [iFirst]="pageNote.first"
                      [iLast]="pageNote.last" [iNumber]="pageNote.number" [iTotalPages]="pageNote.totalPages"
                      (currentPageChanged)="onChangeNotePage($event)" >
      </app-pagination>
      </tfoot>
    </table>
  </div>



  <div class="modal fade" id="noteModal" tabindex="-1" role="dialog" aria-labelledby="noteLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="noteTitle" name="startAt">
            AJOUTER UNE NOTE
          </h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div id="noteError" class="alert alert-danger mt-4 ml-4 mr-4" role="alert" *ngIf="popupError && popupError.length > 0">
          {{popupError}}
        </div>

        <form [formGroup]="noteForm" #f="ngForm" name="newNoteForm" (ngSubmit)="f.form.valid && onCreateNote()" novalidate>
          <div>
            <div class="form-row ml-4 p-1 mt-4">
              <div class="input-group raw mb-3">
                <div class="col-4">
                  <label for="note-date" class="col-form-label">CHOISIR LA DATE</label>
                  <input id="note-date" type="date" class="form-control form-control-lg form-control-borderless"
                         name="date" formControlName="date" required aria-describedby="basic-addon2">
                  <p class="alert alert-danger p-0" role="alert" *ngIf="noteSubmited && f.form.controls.date.status == 'INVALID'">
                    Merci de définir la date !
                  </p>
                </div>
              </div>
            </div>
            <div class="form-row ml-4 p-1 mt-1">
              <div class="input-group raw mb-3">
                <div class="col-8">
                  <label for="noteDescription" class="col-form-label">DESCRIPTION</label>
                  <textarea class="form-control" id="noteDescription" name="description" formControlName="description"  rows="6"></textarea>
                  <p class="alert alert-danger p-0" role="alert" *ngIf="noteSubmited && f.form.controls.description.status == 'INVALID'">
                    Merci de définir la description !
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">ANNULER</button>
            <button type="button" class="btn btn-primary" type="submit" (click)="isNoteSubmited(f.form)">VALIDER</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
