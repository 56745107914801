<div class="container mt-4">
  <div class="bg-light p-2 pl-5 mt-4 mb-4 rounded" >
    <h3>CREER UN ELEVE</h3>
  </div>
  <div class="alert alert-danger" role="alert" *ngIf="messageError != null && messageError.length > 0">
    <p class="alert-heading mb-0">Une erreur a été rencontrée : {{messageError}}</p>
    <hr>
  </div>
  <form class="needs-validation" [formGroup]="newStudentForm" #f="ngForm" name="editEventForm"
      (ngSubmit)="f.form.valid && onCreateStudent()" novalidate>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label for="madam"></label>
        <div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="sexe" formControlName="sexe" id="madam" value="F">
          <label class="form-check-label" for="madam">MADAME</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="sexe" formControlName="sexe" id="sir" value="M">
          <label class="form-check-label" for="sir">MONSIEUR</label>
        </div>
        </div>
      </div>
      <div class="col-md-4 mb-3">
        <label for="formulaId">Formule</label>
        <select id="formulaId" class="form-control form-control-borderless" formControlName="formulaId"> <!--(change)="selectMonitor($event)" -->
          <option [ngValue]="null" disabled>Choisir une formule</option>
          <option *ngFor="let f of formulas" [ngValue]="f.id" >{{f.name}} - {{f.price}} €</option>
        </select>
        <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.formulaId.status == 'INVALID')">
          Merci de choisir une formule !
        </p>
      </div>
      <div class="col-md-4 mb-3">
        <label for="neph">NEPH</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">N</span>
          </div>
          <input type="text" class="form-control" id="neph" formControlName="neph" placeholder="Numéro NEPH">
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label for="name">Nom</label>
        <input type="text" class="form-control" id="name" formControlName="lastname" placeholder="Nom" required>
        <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.lastname.status == 'INVALID')">
          Merci de définir le prénom !
        </p>
      </div>
      <div class="col-md-4 mb-3">
        <label for="firstname">Prénom</label>
        <input type="text" class="form-control" id="firstname" formControlName="firstname" placeholder="Prénom" required>
        <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.firstname.status == 'INVALID')">
          Merci de définir le nom !
        </p>
      </div>
      <div class="col-md-4 mb-3">
        <label for="email">Email</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroupPrepend">@</span>
          </div>
          <input type="email" class="form-control" id="email" formControlName="email" placeholder="Email" required>
        </div>
        <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.email.status === 'INVALID')">
          Email invalide ou déjà utilisé !
        </p>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label for="birthDate">Date De Naissance</label>
        <input type="date" class="form-control" id="birthDate" formControlName="birthDate" placeholder="Date De Naissance" required>
        <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.birthDate.status == 'INVALID')">
          Merci de définir la date de naissance !
        </p>
      </div>
      <div class="col-md-4 mb-3">
        <label for="birthPlace">Lieu De Naissance</label>
        <input type="text" class="form-control" id="birthPlace" formControlName="birthPlace" placeholder="Lieu De Naissance" required>
        <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.birthPlace.status == 'INVALID')">
          Merci de définir le lieu de naissance !
        </p>
      </div>
      <div class="col-md-4 mb-3">
        <label for="phoneNumber">Téléphone</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">T</span>
          </div>
          <input type="text" class="form-control" id="phoneNumber" formControlName="phoneNumber" placeholder="Téléphone" required>
        </div>
        <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.phoneNumber.status == 'INVALID')">
          Merci de définir un numéro de téléphone !
        </p>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6 mb-3">
        <label for="address">Adresse</label>
        <input type="text" class="form-control" id="address" formControlName="address" placeholder="Adresse" required>
        <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.address.status == 'INVALID')">
          Merci de définir l'adresse
        </p>
      </div>
      <div class="col-md-3 mb-3">
        <label for="postalCode">Code Postal</label>
        <input type="text" class="form-control" id="postalCode" formControlName="postalCode" placeholder="Code Postal" required>
        <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.postalCode.status == 'INVALID')">
          Merci de définir le code postal !
        </p>
      </div>
      <div class="col-md-3 mb-3">
        <label for="city">Ville</label>
        <input type="text" class="form-control" id="city" formControlName="city" placeholder="Ville" required>
        <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.city.status == 'INVALID')">
          Merci de définir la ville !
        </p>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-3 mb-3">
        <label for="evalDate">Date De L'évaluation</label>
        <input type="date" class="form-control" id="evalDate" formControlName="evaluationDate" placeholder="Date De l'évaluation">
      </div>
      <div class="col-md-3 mb-3">
        <label for="evaluationHours">Nombre d'heure évaluée</label>
        <input type="number" class="form-control" id="evaluationHours" formControlName="evaluationHours" placeholder="nombre d'heure évaluée">
      </div>
      <div class="col-md-3 mb-3">
        <label for="agreedNumberHours">Nombre d'heure convenue</label>
        <input type="number" class="form-control" id="agreedNumberHours" formControlName="agreedNumberHours" placeholder="nombre d'heure convenue">
      </div>
      <div class="col-md-3 mb-3">
        <label for="hasCode">L'éléve a eu le code</label>
        <select id="hasCode" class="form-control form-control-borderless" formControlName="hasCode"> <!--(change)="selectMonitor($event)" -->
          <option [ngValue]="null" disabled></option>
          <option [ngValue]="false">NON</option>
          <option [ngValue]="true">OUI</option>
        </select>
        <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.hasCode.status == 'INVALID')">
          Merci de définir si l'éléve a eu le code !
        </p>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-3 mb-3">
        <label for="registrationDate">Date D'Inscription</label>
        <input type="date" class="form-control" id="registrationDate" formControlName="registrationDate" placeholder="Date D'inscription" readonly>
      </div>
      <div class="col-md-3 mb-3">
        <label for="contractDate">Date Du Contrat</label>
        <input type="date" class="form-control" id="contractDate" formControlName="contractDate" placeholder="Date De la signature">
      </div>
      <div class="col-md-3 mb-3">
        <label for="settlementDate">Date Du Réglement Intérieur</label>
        <input type="date" class="form-control" id="settlementDate" formControlName="settlementDate" placeholder="Date De la signature">
      </div>
    </div>
    <div class="form-row">
      <button class="btn btn-primary" type="submit" (click)="isSubmited(f.form)">CREER UN NOUVEL ELEVE</button>
    </div>
  </form>
</div>
