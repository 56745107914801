import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {StudentService} from '../../_services/student.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {ToolsService} from '../../_services/tools.service';

declare let $: any;

@Component({
  selector: 'app-student-save',
  templateUrl: './student-save.component.html',
  styleUrls: ['./student-save.component.css']
})
export class StudentSaveComponent implements OnInit {

  studentId : string = this.router.url.split("/")[2];
  isNewStudent: boolean = false;
  studentForm = this.fb.group({
    id: [{value:null, disabled:true}],
    sexe: [{value:null, disabled:true}, Validators.required],
    formulaId: [{value:null, disabled:true}, Validators.required],
    neph: [{value:null, disabled:true}],
    firstname: [{value:null, disabled:true}, Validators.required],
    lastname: [{value:null, disabled:true}, Validators.required],
    email: [{value:null, disabled:true}, [Validators.required, Validators.email]],
    birthDate: [{value:null, disabled:true}, Validators.required],
    birthPlace: [{value:null, disabled:true}, Validators.required],
    phoneNumber: [{value:null, disabled:true}, [Validators.required, Validators.pattern("0[0-9]{9}")]],
    address: [{value:null, disabled:true}, Validators.required],
    postalCode: [{value:null, disabled:true}, Validators.required],
    city: [{value:null, disabled:true}, Validators.required],
    registrationDate: [{value:null, disabled:true}, Validators.required],
    evaluationDate: [{value:null, disabled:true}],
    evaluationHours:[{value:null, disabled:true}],
    agreedNumberHours:[{value:null, disabled:true}],
    contractDate: [{value:null, disabled:true}],
    settlementDate: [{value:null, disabled:true}],
    hasCode: [{value:null, disabled:true}]
  });
  formulas?: any[];
  canSubmit: boolean = false;
  isSaved: boolean = false;
  messageError: string = "";

  constructor(private fb:UntypedFormBuilder, private studentService: StudentService, private toolsService: ToolsService,
              private router: Router) {

    studentService.getStudentsById(this.studentId).subscribe(
      student => {
        this.studentForm = this.getStudentForm(student);
        this.isNewStudent = student.status === "TO_CREATE";
        this.studentForm.disable();
      },
      err => {
        this.messageError = err.error.message;
      });

    toolsService.getActiveFormulas().subscribe(
      formulas => {
        this.formulas = formulas;
      },
      err => {
        this.messageError = err.error.message;
      });
    $(".input-form").prop('disabled', true);
  }

  ngOnInit(): void {
  }

  onEditStudent() {
    if (!this.studentForm.valid) {
      return;
    }
    if (this.studentForm.controls["contractDate"].value != null
      && !moment(this.studentForm.controls["contractDate"].value).isValid()) {
      this.studentForm.patchValue({"contractDate": null})
    }
    if (this.studentForm.controls["settlementDate"].value != null
      && !moment(this.studentForm.controls["settlementDate"].value).isValid()) {
      this.studentForm.patchValue({"settlementDate": null})
    }

    let studentForm = this.studentForm.value;
    this.studentService.saveStudent(studentForm).subscribe(
      data => {
        this.messageError = "";
        this.isSaved = true;
        this.canSubmit = false;
        this.studentForm = this.getStudentForm(data)
        $(".input-form").prop('disabled', true);
      },
      err => {
        this.messageError = err.error.message;
      }
    );

    return true;
  }

  isSubmited(form: UntypedFormGroup) {
  }

  private getStudentForm(data: any) {
    this.studentForm.disable();
    return this.fb.group({
      id: data.id,
      sexe: data.sexe,
      formulaId: data.formula.id,
      neph: data.neph,
      firstname: data.firstname,
      lastname: data.lastname,
      email: [data.email],
      birthDate: moment.utc(data.birthDate).format('YYYY-MM-DD'),
      birthPlace: data.birthPlace,
      phoneNumber: data.phoneNumber,
      address: data.address,
      postalCode: data.postalCode,
      city: data.city,
      registrationDate: data.registrationDate ? moment.utc(data.registrationDate).format('YYYY-MM-DD') : null,
      contractDate: data.contractDate ? moment.utc(data.contractDate).format('YYYY-MM-DD') : null,
      evaluationDate: data.evaluationDate ? moment.utc(data.evaluationDate).format('YYYY-MM-DD') : null,
      evaluationHours:[data.evaluationHours],
      agreedNumberHours:[data.agreedNumberHours],
      settlementDate: data.settlementDate ? moment.utc(data.settlementDate).format('YYYY-MM-DD') : null,
      hasCode: data.hasCode
    });
  }

  onEditActive() {
    this.studentForm.enable();
    this.isSaved = false;
    this.canSubmit = true;
    $(".input-form").removeAttr('disabled');
  }

  uploadIdentificationFile(studentId: bigint) {
    this.studentService.uploadIdentificationFile(studentId).subscribe(
      data => {
        var file = new Blob([data], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      },
      error => {
        console.log('Error downloading the file.')
        console.log(error)
      });
  }

  uploadContract(studentId: bigint) {
    this.studentService.uploadContract(studentId).subscribe(
      data => {
        var file = new Blob([data], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      },
      error => {
        console.log('Error downloading the file.')
        console.log(error)
      });
  }
}
