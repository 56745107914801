import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from './../../environments/environment';
import {BetweenDates} from '../models/eventRequest';
import {DateService} from './date.service';

const STUDENT_API = environment.APIEndpoint + '/api/students';
const DRIVING_COMMENTS_API = environment.APIEndpoint + '/api/comments';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  constructor(private dateService: DateService, private http: HttpClient) { }

  createStudent(studentRequest: any): Observable<any> {
    studentRequest.registrationDate = this.dateService.getDateNow();
    return this.http.post(STUDENT_API + '/signup', studentRequest, httpOptions);
  }

  saveStudent(studentRequest: any): Observable<any> {
    return this.http.post(STUDENT_API + '/' + studentRequest.id, studentRequest, httpOptions);
  }

  updateStudentStatus(newStatusKey: any, studentId: any): Observable<any> {
    return this.http.put(STUDENT_API + '/' + studentId + '/status', {statusKey: newStatusKey}, httpOptions);
  }

  searchStudentByName(name: string, pageNumber: number, isArchive: boolean): Observable<any> {
    return this.http.post(STUDENT_API + '/search?' + 'page=' + pageNumber + '&size=20'
      + '&name=' + name + '&isArchive=' + isArchive, httpOptions);
  }

  getStudentsById(id: string): Observable<any> {
    return this.http.get(STUDENT_API + '/' + id, httpOptions);
  }

  getStudentPayments(id: string, page: number, size: number): Observable<any> {
    return this.http.get(STUDENT_API + '/' + id + '/payments?page=' + page + '&size=' + size + '&sort=date,DESC', httpOptions);
  }

  getStudentTotalPayments(id: string): Observable<any> {
    return this.http.get(STUDENT_API + '/' + id + '/payments/total', httpOptions);
  }

  getStudentConsumptions(id: string, page: number, size: number): Observable<any> {
    return this.http.get(STUDENT_API + '/' + id + '/consumptions?page=' + page + '&size=' + size + '&sort=date,DESC', httpOptions);
  }

  getStudentDrivingEvents(id: string, page: number, size: number): Observable<any> {
    return this.http.get(STUDENT_API + '/' + id + '/driving-events?page=' + page + '&size=' + size + '&sort=startAt,DESC', httpOptions);
  }

  getStudentTotalConsumptions(id: string): Observable<any> {
    return this.http.get(STUDENT_API + '/' + id + '/consumptions/total', httpOptions);
  }

  getNumberHoursPurchasedByStudentId(id: string): Observable<any> {
    return this.http.get(STUDENT_API + '/' + id + '/number_hours', httpOptions);
  }

  getNumberHoursConsumedByStudentId(id: string): Observable<any> {
    return this.http.get(STUDENT_API + '/' + id + '/number-hours-consumed', httpOptions);
  }

  getStudentsBetweenDates(dates: BetweenDates, page: number, size: number): Observable<any> {
    const datesString = this.dateService.getBetweenDatesString(dates);
    return this.http.post(STUDENT_API + '/between-dates?page=' + page + '&size=' + size + '&sort=registrationDate,DESC' , datesString, httpOptions);
  }

  uploadContract(studentId: bigint): Observable<any> {
    return this.http.get(STUDENT_API + '/' + studentId + '/contract', { responseType: 'arraybuffer' });
  }

  uploadIdentificationFile(studentId: bigint): Observable<any> {
    return this.http.get(STUDENT_API + '/' + studentId + '/identification', { responseType: 'arraybuffer' });
  }

  saveSuiviDrive(studentId: any, bookId: string, driveBookRequest: any): Observable<any> {
    return this.http.post(STUDENT_API + '/' + studentId + '/drive-book?bookId=' + bookId, driveBookRequest, httpOptions);
  }

  getSuiviDrive(studentId: any): Observable<any> {
    return this.http.get(STUDENT_API + '/' + studentId + '/drive-book', httpOptions);
  }

  getDriveBookScore(studentId: any): Observable<any> {
    return this.http.get(STUDENT_API + '/' + studentId + '/drive-book-score', httpOptions);
  }

  getStudentDrivingComments(studentId: string) {
    return this.http.get(DRIVING_COMMENTS_API + '/students/' + studentId, httpOptions);
  }

  saveDrivingComment(commentForm: any) {
    return this.http.post(DRIVING_COMMENTS_API, commentForm, httpOptions);
  }

  deleteStudentById(studentId: string) {
    return this.http.delete(STUDENT_API + '/' + studentId, httpOptions);
  }

  updateFolderStatus(studentId: string, todo: number) {
    return this.http.get(STUDENT_API + '/' + studentId + '/folder?status=' + todo, httpOptions);
  }
}
