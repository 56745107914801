import {Component, OnInit} from '@angular/core';
import {UserService} from '../_services/user.service';
import {Consumption, Event, EventTO, ExtendedProps, Payment, Student} from '../models/eventRequest';
import * as moment from 'moment';
import {ProfileService} from '../_services/profile.service';
import {TokenStorageService} from '../_services/token-storage.service';
import {COLOR_MANUAL_AVAILABLE, COLOR_MANUAL_RESERVED} from '../global.variables';

@Component({
  selector: 'app-board-student',
  templateUrl: './board-student.component.html',
  styleUrls: ['./board-student.component.css']
})
export class BoardStudentComponent implements OnInit {
  student?: Student;
  content?: string;
  studentId = this.myProfileId();
  drivingEvents: Event[] = [];
  hoursConsumedTotal: number = 0;
  pageDrivingEvent: any;

  payments: Payment[] = [];
  pagePayment: any;
  totalPayments: number = 0;
  consumptions: Consumption[] = [];
  pageConsumption: any;
  totalConsumptions: number = 0;
  toDay : Date = new Date();
  totalDrivingEventsOfStudent: number = 0;
  numberHoursPurchased: any = 0;
  navMobile: boolean = screen.width < 577;
  messageError: string = "";
  popupError: string = "";
  showPayments: boolean = false;
  showConsumptions: boolean = false;
  toPay: any;
  showDrivingEvents: boolean = false;
  showDrivingEventsReserved: boolean = false;

  constructor(private userService: UserService, private profileService: ProfileService,
              private token: TokenStorageService) {
    this.getMyProfile();
    this.getMyDrivingEvents(0, 5);
    this.getStudentNumberHoursPurchased();
    this.getMyConsumptions(0, 5);
    this.getMyTotalConsumptions();
    this.getMyPayments(0, 5);
    this.getMyTotalPayments();
  }

  ngOnInit(): void {
    this.getMyProfile();
  }

  onChangeDrivingEventPage(pageNumber: number) {
    this.getMyDrivingEvents(pageNumber, 5);
  }
  onChangePaymentPage($event: number) {
    this.getMyPayments($event, 5);
  }

  onChangeConsumptionPage($event: number) {
    this.getMyConsumptions($event, 5);
  }

  getMyPayments(page: number, size: number) {
    this.profileService.getMyPayments(page, size).subscribe(
      paymentPages => {
        this.payments = paymentPages.content;
        this.pagePayment = paymentPages;
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  getMyTotalPayments() {
    this.profileService.getMyTotalPayments().subscribe(
      total => {
        this.totalPayments = total;
        this.toPay = this.totalConsumptions - this.totalPayments;
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  getMyConsumptions(page: number, size: number) {
    this.profileService.getMyConsumptions(page, size).subscribe(
      consumptionPage => {
        this.consumptions = consumptionPage.content;
        this.pageConsumption = consumptionPage;
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  getMyTotalConsumptions() {
    this.profileService.getMyTotalConsumptions().subscribe(
      total => {
        this.totalConsumptions = total;
        this.toPay = this.totalConsumptions - this.totalPayments;
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  getMyDrivingEvents(page: number, size: number) {
    this.profileService.getMyDrivingEvents(page, size).subscribe(
      (data: any) => {
        this.pageDrivingEvent = data;
        this.totalDrivingEventsOfStudent = data.totalElements;
        let drivingEvents = data.content.map((item:EventTO) => {
          let props = new ExtendedProps(item.boxType, item.monitor, item.student, item.active, item.description);
          let student = item.student ? item.student.firstname + " " + item.student.lastname : "";
          let monitorName = item.monitor.firstname;
          let eventTitle = student != "" ? student + " | " + monitorName : monitorName;
          let color = student == "" ? "green" : item.monitor.color;
          if (new Date(item.startAt).getTime() < new Date().getTime()) {
            this.hoursConsumedTotal++;
          }
          return new Event(
            item.id,
            eventTitle,
            moment.utc(item.startAt).format('YYYY-MM-DD HH:mm'),
            moment.utc(item.startAt).add(1, "hours").format('YYYY-MM-DD HH:mm'),
            props, color
          );
        });
        this.drivingEvents = drivingEvents;
      },
      err => {
        console.log(err);
      }
    )
  }

  getStudentNumberHoursPurchased() {
    this.profileService.getMyNumberHoursPurchased().subscribe(
      number => {
        this.numberHoursPurchased = number;
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  getMyProfile() {
    this.profileService.getMyProfile().subscribe(
      student => {
        this.student = student;
      },
      err => {
        this.content = JSON.parse(err.error).message;
      }
    );
  }

  onShowConsumptions() {
    this.showConsumptions = !this.showConsumptions;
  }

  onShowPayments() {
    this.showPayments = !this.showPayments;
  }

  onShowDrivingEvent() {
    this.showDrivingEvents = !this.showDrivingEvents;
  }
  private myProfileId() : string {
    return this.token.getUser()?.id;
  }
}
