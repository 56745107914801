import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

const TOOLS_API = environment.APIEndpoint + '/api/tools';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ToolsService {
  constructor(private http: HttpClient) { }

  getActiveArticles(): Observable<any> {
    return this.http.get(TOOLS_API + "/articles", httpOptions);
  }

  getAllArticles(): Observable<any> {
    return this.http.get(TOOLS_API + "/all-articles", httpOptions);
  }

  saveArticle(article: any): Observable<any> {
    return this.http.post(TOOLS_API + "/articles", article, httpOptions);
  }

  activeArticle(articleId: any, active: boolean): Observable<any> {
    return this.http.put(TOOLS_API + "/articles/" + articleId, {active: active}, httpOptions);
  }

  getActiveFormulas(): Observable<any> {
    return this.http.get(TOOLS_API + "/formulas", httpOptions);
  }

  getAllFormulas(): Observable<any> {
    return this.http.get(TOOLS_API + "/all-formulas", httpOptions);
  }

  saveFormula(formula: any): Observable<any> {
    return this.http.post(TOOLS_API + "/formulas", formula, httpOptions);
  }

  getUserRoles() {
    return this.http.get(TOOLS_API + "/user-roles", httpOptions);
  }

}
