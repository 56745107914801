<div id="app">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="home">Accueil</a>
    <button class="navbar-toggler" type="button" (click)="myFunction()" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item active">
          <a class="nav-link" routerLink="admin" *ngIf="showAdminBoard">Global</a>
        </li>
        <li class="nav-item active">
          <a class="nav-link" routerLink="my-board" *ngIf="showStudentBoard">Mon bord</a>
        </li>
        <li class="nav-item active">
          <a class="nav-link" routerLink="monitor-calendar" *ngIf="!showAdminBoard && showMonitorBoard">Mon Plannig</a>
        </li>
        <li class="nav-item active">
          <a class="nav-link" routerLink="calendar" *ngIf="showAdminBoard">Calendrier</a>
          <a class="nav-link" routerLink="my-calendar" *ngIf="showStudentBoard">Mes Heures</a>
        </li>
        <li class="nav-item active" *ngIf="showStudentBoard">
          <a class="nav-link" routerLink="my-drive">Suivi Drive</a>
        </li>
        <li class="nav-item dropdown active" *ngIf="showAdminBoard">
          <a class="nav-link dropdown-toggle" id="student-navbar" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Eléves
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" routerLink="students">Liste des éléves</a>
            <a class="dropdown-item" routerLink="new-student">Créé un éléve</a>
            <a class="dropdown-item" routerLink="students-archived">Elèves archivés</a>
          </div>
        </li>
        <li class="nav-item dropdown active" *ngIf="showAdminBoard">
          <a class="nav-link dropdown-toggle" id="monitor-navbar" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Moniteurs
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" routerLink="monitors">Liste des moniteurs</a>
            <a class="dropdown-item" routerLink="new-monitor">Créé un moniteur</a>
          </div>
        </li>
        <li class="nav-item active">
          <a class="nav-link" routerLink="configurations" *ngIf="showAdminBoard">Configurations</a>
        </li>
        <li class="nav-item active">
          <a class="nav-link" routerLink="purchases" *ngIf="showAdminBoard" data-toggle="modal" data-target="#expenseModal">
            <i class="fa fa-plus" aria-hidden="true"></i> Dépense
          </a>
        </li>
        <a href="/login" class="nav-link a-right" routerLink="login" *ngIf="!isLoggedIn && navMobile">Connexion</a>
        <a href="/profile" class="nav-link a-right" routerLink="profile" *ngIf="isLoggedIn && navMobile">{{ username }}</a>
        <a href class="nav-link a-right" (click)="logout()" *ngIf="isLoggedIn && navMobile">Se Déconnecter</a>
      </ul>
    </div>
    <form class="form-inline" *ngIf="!navMobile">
      <a href="/login" class="nav-link a-right" routerLink="login" *ngIf="!isLoggedIn">Connexion</a>
      <a href="/profile" class="nav-link a-right" routerLink="profile" *ngIf="isLoggedIn">{{ username }}</a>
      <a href class="nav-link a-right" (click)="logout()" *ngIf="isLoggedIn">Se Déconnecter</a>
    </form>
  </nav>

  <router-outlet></router-outlet>

  <!-- Modal -->
  <div class="modal fade" id="expenseModal" tabindex="-1" role="dialog" aria-labelledby="expenseModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="expenseModalLabel">Ajouter une dépense</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="expenseForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label for="date">Date</label>
              <input type="date" class="form-control" id="date" formControlName="date">
            </div>
            <div class="form-group">
              <label for="product">Mode de paiement</label>
              <select class="form-control" id="payment" formControlName="paymentMethod">
                <option *ngFor="let payment of paymentMethods" [value]="payment">{{ payment }}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="price">Prix</label>
              <input type="number" class="form-control" id="price" formControlName="cost" placeholder="Quel est le montant ?">
            </div>
            <div class="form-group">
              <label for="product">Raison</label>
              <select class="form-control" id="product" formControlName="reason">
                <option *ngFor="let product of products" [value]="product">{{ product }}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="description">Description</label>
              <textarea class="form-control" id="description" formControlName="description" rows="3"></textarea>
            </div>
            <button type="submit" class="btn btn-primary">Enregistrer</button>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
        </div>
      </div>
    </div>
  </div>


</div>

