<div class="modal fade" id="editEventModal" tabindex="-1" role="dialog" aria-labelledby="newEventLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="newEventTitle" name="startAt">
          {{isMobilePhone ? '' : 'L\'HEURE DE CONDUITE'}}
        </h3>
        <button type="button" *ngIf="student && !isMobilePhone" class="btn btn-primary ml-4" data-dismiss="modal"
                (click)="goToDriveBook(student.id)">SUIVI DRIVE</button>
        <button type="button" *ngIf="isMobilePhone" class="btn btn-secondary ml-2" data-dismiss="modal">ANNULER</button>
        <button *ngIf="isActive" type="button" class="btn btn-outline-danger ml-1" (click)="onEditActive()">MODIFIER</button>
        <button *ngIf="isActive" type="button" class="btn btn-danger ml-1" (click)="onRemoveActive()">SUPPRIMER</button>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div id="alert-delete-event" class="alert alert-danger mt-4 ml-4 mr-4" role="alert" *ngIf="removeActive">
        CONFIRMER-VOUS LA SUPPRESSION ? <button type="button" class="btn btn-danger ml-1" (click)="onDeleteEvent(editEventForm.value.id)">OUI</button>
      </div>
      <div id="newEventError" class="alert alert-danger mt-4 ml-4 mr-4" role="alert" *ngIf="popupError">
        {{popupError}}
      </div>

      <h6 *ngIf="student && isMobilePhone">
        <button type="button" class="btn btn-primary ml-4 mt-2 mr-4" data-dismiss="modal"
                (click)="goToDriveBook(student.id)">SUIVI DRIVE</button>
        TELEPHONE : {{student.phoneNumber}}
      </h6>
      <form [formGroup]="editEventForm" #f="ngForm" name="editEventForm" (ngSubmit)="f.form.valid && onEditEvent()" novalidate>
        <div>
          <div class="form-row ml-4 p-1 mt-4">
            <div class="input-group raw mb-3">
              <div class="col-md-4">
                <label for="inputDate" class="col-form-label">CHOISIR LA DATE</label>
                <input id="inputDate" type="date" class="form-control form-control-lg form-control-borderless update-modal"
                       name="dateAt" formControlName="dateAt" required aria-describedby="basic-addon2" [disabled]="true">
              </div>
              <div class="col-md-4">
                <label for="selectHour" class="col-form-label">SELECTIONNER UNE HEURE</label>
                <select id="selectHour" [attr.disabled]="true" name="hourAt" formControlName="hourAt"
                        class="form-control form-control-lg form-control-borderless overflow-auto update-modal">
                  <option *ngFor="let h of hours" [ngValue]="h">{{h}}</option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="boxType" class="col-form-label">SELECTIONNER LA BOITE</label>
                <select id="boxType" name="hourAt" formControlName="boxType"
                        class="form-control form-control-lg form-control-borderless overflow-auto update-modal" [attr.disabled]="true">
                  <option value="MANUELLE">MANUELLE</option>
                  <option value="AUTOMATIQUE">AUTOMATIQUE</option>
                  <option value="PERMIS">PERMIS</option>
                </select>
              </div>
            </div>

          </div>
          <p class="alert alert-danger ml-4 mr-4 p-0" role="alert" *ngIf="submited && f.form.value.dateAt == null">
            Merci de définir la date !
          </p>
          <p class="alert alert-danger ml-4 mr-4 p-0" role="alert" *ngIf="submited && (f.form.value.hourAt == null || f.form.value.hourAt.length < 4)">
            Merci de définir l'heure !
          </p>
        </div>

        <div>
          <div class="form-row ml-4 p-1" *ngIf="monitors">
            <label for="monitorSearch" class="col-form-label">CHOISIR UN MONITEUR</label>
            <div id="monitorSearch" class="input-group col-md-12">
              <select id="selectMonitor" class="input-group form-control form-control-lg form-control-borderless update-modal"
                      formControlName="monitorId" [attr.disabled]="true">
                <option [ngValue]="null" disabled>Choisir un moniteur</option>
                <option *ngFor="let m of monitors" [ngValue]="m.id">{{m.lastname}} {{m.firstname}} - {{m.phoneNumber}}</option>
              </select>
            </div>
          </div>
          <p class="alert alert-danger ml-4 mr-4 p-0" role="alert" *ngIf="submited && f.form.value.monitorId == null">
            Merci de choisir le moniteur !
          </p>
        </div>

        <div *ngIf="f.form.value.boxType != 'PERMIS'">
          <div class="form-row ml-4 p-1">
            <label for="studentSearch" class="col-form-label">RECHERCHER UN ELEVE</label>
            <div id="studentSearch" class="input-group mb-3">
              <input type="text" id="inputStudent" #studentInput type="search"
                     class="form-control form-control-lg form-control-borderless col-md-8 update-modal"
                     placeholder="Rechercher un éléve" [disabled]="true">
              <div class="input-group-append">
                <button id="btn-search-student" disabled class="btn btn-outline-success btn-search-student update-modal" type="button" (click)="searchStudent(studentInput.value)">RECHERCHER</button>
              </div>
            </div>
          </div>
          <div class="form-row ml-4 p-1">
            <select id="selectStudent" class="input-group form-control form-control-lg form-control-borderless col-md-9 update-modal"
                    [attr.disabled]="true" formControlName="studentId">
              <option [ngValue]="null" disabled>Choisir un éléve</option>
              <option *ngFor="let s of editEventStudents" [ngValue]="s.id" >{{s.lastname}} {{s.firstname}} - {{s.phoneNumber}}</option>
            </select>
          </div>
        </div>
        <div class="form-row ml-4 p-1 col-10">
          <label for="editEventDescription" class="col-form-label">NOTES</label>
          <textarea class="form-control update-modal" id="editEventDescription" name="description" formControlName="description"
                    rows="3" ></textarea>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">ANNULER</button>
          <button id="btn-validate-edit" disabled type="button" class="btn btn-primary update-modal" type="submit" (click)="isSubmited(f.form)">VALIDER</button>
        </div>
      </form>
    </div>
  </div>
</div>
