<div class="modal fade" id="newConsumptionModal" tabindex="-1" role="dialog" aria-labelledby="newArticleLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="newArticleTitle" name="startAt" *ngIf="iStudent">
          AJOUTER UNE CONSOMMATION - # {{iStudent.lastname}} {{iStudent.firstname}}
        </h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div id="newArticleError" class="alert alert-danger mt-4 ml-4 mr-4" role="alert" *ngIf="popupError && popupError.length > 0">
        {{popupError}}
      </div>

      <form [formGroup]="consumptionForm" #f2="ngForm" name="newEventForm" (ngSubmit)="f2.form.valid && onCreateConsumption()" novalidate>
        <div>
          <div class="form-row ml-4 p-1 mt-4">
            <div class="input-group raw mb-3">
              <div class="col-4">
                <label for="article-date-c" class="col-form-label">CHOISIR LA DATE</label>
                <input id="article-date-c" type="date" class="form-control form-control-lg form-control-borderless"
                       name="date" formControlName="date" required aria-describedby="basic-addon2">
                <p class="alert alert-danger p-0" role="alert" *ngIf="consumptionSubmited && f2.form.controls.date.status == 'INVALID'">
                  Merci de définir la date !
                </p>
              </div>
              <div class="col-6">
                <label for="new-article" class="col-form-label">CHOISIR UN ARTICLE</label>
                <select id="new-article" class="form-control form-control-lg form-control-borderless" formControlName="articleId"> <!--(change)="selectMonitor($event)" -->
                  <option [ngValue]="null" disabled>Choisir un artcile</option>
                  <option *ngFor="let a of iArticles" [ngValue]="a.id" >{{a.name}} - {{a.price}} €</option>
                </select>
                <p class="alert alert-danger p-0" role="alert" *ngIf="consumptionSubmited && f2.form.controls.articleId.status == 'INVALID'">
                  Merci de choisir un article !
                </p>
              </div>
            </div>
          </div>
          <div class="form-row ml-4 p-1 mt-1">
            <div class="input-group raw mb-3">
              <div class="col-4">
                <label for="quantity-c" class="col-form-label">QUANTITE</label>
                <input id="quantity-c" class="form-control form-control-lg form-control-borderless"
                       name="quantity" formControlName="quantity" required aria-describedby="basic-addon2">
                <p class="alert alert-danger p-0" role="alert" *ngIf="consumptionSubmited && f2.form.controls.quantity.status == 'INVALID'">
                  Merci de définir la quantité d'article !
                </p>
              </div>
              <div class="col-6">
                <label for="consumption-description-c" class="col-form-label">DESCRIPTION</label>
                <textarea class="form-control" id="consumption-description-c" name="description" formControlName="description"  rows="3"></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">ANNULER</button>
          <button type="button" class="btn btn-primary" type="submit" (click)="isConsumptionSubmited(f2.form)">VALIDER</button>
        </div>
      </form>
    </div>
  </div>
</div>
