
<header class="u-clearfix u-header u-header" id="sec-a82c">
    <p style="text-align: center">
      <img *ngIf="!isAgencySAM" class="" src="/assets/img/baniere.png" style="height: 110px" alt="AUTO ECOLE DU NORD LOGO">
      <img *ngIf="isAgencySAM" class="" src="/assets/img/logo-sam.png" style="height: 110px" alt="AUTO ECOLE SAM LOGO">
    </p>
</header>
<h4 *ngIf="!isAgencySAM" style="text-align: center">1 RUE DU NORD, 59280 ARMENTIERES - 09 80 71 89 32 </h4>
<h4 *ngIf="isAgencySAM" style="text-align: center">189 RUE DU MARECHAL FOCH 59120 LOOS - 06 25 25 44 90 </h4>
<section class="u-clearfix u-section-3" id="sec-5b80">
  <h5 style="text-align: center" *ngIf="!isAgencySAM">
    <b>En mode connecté</b>, avec l'AUTO ECOLE DU NORD c'est toi qui <b>pilote ta formation</b>
  </h5>
  <h5 style="text-align: center" *ngIf="isAgencySAM">
    <b>En mode connecté</b>, avec l'AUTO ECOLE SAM c'est toi qui <b>pilote ta formation</b>
  </h5>
  <h6 style="text-align: center"><b>Gagner du temps avec la pré-inscription en bas de page</b></h6>
  <div class="u-clearfix u-sheet u-sheet-1">
    <div class="u-list u-list-1">
      <div class="u-repeater u-repeater-1">
        <div class="u-align-center u-container-style u-list-item u-repeater-item">
          <div class="u-container-layout u-similar-container u-valign-top u-container-layout-1"><span class="u-icon u-icon-circle u-icon-2"><svg class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 52 52" style=""><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-c49c"></use></svg><svg class="u-svg-content" viewBox="0 0 52 52" x="0px" y="0px" id="svg-c49c" style="enable-background:new 0 0 52 52;"><path style="fill:currentColor;" d="M38.853,5.324L38.853,5.324c-7.098-7.098-18.607-7.098-25.706,0h0
	C6.751,11.72,6.031,23.763,11.459,31L26,52l14.541-21C45.969,23.763,45.249,11.72,38.853,5.324z M26.177,24c-3.314,0-6-2.686-6-6
	s2.686-6,6-6s6,2.686,6,6S29.491,24,26.177,24z"></path></svg>


          </span>
            <h3 class="u-text u-text-default u-text-3">Tu choisis l'endroit et le moment</h3>
            <p class="u-text u-text-default u-text-4">Avec l'accès en ligne, révise où et quand tu veux !&nbsp;&nbsp;</p>
          </div>
        </div>
        <div class="u-align-center u-container-style u-list-item u-repeater-item">
          <div class="u-container-layout u-similar-container u-valign-top u-container-layout-2"><span class="u-icon u-icon-circle u-icon-3"><svg class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 24 24" style=""><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-7893"></use></svg><svg class="u-svg-content" viewBox="0 0 24 24" id="svg-7893"><path d="m14.25 3h-11.5c-1.52 0-2.75 1.23-2.75 2.75v12.5c0 1.52 1.23 2.75 2.75 2.75h7.38l.22-1.23c.101-.56.36-1.06.761-1.47l1.3-1.3h-8.661c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h9.5c.18 0 .35.07.48.18l3.27-3.26v-6.67c0-1.52-1.23-2.75-2.75-2.75zm-1 10.75h-9.5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h9.5c.41 0 .75.34.75.75s-.34.75-.75.75zm0-3.25h-9.5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h9.5c.41 0 .75.34.75.75s-.34.75-.75.75z" fill="#2196f3"></path><path d="m12.25 2h-1.1c-.33-1.15-1.39-2-2.65-2s-2.32.85-2.65 2h-1.1c-.41 0-.75.34-.75.75v2c0 .96.79 1.75 1.75 1.75h5.5c.96 0 1.75-.79 1.75-1.75v-2c0-.41-.34-.75-.75-.75z" fill="#1976d2"></path><g fill="#fff"><path d="m14 9.75c0 .41-.34.75-.75.75h-9.5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h9.5c.41 0 .75.34.75.75z"></path><path d="m14 13c0 .41-.34.75-.75.75h-9.5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h9.5c.41 0 .75.34.75.75z"></path><path d="m13.73 15.68-1.32 1.32h-8.66c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h9.5c.18 0 .35.07.48.18z"></path>
</g><path d="m4 3h-1.25c-1.52 0-2.75 1.23-2.75 2.75v12.5c0 1.52 1.23 2.75 2.75 2.75h5.75v-4h-4.75c-.41 0-.75-.34-.75-.75 0-.41.34-.75.75-.75h4.75v-1.75h-4.75c-.41 0-.75-.34-.75-.75 0-.41.34-.75.75-.75h4.75v-1.75h-4.75c-.41 0-.75-.34-.75-.75 0-.41.34-.75.75-.75h4.75v-2.5h-2.75c-.96 0-1.75-.79-1.75-1.75z" fill="#1d83d4"></path><path d="m8.5 0c-1.26 0-2.32.85-2.65 2h-1.1c-.41 0-.75.34-.75.75v.25 1.75c0 .96.79 1.75 1.75 1.75h2.75z" fill="#1667b7"></path><path d="m8.5 9h-4.75c-.41 0-.75.34-.75.75 0 .41.34.75.75.75h4.75z" fill="#dedede"></path><path d="m8.5 12.25h-4.75c-.41 0-.75.34-.75.75 0 .41.34.75.75.75h4.75z" fill="#dedede"></path><path d="m8.5 15.5h-4.75c-.41 0-.75.34-.75.75 0 .41.34.75.75.75h4.75z" fill="#dedede"></path><path d="m12.527 24c-.197 0-.389-.078-.53-.22-.173-.173-.251-.419-.208-.661l.53-3.005c.026-.151.1-.291.208-.399l7.425-7.425c.912-.913 1.808-.667 2.298-.177l1.237 1.237c.683.683.683 1.792 0 2.475l-7.425 7.425c-.108.109-.248.182-.4.209l-3.005.529zm3.005-1.28h.01z" fill="#ffc107"></path><path d="m21.23 11.675c-.392 0-.833.17-1.278.615l-7.425 7.425c-.108.108-.182.248-.208.399l-.53 3.005c-.043.242.035.488.208.661.008.008.015.015.023.022l10.959-10.96-.73-.73c-.25-.25-.608-.437-1.019-.437z" fill="#dea806"></path></svg>


          </span>
            <h3 class="u-text u-text-default u-text-5">Plus besoin de prendre des notes</h3>
            <p class="u-text u-text-default u-text-6">Avec l'application, les bilans des leçons sont aussi en mode connecté !</p>
          </div>
        </div>
        <div class="u-align-center u-container-style u-list-item u-repeater-item">
          <div class="u-container-layout u-similar-container u-valign-top u-container-layout-3">
			  <span class="u-icon u-icon-circle u-icon-4">
			  <svg class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 512 512" style=""><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-dc58"></use></svg><svg class="u-svg-content" viewBox="0 0 512 512" x="0px" y="0px" id="svg-dc58" style="enable-background:new 0 0 512 512;"><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="165.9509" y1="426.9289" x2="274.6409" y2="185.3989" gradientTransform="matrix(1.0667 0 0 -1.0667 3.2667 557.533)"><stop offset="0" style="stop-color:#00A6F9"></stop><stop offset="1" style="stop-color:#0071E2"></stop>
</linearGradient><path style="fill:url(#SVGID_1_);" d="M453.477,42.994H58.519c-11.092,0-20.095,9.003-20.095,20.095v414.624  c0,11.092,9.003,20.095,20.095,20.095h394.958c11.092,0,20.095-9.003,20.095-20.095V63.089  C473.572,51.997,464.571,42.994,453.477,42.994z"></path><linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="97.5282" y1="323.8781" x2="377.6182" y2="21.1181" gradientTransform="matrix(1.0667 0 0 -1.0667 3.2667 557.533)"><stop offset="0" style="stop-color:#C2CECE"></stop><stop offset="1" style="stop-color:#97AAAA"></stop>
</linearGradient><path style="fill:url(#SVGID_2_);" d="M453.474,497.808H58.524c-11.098,0-20.095-8.996-20.095-20.095V260.019h435.14v217.694  C473.569,488.812,464.572,497.808,453.474,497.808z"></path><linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="236.9364" y1="61.3073" x2="236.9364" y2="103.0873" gradientTransform="matrix(1.0667 0 0 -1.0667 3.2667 557.533)"><stop offset="0" style="stop-color:#C2CECE;stop-opacity:0"></stop><stop offset="0.179" style="stop-color:#AFBCBC;stop-opacity:0.179"></stop><stop offset="1" style="stop-color:#5B6A6A"></stop>
</linearGradient><path style="fill:url(#SVGID_3_);" d="M453.474,497.808H58.524c-11.098,0-20.095-8.996-20.095-20.095v-42.199h435.14v42.199  C473.569,488.812,464.572,497.808,453.474,497.808z"></path><linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="61.7254" y1="265.133" x2="511.1254" y2="62.723" gradientTransform="matrix(1.0667 0 0 -1.0667 3.2667 557.533)"><stop offset="0" style="stop-color:#00A6F9"></stop><stop offset="1" style="stop-color:#0071E2"></stop>
</linearGradient><path style="fill:url(#SVGID_4_);" d="M491.894,448.911H20.103c-12.908,0-22.468-11.996-19.588-24.578l37.914-165.653h435.14  l37.914,165.653C514.363,436.915,504.802,448.911,491.894,448.911z"></path><linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="236.9364" y1="118.9973" x2="236.9364" y2="93.8773" gradientTransform="matrix(1.0667 0 0 -1.0667 3.2667 557.533)"><stop offset="1.000000e-004" style="stop-color:#008BF2;stop-opacity:0"></stop><stop offset="1" style="stop-color:#0046E2"></stop>
</linearGradient><path style="fill:url(#SVGID_5_);" d="M491.894,448.911H20.103c-12.908,0-22.468-11.996-19.588-24.578l37.914-165.653h435.14  l37.914,165.653C514.363,436.915,504.802,448.911,491.894,448.911z"></path><linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="322.1359" y1="194.9758" x2="37.4249" y2="479.6858" gradientTransform="matrix(1.0667 0 0 -1.0667 3.2667 557.533)"><stop offset="1.000000e-004" style="stop-color:#008BF2;stop-opacity:0"></stop><stop offset="1" style="stop-color:#0046E2"></stop>
</linearGradient><path style="fill:url(#SVGID_6_);" d="M491.894,448.911c12.908,0,22.468-11.996,19.588-24.578l-37.914-165.653H38.429  L228.66,448.911H491.894z"></path><linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="206.986" y1="138.5962" x2="160.336" y2="196.3462" gradientTransform="matrix(1.0667 0 0 -1.0667 3.2667 557.533)"><stop offset="0" style="stop-color:#C2CECE;stop-opacity:0"></stop><stop offset="0.179" style="stop-color:#AFBCBC;stop-opacity:0.179"></stop><stop offset="1" style="stop-color:#5B6A6A"></stop>
</linearGradient><path style="fill:url(#SVGID_7_);" d="M473.569,448.911H38.429v0.67l48.227,48.227h366.818c11.098,0,20.095-8.996,20.095-20.095  V448.911z"></path><g><path style="fill:#FFFFFF;" d="M152.136,298.536v-84.781c0-22.333,5.922-38.749,17.772-49.237   c11.845-10.489,27.678-15.732,47.488-15.732c19.811,0,35.69,5.244,47.635,15.732c11.945,10.489,17.917,26.903,17.917,49.237v84.781   c0,22.338-5.972,38.749-17.917,49.237c-11.946,10.489-27.823,15.732-47.635,15.732c-19.811,0-35.644-5.244-47.488-15.732   C158.059,337.284,152.136,320.873,152.136,298.536z M183.019,298.536c0,25.056,11.458,37.584,34.379,37.584   c11.071,0,19.616-3.105,25.638-9.324c6.018-6.213,9.031-15.632,9.031-28.26v-84.781c0-12.623-3.013-22.043-9.031-28.26   c-6.022-6.213-14.567-9.324-25.638-9.324c-22.921,0-34.379,12.528-34.379,37.584V298.536z"></path><path style="fill:#FFFFFF;" d="M301.885,192.779c0-5.049,2.038-9.031,6.118-11.946l30.3-29.134   c2.134-2.135,4.757-3.205,7.866-3.205c3.497,0,6.651,0.925,9.469,2.768c2.814,1.847,4.224,4.324,4.224,7.429V351.56   c0,3.109-1.557,5.586-4.661,7.429c-3.109,1.847-6.701,2.768-10.78,2.768c-4.275,0-7.917-0.919-10.925-2.768   c-3.013-1.844-4.516-4.32-4.516-7.429V189.573l-10.197,12.819c-1.943,1.943-4.079,2.913-6.41,2.913   c-2.913,0-5.39-1.311-7.429-3.933C302.904,198.751,301.885,195.888,301.885,192.779z"></path>
</g><g><linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="200.8839" y1="248.1173" x2="200.8839" y2="391.4473" gradientTransform="matrix(1.0667 0 0 -1.0667 3.2667 557.533)"><stop offset="0" style="stop-color:#C2CECE;stop-opacity:0"></stop><stop offset="0.179" style="stop-color:#AFBCBC;stop-opacity:0.179"></stop><stop offset="1" style="stop-color:#5B6A6A"></stop>
</linearGradient><path style="fill:url(#SVGID_8_);" d="M252.06,258.679v39.855c0,12.633-3.001,22.051-9.029,28.267   c-6.015,6.217-14.562,9.324-25.628,9.324c-22.922,0-34.389-12.54-34.389-37.59v-39.855h-30.879v39.855   c0,22.346,5.921,38.756,17.777,49.232c11.842,10.49,27.678,15.741,47.491,15.741c19.801,0,35.689-5.251,47.625-15.741   c11.95-10.476,17.924-26.887,17.924-49.232v-39.855L252.06,258.679L252.06,258.679z"></path><linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="319.8339" y1="248.1173" x2="319.8339" y2="391.4473" gradientTransform="matrix(1.0667 0 0 -1.0667 3.2667 557.533)"><stop offset="0" style="stop-color:#C2CECE;stop-opacity:0"></stop><stop offset="0.179" style="stop-color:#AFBCBC;stop-opacity:0.179"></stop><stop offset="1" style="stop-color:#5B6A6A"></stop>
</linearGradient><path style="fill:url(#SVGID_9_);" d="M328.983,258.679v92.879c0,3.108,1.501,5.586,4.514,7.435   c3.001,1.849,6.644,2.759,10.918,2.759c4.086,0,7.677-0.911,10.784-2.759c3.108-1.849,4.662-4.327,4.662-7.435v-92.879H328.983z"></path>
</g><linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="441.3411" y1="293.2793" x2="345.2411" y2="268.1593" gradientTransform="matrix(1.0667 0 0 -1.0667 3.2667 557.533)"><stop offset="1.000000e-004" style="stop-color:#008BF2;stop-opacity:0"></stop><stop offset="1" style="stop-color:#0046E2"></stop>
</linearGradient><path style="fill:url(#SVGID_10_);" d="M511.485,424.328l-37.913-165.649V63.089c0-11.092-9.003-20.095-20.095-20.095h-9.504  c11.092,0,20.095,9.003,20.095,20.095v195.59l37.913,165.649c2.88,12.593-6.685,24.582-19.586,24.582h9.504  C504.8,448.911,514.365,436.92,511.485,424.328z"></path><linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="205.018" y1="432.4879" x2="167.788" y2="469.7169" gradientTransform="matrix(1.0667 0 0 -1.0667 3.2667 557.533)"><stop offset="1.000000e-004" style="stop-color:#008BF2;stop-opacity:0"></stop><stop offset="1" style="stop-color:#0046E2"></stop>
</linearGradient><path style="fill:url(#SVGID_11_);" d="M170.864,78.79c-3.658-3.429-5.962-8.279-5.962-13.692V42.994h37.51l30.634,30.634  c9.764,9.764,15.25,23.008,15.25,36.817l0,0c0,10.116-7.783,18.816-17.889,19.273c-5.76,0.26-10.946-2.091-14.557-5.941  L170.864,78.79z"></path><linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="169.1164" y1="508.4793" x2="169.1164" y2="306.9173" gradientTransform="matrix(1.0667 0 0 -1.0667 3.2667 557.533)"><stop offset="0" style="stop-color:#FFC200"></stop><stop offset="0.268" style="stop-color:#FFBB00"></stop><stop offset="0.659" style="stop-color:#FFA801"></stop><stop offset="1" style="stop-color:#FF9102"></stop>
</linearGradient><path style="fill:url(#SVGID_12_);" d="M183.658,83.854L183.658,83.854c-10.358,0-18.755-8.397-18.755-18.755V32.947  c0-10.358,8.397-18.755,18.755-18.755l0,0c10.358,0,18.755,8.397,18.755,18.755v32.151  C202.413,75.457,194.016,83.854,183.658,83.854z"></path><linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="171.9374" y1="476.7283" x2="187.7974" y2="476.7283" gradientTransform="matrix(1.0667 0 0 -1.0667 3.2667 557.533)"><stop offset="0" style="stop-color:#FFC200;stop-opacity:0"></stop><stop offset="0.203" style="stop-color:#FFBB00;stop-opacity:0.203"></stop><stop offset="0.499" style="stop-color:#FFA700;stop-opacity:0.499"></stop><stop offset="0.852" style="stop-color:#FF8800;stop-opacity:0.852"></stop><stop offset="1" style="stop-color:#FF7800"></stop>
</linearGradient><path style="fill:url(#SVGID_13_);" d="M183.658,14.192v69.662c10.358,0,18.755-8.397,18.755-18.755V32.947  C202.413,22.589,194.016,14.192,183.658,14.192z"></path><linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="332.808" y1="432.488" x2="295.5781" y2="469.7169" gradientTransform="matrix(1.0667 0 0 -1.0667 3.2667 557.533)"><stop offset="1.000000e-004" style="stop-color:#008BF2;stop-opacity:0"></stop><stop offset="1" style="stop-color:#0046E2"></stop>
</linearGradient><path style="fill:url(#SVGID_14_);" d="M307.173,78.79c-3.658-3.429-5.962-8.279-5.962-13.692V42.994h37.51l30.634,30.634  c9.764,9.764,15.25,23.008,15.25,36.817l0,0c0,10.116-7.783,18.816-17.889,19.273c-5.76,0.26-10.946-2.091-14.557-5.941  L307.173,78.79z"></path><linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="296.9074" y1="508.4793" x2="296.9074" y2="306.9173" gradientTransform="matrix(1.0667 0 0 -1.0667 3.2667 557.533)"><stop offset="0" style="stop-color:#FFC200"></stop><stop offset="0.268" style="stop-color:#FFBB00"></stop><stop offset="0.659" style="stop-color:#FFA801"></stop><stop offset="1" style="stop-color:#FF9102"></stop>
</linearGradient><path style="fill:url(#SVGID_15_);" d="M319.968,83.854L319.968,83.854c-10.358,0-18.755-8.397-18.755-18.755V32.947  c0-10.358,8.397-18.755,18.755-18.755l0,0c10.358,0,18.755,8.397,18.755,18.755v32.151  C338.723,75.457,330.325,83.854,319.968,83.854z"></path><linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="299.7374" y1="476.7283" x2="315.5874" y2="476.7283" gradientTransform="matrix(1.0667 0 0 -1.0667 3.2667 557.533)"><stop offset="0" style="stop-color:#FFC200;stop-opacity:0"></stop><stop offset="0.203" style="stop-color:#FFBB00;stop-opacity:0.203"></stop><stop offset="0.499" style="stop-color:#FFA700;stop-opacity:0.499"></stop><stop offset="0.852" style="stop-color:#FF8800;stop-opacity:0.852"></stop><stop offset="1" style="stop-color:#FF7800"></stop>
</linearGradient><path style="fill:url(#SVGID_16_);" d="M319.968,14.192v69.662c10.358,0,18.755-8.397,18.755-18.755V32.947  C338.723,22.589,330.325,14.192,319.968,14.192z"></path></svg>


          </span>
            <h3 class="u-text u-text-default u-text-7">La réservation se fait en quelques clics</h3>
            <p class="u-text u-text-default u-text-8">Avec ton compte, réserve tes heures de conduites sans te déplacer !</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="u-align-center u-clearfix u-grey-5 u-section-2" id="sec-80aa">
  <div class="u-clearfix u-sheet u-sheet-1">
    <div class="u-clearfix u-gutter-20 u-layout-wrap u-layout-wrap-1">
      <div class="u-layout">
        <div class="u-layout-row">
          <div class="u-align-center u-border-no-bottom u-border-no-left u-border-no-right u-border-no-top u-container-style u-layout-cell u-left-cell u-size-15 u-size-30-md u-white u-layout-cell-1">
            <div class="u-container-layout u-valign-top u-container-layout-1">
              <h5 class="u-text u-text-2">Les Documents D'inscription</h5>
              <p class="font-weight-normal" style="text-align: left">Pièce d'identité <br> E-photo (Photo permis) <br> 2 enveloppe A5 lettre suivis <br> Facture de moins de 3 mois à votre nom <b>ou</b> Facture + Attestion d'hébergement et Pièce d'identité de l'hébergent <br> JDC pour les moins de 25 ans </p>
            </div>
          </div>
          <div class="u-align-center u-border-no-bottom u-border-no-left u-border-no-right u-border-no-top u-container-style u-layout-cell u-left-cell u-size-15 u-size-30-md u-white u-layout-cell-1">
            <div class="u-container-layout u-valign-top u-container-layout-1">
              <h5 class="u-text u-text-2">Formule Classique B ou BA</h5>
              <p class="font-weight-normal">Frais d'inscription <br> Accès au code en ligne et en agence <br> 20H de conduite B <b>ou</b> 13H de conduite BA<br> suivi drive <br> examen théorique et pratique</p>
              <h3 class="u-text u-text-2">899 €</h3>
            </div>
          </div>
          <div class="u-align-center u-container-style u-layout-cell u-size-15 u-size-30-md u-white u-layout-cell-2">
            <div class="u-container-layout u-valign-top u-container-layout-2">
              <h5 class="u-text u-text-2">Formule Accélérée B ou BA</h5>
              <p class="font-weight-normal">Frais d'inscription <br> Accès au code en ligne et en agence <br> 20H de conduite B <b>ou</b> 13H de conduite BA <br> suivi drive <br> examen théorique et pratique</p>
              <h3 class="u-text u-text-2">1 499 €</h3>
            </div>
          </div>
          <div class="u-align-center u-border-no-bottom u-border-no-left u-border-no-right u-border-no-top u-container-style u-layout-cell u-size-15 u-size-30-md u-white u-layout-cell-3">
            <div class="u-container-layout u-valign-top u-container-layout-3">
              <h5 class="u-text u-text-2">Formule A La Carte B ou BA</h5>
              <p class="font-weight-normal">Frais d'inscription <br> 7 Heures de conduite <br> suivi drive <br><br><br><br></p>
              <h3 class="u-text u-text-2">490 €</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="mt-2 u-align-center u-clearfix u-section-4 ml-4 mr-4 mb-4" id="sec-7127" style="margin-bottom: 100px;">
  <div style="margin-left: 25%; margin-bottom: 100px">
    <button type="button" class="btn btn-lg btn-block" [ngClass]="showFormulas ? 'btn-outline-primary' : 'btn-primary'"
            (click)="onShowFormulas()" style="width: 50%;">
      FAIRE UNE PRE-INSCRIPTION
    </button>
  </div>

  <div class="container mt-2" *ngIf="showFormulas" style="margin-bottom: 50px">
    <div class="alert alert-danger" role="alert" *ngIf="messageError != null && messageError.length > 0">
      <p class="alert-heading mb-0">Une erreur a été rencontrée : {{messageError}}</p>
      <hr>
    </div>
    <form class="needs-validation" [formGroup]="initStudentForm" #f="ngForm" name="editEventForm"
          (ngSubmit)="f.form.valid && onCreateStudent()" novalidate>
      <div class="form-row">
        <div class="col-md-4 mb-3">
          <label for="madam"></label>
          <div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="sexe" formControlName="sexe" id="madam" value="F">
              <label class="form-check-label" for="madam">MADAME</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="sexe" formControlName="sexe" id="sir" value="M">
              <label class="form-check-label" for="sir">MONSIEUR</label>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="formulaId">Formule</label>
          <select id="formulaId" class="form-control form-control-borderless" formControlName="formulaId"> <!--(change)="selectMonitor($event)" -->
            <option [ngValue]="null" disabled>Choisir une formule</option>
            <option *ngFor="let f of formulas" [ngValue]="f.id" >{{f.name}} - {{f.price}} €</option>
          </select>
          <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.formulaId.status == 'INVALID')">
            Merci de choisir une formule !
          </p>
        </div>
        <div class="col-md-4 mb-3">
          <label for="neph">NEPH</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">N</span>
            </div>
            <input type="text" class="form-control" id="neph" formControlName="neph" placeholder="Numéro NEPH">
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4 mb-3">
          <label for="name">Nom</label>
          <input type="text" class="form-control" id="name" formControlName="lastname" placeholder="Nom" required>
          <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.lastname.status == 'INVALID')">
            Merci de définir le prénom !
          </p>
        </div>
        <div class="col-md-4 mb-3">
          <label for="firstname">Prénom</label>
          <input type="text" class="form-control" id="firstname" formControlName="firstname" placeholder="Prénom" required>
          <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.firstname.status == 'INVALID')">
            Merci de définir le nom !
          </p>
        </div>
        <div class="col-md-4 mb-3">
          <label for="email">Email</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroupPrepend">@</span>
            </div>
            <input type="email" class="form-control" id="email" formControlName="email" placeholder="Email" required>
          </div>
          <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.email.status === 'INVALID')">
            Email invalide ou déjà utilisé !
          </p>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4 mb-3">
          <label for="birthDate">Date De Naissance</label>
          <input type="date" class="form-control" id="birthDate" formControlName="birthDate" placeholder="Date De Naissance" required>
          <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.birthDate.status == 'INVALID')">
            Merci de définir la date de naissance !
          </p>
        </div>
        <div class="col-md-4 mb-3">
          <label for="birthPlace">Lieu De Naissance</label>
          <input type="text" class="form-control" id="birthPlace" formControlName="birthPlace" placeholder="Lieu De Naissance" required>
          <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.birthPlace.status == 'INVALID')">
            Merci de définir le lieu de naissance !
          </p>
        </div>
        <div class="col-md-4 mb-3">
          <label for="phoneNumber">Téléphone</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">T</span>
            </div>
            <input type="text" class="form-control" id="phoneNumber" formControlName="phoneNumber" placeholder="Téléphone" required>
          </div>
          <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.phoneNumber.status == 'INVALID')">
            Merci de définir un numéro de téléphone !
          </p>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 mb-3">
          <label for="address">Adresse</label>
          <input type="text" class="form-control" id="address" formControlName="address" placeholder="Adresse" required>
          <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.address.status == 'INVALID')">
            Merci de définir l'adresse
          </p>
        </div>
        <div class="col-md-3 mb-3">
          <label for="postalCode">Code Postal</label>
          <input type="text" class="form-control" id="postalCode" formControlName="postalCode" placeholder="Code Postal" required>
          <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.postalCode.status == 'INVALID')">
            Merci de définir le code postal !
          </p>
        </div>
        <div class="col-md-3 mb-3">
          <label for="city">Ville</label>
          <input type="text" class="form-control" id="city" formControlName="city" placeholder="Ville" required>
          <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.city.status == 'INVALID')">
            Merci de définir la ville !
          </p>
        </div>
      </div>
      <button class="btn btn-primary" type="submit" (click)="isSubmited(f.form)">ENVOYER</button>
    </form>
  </div>

</section>

<div id="modal-send-validated" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="alert alert-success" role="alert">
      <h4 class="alert-heading">Votre pré-inscription est envoyée !</h4>
      <hr>
      <p class="mb-0">Rendez-vous en agence pour finaliser votre dossier.
        <button type="button" class="btn btn-primary ml-4">OK</button>
      </p>
    </div>
  </div>
</div>

