import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {TokenStorageService} from './token-storage.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsMonitor  {

  constructor(private tokenStorageService: TokenStorageService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean | Observable<any> | Promise<any>{
    const isSignedIn = this.tokenStorageService.getToken() != null &&
      this.tokenStorageService.getUser().roles.includes('ROLE_MONITOR');

    if (isSignedIn !== true) {
      this.router.navigate(["/home"]);
    }
    return isSignedIn;
  }

}
