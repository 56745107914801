<div class="modal fade" id="updateUserForm" tabindex="-1" role="dialog" aria-labelledby="updateUserForm" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content container">
      <div class="modal-header">
        <h3 class="modal-title" id="updateUserFormTitle" name="startAt" *ngIf="iStudent">
          MODIFIER LE MOT DE PASSE - # {{iStudent.lastname}} {{iStudent.firstname}}
        </h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div id="newPaymentError" class="alert alert-danger mt-4 ml-4 mr-4" role="alert" *ngIf="popupError && popupError.length > 0">
        ERREUR : {{popupError}}
      </div>

      <form *ngIf="!isSuccessful" name="form" (ngSubmit)="f.form.valid && onSubmit()"
            #f="ngForm" novalidate>
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" id="email" class="form-control" name="email" [(ngModel)]="form.email"
                 required email #email="ngModel" disabled/>
          <div class="alert-danger" *ngIf="email.errors && f.submitted">
            <div *ngIf="email.errors.required">Email is required</div>
            <div *ngIf="email.errors.email">
              Email must be a valid email address
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="username">Username</label>
          <input type="text" id="username" class="form-control" name="username" [(ngModel)]="form.username"
                 required minlength="3" maxlength="20" #username="ngModel"/>
          <div class="alert-danger" *ngIf="username.errors && f.submitted">
            <div *ngIf="username.errors.required">Username is required</div>
            <div *ngIf="username.errors.minlength">
              Username must be at least 3 characters
            </div>
            <div *ngIf="username.errors.maxlength">
              Username must be at most 20 characters
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" id="password" class="form-control" name="password" [(ngModel)]="form.password"
                 required minlength="6" #password="ngModel"/>
          <div class="alert-danger" *ngIf="password.errors && f.submitted">
            <div *ngIf="password.errors.required">Password is required</div>
            <div *ngIf="password.errors.minlength">
              Password must be at least 6 characters
            </div>
          </div>
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block">VALIDER</button>
        </div>

        <div class="alert alert-warning" *ngIf="f.submitted && isSignUpFailed">
          Une erreur a été rencontrée : <br />{{ errorMessage }}
        </div>
      </form>

      <div class="alert alert-success" *ngIf="isSuccessful">
        Le mot de passe a été modifié !
      </div>



     </div>
  </div>
</div>
