import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Note} from '../models/eventRequest';
import {NoteService} from '../_services/note.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {TokenStorageService} from '../_services/token-storage.service';
import {Router} from '@angular/router';

declare let $ : any;

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.css']
})
export class NoteComponent implements OnInit {

  notes: Note[] = [];
  popupError: any;
  noteForm = this.resetForm();
  noteSubmited: boolean = false;
  showNotes: boolean = false;
  pageNote: any;
  userId: any;
  studentId: any;

  @Input()
  iNavMobile: boolean = false;

  @Input()
  iFolderStatus: number = 0;

  @Output()
  oFolderStatus: EventEmitter<number> = new EventEmitter();

  constructor(private noteService: NoteService, private fb:UntypedFormBuilder, private tokenStorageService: TokenStorageService,
              private router: Router) {
    this.userId = this.tokenStorageService.getUser().id;
    if (this.router.url.match("/students")) {
      this.studentId = this.router.url.split("/")[2];
    }
    this.noteForm = this.resetForm();
    this.onChangeNotePage(0)
  }

  ngOnInit(): void {
  }

  onCreateNote() {
    let note = this.noteForm?.value;
    this.noteService.createNote(note).subscribe(
      note => {
        $("#noteModal").modal("hide");
        this.noteForm = this.resetForm();
        this.onChangeNotePage(0);
      },
      err => {
        this.popupError = err.error.message;
      }
    );
    this.noteSubmited = false;
    return false;
  }

  isNoteSubmited(form: UntypedFormGroup) {
    this.noteSubmited = true;
  }

  onAddNote() {
    $("#noteModal").modal("show");
  }

  private resetForm(): UntypedFormGroup {
    const noteForm =  this.fb.group({
      date: [moment(new Date()).format('YYYY-MM-DD'), Validators.required],
      userId: [this.userId, Validators.required],
      studentId: [this.studentId],
      description: [null, Validators.required]
    });
    noteForm.get('date')?.disable();
    return noteForm;
  }

  onChangeNotePage(pageNumber: number) {
    if (this.router.url.match("/students")) {
      let sId : bigint | string = this.router.url.split("/")[2];
      this.noteService.getNotesByStudentId(this.studentId, pageNumber, 10).subscribe((pageNote: any) => {
        this.notes = pageNote.content;
        this.pageNote = pageNote;
      });
    } else {
      this.noteService.getMyNotes(pageNumber, 10).subscribe((pageNote: any) => {
        this.notes = pageNote.content;
        this.pageNote = pageNote;
      });
    }
  }

  onDelete(id: bigint) {
    this.noteService.deleteNoteById(id).subscribe(data => {
      this.onChangeNotePage(0);
    }, error => {
    });
  }
  onUpdateStudentFolderStatus(value: number) {
    if (value > 3) {
      value = 0;
    }
    this.iFolderStatus = value;
    this.oFolderStatus.emit(this.iFolderStatus);
  }
}
