<div class="container mt-4">
  <div class="bg-light p-2 pl-5 mb-4 rounded" >
    <h3 *ngIf="student">{{student.lastname}} {{student.firstname}}</h3>
  </div>

  <div class="alert alert-danger col-sm-12 col-md-12 col-lg-12" role="alert" style="text-align: center; font-size: 20px" *ngIf="toPay > 0">
    MONTANT RESTANT A PAYER : {{ toPay | number: "1.2-2"}} €
  </div>
  <div class="alert alert-success col-sm-12 col-md-12 col-lg-12" style="text-align: center; font-size: 20px" role="alert" *ngIf="toPay <= 0">
    PAIEMENT A JOUR : +{{ -toPay | number: "1.2-2"}} €
  </div>

  <div class="card-deck mb-3 text-center">
    <div class="card mb-4 shadow-sm">
      <div class="card-header">
        <h4 class="my-0 font-weight-normal">HEURES DE CONDUITES</h4>
      </div>
      <div class="card-body">
        <h1 class="card-title pricing-card-title">{{drivingEvents.length}} <small class="text-muted">Heures</small></h1>
        <h6 class="text-muted">
          CONSOMMEES ({{hoursConsumedTotal}} H) | RESERVEES ({{drivingEvents.length - hoursConsumedTotal}} H)
        </h6>
        <button type="button" class="btn btn-lg btn-block" [ngClass]="showDrivingEvents ? 'btn-outline-primary' : 'btn-primary'"
                (click)="onShowDrivingEvent()">
          {{showDrivingEvents ? 'CACHER' : 'AFFICHER'}}
        </button>
      </div>
    </div>
    <div class="card mb-4 shadow-sm">
      <div class="card-header">
        <h4 class="my-0 font-weight-normal">PAIEMENTS</h4>
      </div>
      <div class="card-body">
        <h1 class="card-title pricing-card-title">{{totalPayments}}<small class="text-muted"> EUROS</small></h1>
        <button type="button" class="btn btn-lg btn-block" [ngClass]="showPayments ? 'btn-outline-primary' : 'btn-primary'"
                (click)="onShowPayments()">
          {{showPayments ? 'CACHER' : 'AFFICHER'}}
        </button>
      </div>
    </div>
    <div class="card mb-4 shadow-sm">
      <div class="card-header">
        <h4 class="my-0 font-weight-normal">CONSOMMATIONS</h4>
      </div>
      <div class="card-body">
        <h1 class="card-title pricing-card-title">{{totalConsumptions}}<small class="text-muted"> EUROS</small></h1>
        <button type="button" class="btn btn-lg btn-block" [ngClass]="showConsumptions ? 'btn-outline-primary' : 'btn-primary'"
                (click)="onShowConsumptions()">
          {{showConsumptions ? 'CACHER' : 'AFFICHER'}}
        </button>
      </div>
    </div>
  </div>

  <app-payment-list [iShowPayments]="showPayments" [iPayments]="payments" [iPagePayment]="pagePayment" [iNavMobile]="navMobile"
                    (iPageNumber)="onChangePaymentPage($event)">
  </app-payment-list>

  <app-consumption-list [iShowConsumptions]="showConsumptions" [iConsumptions]="consumptions" [iNavMobile]="navMobile"
                        [iPageConsumption]="pageConsumption" (iPageNumber)="onChangeConsumptionPage($event)">
  </app-consumption-list>

  <app-driving-event-list [iShowDrivingEvents]="showDrivingEvents" [iDrivingEvents]="drivingEvents" [iNavMobile]="navMobile"
                          [iPageDrivingEvent]="pageDrivingEvent" (iPageNumber)="onChangeDrivingEventPage($event)">
  </app-driving-event-list>

  <ng-template #loggedOut>
    Please login.
  </ng-template>
</div>
