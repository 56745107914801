import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from '../_services/user.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {StudentService} from '../_services/student.service';
import {ToolsService} from '../_services/tools.service';
import {HomeService} from '../_services/home.service';
import { DOCUMENT } from '@angular/common';

declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  content?: string;
  initStudentForm = this.fb.group({
    sexe: ["F", Validators.required],
    formulaId: [null, Validators.required],
    neph: [null],
    firstname: [null, Validators.required],
    lastname: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
    birthDate: [null, Validators.required],
    birthPlace: [null, Validators.required],
    phoneNumber: [null, [Validators.required, Validators.pattern("0[0-9]{9}")]],
    address: [null, Validators.required],
    postalCode: [null, Validators.required],
    city: [null, Validators.required],
    contractDate: [null],
    settlementDate: [null],
    registrationDate: [moment(new Date()).format('YYYY-MM-DD'), Validators.required],
    hasCode: [null]
  });
  showFormulas: boolean = false;
  messageError: string = "";
  submited: boolean = false;
  sendValidated: boolean = false;
  isAgencySAM: boolean = false;
  formulas: any;

  constructor(private fb:UntypedFormBuilder, private studentService: StudentService, private toolsService: ToolsService,
              private homeService: HomeService, @Inject(DOCUMENT) private document: Document) {
    homeService.getAllFormulas().subscribe(
      formulas => {
        this.formulas = formulas;
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  ngOnInit(): void {
    const currentUrl = this.document.location.href;
    if (currentUrl.includes('autoecolesam.fr')) {
      this.isAgencySAM = true;
    }
  }

  onShowFormulas() {
    this.showFormulas = !this.showFormulas;
  }

  onCreateStudent() {
    return this.initStudentForm.valid;
  }

  isSubmited(form: UntypedFormGroup) {
    if (!form.valid) {
      this.submited = true;
      return;
    }

    this.homeService.initStudent(form.value).subscribe(
      data => {
        this.messageError = "";
      },
      err => {
        this.messageError = err.error.message;
      }
    );
    this.submited = false;
    this.initStudentForm.reset();
    this.sendValidated = true;
    $('#modal-send-validated').modal("show");
  }
}
