import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from './../../environments/environment';

const MONITOR_API = environment.APIEndpoint + '/api/monitors';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class MonitorService {
  constructor(private http: HttpClient) { }

  createMonitor(monitorRequest: any): Observable<any> {
    return this.http.post(MONITOR_API  + '/new', monitorRequest, httpOptions);
  }

  saveMonitor(monitorRequest: any, monitorId: string): Observable<any> {
    return this.http.post(MONITOR_API  + '/' + monitorId + '/save', monitorRequest, httpOptions);
  }

  searchMonitorsByName(name: string): Observable<any> {
    return this.http.post(MONITOR_API + '/search?name=' + name, httpOptions);
  }

  getMonitorById(id: string): Observable<any> {
    return this.http.get(MONITOR_API + '/' + id, httpOptions);
  }

  getMyProfile(): Observable<any> {
    return this.http.get(MONITOR_API + '/my-profile', httpOptions);
  }

  getAllMonitors() : Observable<any> {
    return this.http.get(MONITOR_API, httpOptions);
  }
}
