import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Article, Payment, PaymentMethod, Student} from '../../models/eventRequest';
import {PaymentService} from '../../_services/payment.service';
import * as moment from 'moment';

declare let $ : any;

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.css']
})
export class PaymentFormComponent implements OnInit {

  paymentForm = this.resetForm();

  @Input()
  iNavMobile: boolean = false;

  @Input()
  iStudent?: Student;

  @Input()
  iPaymentMethods: PaymentMethod[] = [];

  @Output()
  oNewPayment: EventEmitter<Payment> = new EventEmitter();

  paymentSubmited: boolean = false;
  popupError: string = "";

  constructor(private fb:UntypedFormBuilder, private paymentService: PaymentService) {
  }

  onCreatePayment() {
    let payment = this.paymentForm?.value;
    this.paymentService.createPayment(payment).subscribe(
      newPayment => {
        this.oNewPayment.emit(newPayment);
        this.paymentForm = this.resetForm();
        this.paymentSubmited = false;
        $("#newPaymentModal").modal("hide");
      },
      err => {
        this.popupError = err.error.message;
      }
    );
  }

  ngOnInit(): void {
  }

  isPaymentSubmited(form: UntypedFormGroup) {
    this.paymentSubmited = true;
    this.paymentForm.controls['studentId'].setValue(this.iStudent?.id)
  }

  private resetForm() : UntypedFormGroup {
    return  this.fb.group({
      date: [moment(new Date()).format('YYYY-MM-DD'), Validators.required],
      studentId: [this.iStudent?.id, Validators.required],
      amount: [null, Validators.required],
      paymentMethod: [null, Validators.required],
      description: [null]
    });
  }
}
