import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {StudentService} from '../../_services/student.service';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {ToolsService} from '../../_services/tools.service';
import {Formula} from '../../models/eventRequest';

declare let $: any;

@Component({
  selector: 'app-create-student',
  templateUrl: './create-student.component.html',
  styleUrls: ['./create-student.component.css']
})
export class CreateStudentComponent implements OnInit {

  formulas: Formula[] = [];
  newStudentForm = this.fb.group({
    sexe: ["", Validators.required],
    formulaId: [null, Validators.required],
    neph: [null],
    firstname: [null, Validators.required],
    lastname: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
    birthDate: [null, Validators.required],
    birthPlace: [null, Validators.required],
    phoneNumber: [null, [Validators.required, Validators.pattern("0[0-9]{9}")]],
    address: [null, Validators.required],
    postalCode: [null, Validators.required],
    city: [null, Validators.required],
    contractDate: [null],
    settlementDate: [null],
    evaluationDate:[null],
    evaluationHours:[null],
    agreedNumberHours:[null],
    registrationDate: [moment(new Date()).format('YYYY-MM-DD'), Validators.required],
    hasCode: [null, Validators.required]
  });
  submited: boolean = false;
  messageError: string = "";

  constructor(private fb:UntypedFormBuilder, private studentService: StudentService,
              private toolsService: ToolsService, private router: Router) {

    toolsService.getActiveFormulas().subscribe(
      formulas => {
        this.formulas = formulas;
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  ngOnInit(): void {
    //$("#registrationDate").prop('disabled', true)
   // this.newStudentForm.controls["registrationDate"].disable();
  }

  onCreateStudent() {
    return this.newStudentForm.valid;
  }

  isSubmited(form: UntypedFormGroup) {
    if (!form.valid) {
      this.submited = true;
      return;
    }

    this.studentService.createStudent(form.value).subscribe(
      data => {
        this.messageError = "";
        this.router.navigate(['students/' + data.id]);
      },
      err => {
        this.messageError = err.error.message;
      }
    );
    this.submited = true;
  }
}
