import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Consumption} from '../../models/eventRequest';

@Component({
  selector: 'app-consumption-list',
  templateUrl: './consumption-list.component.html',
  styleUrls: ['./consumption-list.component.css']
})
export class ConsumptionListComponent implements OnInit {

  @Input()
  iShowConsumptions: boolean = false;

  @Input()
  iPageConsumption: any;

  @Input()
  iConsumptions: Consumption[] = [];

  @Input()
  iNavMobile: boolean = false;

  @Input()
  iIsStudent: boolean = false;

  @Input()
  iIsOneStudent: boolean = false;

  @Input()
  iShowUpdateBtn: boolean = true;

  @Output()
  iPageNumber: EventEmitter<number> = new EventEmitter<number>();


  @Output()
  oConsumptionToUpdate: EventEmitter<Consumption> = new EventEmitter<Consumption>();

  constructor() { }

  ngOnInit(): void {
  }

  onChangeConsumptionPage($event: number) {
    this.iPageNumber.emit($event);
  }

  displayUpdateConsumptionPopup(c: Consumption) {
    this.oConsumptionToUpdate.emit(c);
  }
}
