import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-students-archived',
  templateUrl: './students-archived.component.html',
  styleUrls: ['./students-archived.component.css']
})
export class StudentsArchivedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
  }

}
