import * as moment from 'moment';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

export class EventRequest {

  constructor(public id: string, public startAt: any, public boxType: string, public monitorId: bigint,
              public studentId: bigint, public description: string) {
  }
}

export class NewComment {

  constructor(public bigint: string, public date: Date, public userId: bigint, public studentId: number, public comment: string) {
  }
}

export class CalendarRequest {

  constructor(public monitorId: bigint, public boxType: string, public startAt: any, public endAt: any,
              public hourBreakAt: string, public hourBreakEnd: string) {
  }
}

export class BetweenDates {

  constructor(public startAt: Date, public endAt: Date) {
  }
}

export class BetweenDatesString {
  constructor(public startAt: string, public endAt: string) {
  }
}

export class Monitor {

  constructor(public id: number, public firstname: string, public lastname: string, public email: string, public phoneNumber: string, public color?: string, public enabled?: boolean) {
  }
}

export class Note {

  constructor(public id: bigint, public date: Date, public userId: any, public studentId: any, public description: string) {
  }
}

export class Student {

  constructor(public id: bigint, public firstname: string, public lastname: string, public email: string,
              public phoneNumber: string, public formula: Formula, public status: string, public registrationDate: string, public folderStatus: string) {
  }
}

export class User {

  constructor(public id: bigint, public email: string, public username: string, public password: string) {
  }
}

export class ExtendedProps {

  constructor(public boxType: string, public monitor: Monitor, public student: Student, public active: boolean, public description: string) {
  }
}

export class EventTO {

  constructor(public id: bigint, public startAt: Date, public boxType: string, public description: string, public active: boolean, public monitor : Monitor, public student: Student) {
  }
}

export class Event {

  constructor(public id: bigint, public title: string, public start : string, public end: string,
              public extendedProps: ExtendedProps, public backgroundColor?: string) {
  }
}

export class Article {

  constructor(public id: bigint, public name: string, public description: string, public price: bigint) {
  }
}

export class Consumption {

  constructor(public id: bigint, public date: Date, public cost: any, public quantity: any, public studentName: any, public article: any, public description: string) {
  }
}

export class ConsumptionTO {

  constructor(public date: Date, public studentId: any, public articleId: any, public description: string) {
  }
}

export class ConsumptionUpdate {

  constructor(public id: bigint,public date: Date, public studentId: any, public quantity: any, public article: Article, public description: string) {
  }
}

export class Formula {

  constructor(public id: bigint, public name: string, public description: string, public price: bigint) {
  }
}

export class PaymentMethod {

  constructor(public id: bigint, public name: string, public description: string) {
  }
}

export class PaymentTO {

  constructor(public date: Date, public amount : bigint, public description: string) {
  }
}

export class Payment {

  constructor(public paymentId: bigint, public invoiceNumber: bigint, public date: Date, public amount : bigint,
              public paymentMethod: any, public studentName: any, public student: any, public description: string) {
  }
}
export class PaymentUpdate {

  constructor(public paymentId: bigint, public invoiceNumber: bigint, public date: Date, public amount : bigint,
              public paymentMethod: any, public description: string) {
  }
}

export class Purchase {
  constructor(public id: bigint, public date: Date, public cost: bigint,
              public paymentMethod: any, public reason: any, public description: string) {
  }
}

export class Page {

  constructor(public empty: boolean, public first: boolean, public last: boolean, public number: string) {
  }
}

export class EventForm {

  form?: UntypedFormGroup;

  constructor(public id: bigint, public title: string, public start : string,
              public extendedProps: ExtendedProps ) {
    let dateAt = moment.utc(start).format('YYYY-MM-DD');
    let hourAt = moment.utc(start).format('HH:mm');
    this.form = new UntypedFormBuilder().group({
      id: [id, Validators.min(0)],
      dateAt: [dateAt, Validators.required],
      hourAt: [hourAt, [Validators.required, Validators.minLength(4)]],
      monitorId: [extendedProps.monitor.id, Validators.required],
      studentId: [extendedProps.student?.id],
      description: [null]})
  }
}
