import {Component, OnInit} from '@angular/core';
import {TokenStorageService} from './_services/token-storage.service';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PurchaseService} from './_services/purchase.service';
import {Purchase} from './models/eventRequest';
import {DateService} from './_services/date.service';
import {GlobalService} from './_services/global.service';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  private roles: string[] = [];
  today = new Date();
  expenseForm!: FormGroup;
  products: string[] = ['Gazoile', 'Elève', 'Moniteur', 'Autre'];
  paymentMethods: string[] = ['ESPECE', 'CB', 'VIREMENT', 'CHEQUE'];
  isLoggedIn = false;
  showAdminBoard = false;
  showMonitorBoard = false;
  showStudentBoard = false;
  username?: string;
  navMobile = false;

  resizeObservable$?: Observable<Event>;
  resizeSubscription$?: Subscription;

  constructor(private fb: FormBuilder, private tokenStorageService: TokenStorageService, private purchaseService: PurchaseService,
              private dateService: DateService, private globalService: GlobalService) {
    this.checkWindowSizeChange();
    this.expenseForm = this.fb.group({
      date: [new Date().toISOString().split('T')[0]],
      cost: [''],
      product: [''],
      paymentMethod: [''],
      reason: [''],
      description: ['']
    });
  }


  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();
    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;

      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showMonitorBoard = this.roles.includes('ROLE_MONITOR');
      this.showStudentBoard = this.roles.includes('ROLE_USER');

      this.username = user.username;
    }

    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$?.subscribe( evt => {
      this.checkWindowSizeChange();
    });
  }

  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
  }

  myFunction() {
  }

  checkWindowSizeChange() {
    if(screen.width < 577) {
      this.navMobile = true;
    } else {
      this.navMobile = false;
    }
  }

  onSubmit() {
    const formData = this.expenseForm?.value;
    const purchase = new Purchase(
      formData.id,
      new Date(formData.date),
      formData.cost,
      formData.paymentMethod,
      formData.reason,
      formData.description
    );
    this.purchaseService.addPurchase(purchase).subscribe(
      response => {
        // Close the modal
        $('#expenseModal').modal('hide');
        this.globalService.announceExpenseAdded();
      },
      error => {
        console.error('Error submitting form', error);
      }
    );
  }

}
