<div *ngIf="student" class="container mt-4">
  <div class="bg-light p-2 pl-5 mb-4 rounded" >
    <h3>FICHE ELEVE {{student.lastname}} {{student.firstname}}
      - Tel : {{student.phoneNumber | removewhitespaces}}
      <button type="button" class="btn btn-primary ml-3" (click)="onShowDrivingBook()">SUIVI DRIVE</button>
      <button type="button" class="btn btn-primary ml-3" (click)="onShowDetails()">DETAILS</button>
      <button type="button" class="btn btn-primary ml-3" (click)="onOpenUserForm()">MOT DE PASSE</button>
    </h3>
  </div>
  <div class="bg-danger p-2 pl-5 mb-4 rounded align-content-center" style="text-align: center; color: whitesmoke" *ngIf="isArchived">
    <h2>Dossier Archivé !</h2>
  </div>
  <div *ngIf="student">
    <app-note [iFolderStatus]="getFolderStatus(student.folderStatus)" (oFolderStatus)="updateFolderStatus($event)"></app-note>
  </div>
  <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="messageError.length > 0">
    <strong>ERREUR : </strong> {{messageError}}.
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="deleteMessageError()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="bg-light p-2 pl-5 mb-4 rounded" >
    <h3 *ngIf="student">{{student.formula.name}}
      <span style="font-size: 0.6em">[description : {{student.formula.description}}]</span>
      <div class="btn-group  ml-3">
        <button type="button" [ngClass]="btnValideStatusActive? 'btn btn-info' : 'btn btn-outline-info'"
                (click)="valideNewStudentStatus()" [disabled]="!btnValideStatusActive">
          Statut : {{studentStatusValue}}
        </button>
        <button type="button" class="btn btn-info dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="sr-only">Modifier le Statut</span>
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item" (click)="initNewStudentStatus('TO_CREATE')">A CREER</a>
          <a class="dropdown-item" (click)="initNewStudentStatus('IN_PROGRESS')">EN COURS</a>
          <a class="dropdown-item" (click)="initNewStudentStatus('PERMIS_VALIDATED')">A EU LE PERMIS</a>
          <a class="dropdown-item" (click)="initNewStudentStatus('FINISH')">A QUITTER</a>
          <a class="dropdown-item" (click)="initNewStudentStatus('CANCEL')">ANNULER</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="initNewStudentStatus('CANCEL_UPDATE')">ANNULER LA MODIFICATION</a>
        </div>
      </div>
      <button type="button" class="btn btn-outline-danger ml-2" (click)="removeStudent()" *ngIf="isSuperAdmin() && !isMobilePhone()">
        Supprimer
      </button>
    </h3>
  </div>

  <div class="alert alert-danger col-sm-12 col-md-12 col-lg-12" role="alert" style="text-align: center; font-size: 20px" *ngIf="toPay > 0">
    MONTANT RESTANT A PAYER : {{ toPay | number: "1.2-2"}} €
  </div>
  <div class="alert alert-success col-sm-12 col-md-12 col-lg-12" style="text-align: center; font-size: 20px" role="alert" *ngIf="toPay <= 0">
    PAIEMENT A JOUR : +{{ -toPay | number: "1.2-2"}} €
  </div>

  <div class="card-deck mb-3 text-center">
    <div class="card mb-4 shadow-sm">
      <div class="card-header">
        <h4 class="my-0 font-weight-normal">HEURES DE CONDUITES</h4>
      </div>
      <div class="card-body">
        <h1 class="card-title pricing-card-title" *ngIf="totalDrivingEventsOfStudent <= numberHoursPurchased">{{totalDrivingEventsOfStudent}} / <b>{{numberHoursPurchased}}</b> <small class="text-muted"> Heures</small></h1>
        <h1 class="card-title pricing-card-title" *ngIf="totalDrivingEventsOfStudent > numberHoursPurchased"><b style="color: red">{{totalDrivingEventsOfStudent}}</b> / <b>{{numberHoursPurchased}}</b> <small class="text-muted"> Heures</small></h1>
        <h6 class="text-muted">
          <b>CONSOMMEES ({{numberHoursConsumed}} H)</b> | RESERVEES ({{totalDrivingEventsOfStudent - numberHoursConsumed}} H)
        </h6>
        <button type="button" class="btn btn-lg btn-block" [ngClass]="showDrivingEvents ? 'btn-outline-primary' : 'btn-primary'"
                (click)="onShowDrivingEvent()">
          {{showDrivingEvents ? 'CACHER' : 'AFFICHER'}}
        </button>
      </div>
    </div>
    <div class="card mb-4 shadow-sm">
      <div class="card-header">
        <h4 class="my-0 font-weight-normal">PAIEMENTS</h4>
      </div>
      <div class="card-body">
        <h1 class="card-title pricing-card-title">{{totalPayments}}<small class="text-muted"> EUROS</small></h1>
        <button type="button" class="btn btn-lg btn-block" [ngClass]="showPayments ? 'btn-outline-primary' : 'btn-primary'"
                (click)="onShowPayments()">
          {{showPayments ? 'CACHER' : 'AFFICHER'}}
        </button>
        <button type="button" class="btn btn-lg btn-block btn-primary" (click)="onOpenPaymentForm()" [disabled]="isArchived">
          AJOUTER
        </button>
      </div>
    </div>
    <div class="card mb-4 shadow-sm">
      <div class="card-header">
        <h4 class="my-0 font-weight-normal">CONSOMMATIONS</h4>
      </div>
      <div class="card-body">
        <h1 class="card-title pricing-card-title">{{totalConsumptions}}<small class="text-muted"> EUROS</small></h1>
        <button type="button" class="btn btn-lg btn-block" [ngClass]="showConsumptions ? 'btn-outline-primary' : 'btn-primary'"
                (click)="onShowConsumptions()">
          {{showConsumptions ? 'CACHER' : 'AFFICHER'}}
        </button>
        <button type="button" class="btn btn-lg btn-block btn-primary"  (click)="onOpenConsumptionForm()" [disabled]="isArchived">
          AJOUTER
        </button>
      </div>
    </div>
  </div>

  <app-payment-list [iShowPayments]="showPayments" [iPayments]="payments" [iPagePayment]="pagePayment"
                    (iPageNumber)="onChangePaymentPage($event)" (oPaymentToUpdate)="initPaymenToUpdate($event)" (oPaymentDisabled)="disabledPayment($event)">
  </app-payment-list>

  <app-consumption-list [iShowConsumptions]="showConsumptions" [iConsumptions]="consumptions"
                        [iPageConsumption]="pageConsumption" [iNavMobile]="navMobile" [iIsOneStudent]="true"
                        (iPageNumber)="onChangeConsumptionPage($event)" (oConsumptionToUpdate)="initConsumptionToUpdate($event)">
  </app-consumption-list>

  <app-driving-event-list [iShowDrivingEvents]="showDrivingEvents" [iDrivingEvents]="drivingEvents"
                          [iPageDrivingEvent]="pageDrivingEvent" [iNavMobile]="!navMobile"
                          (iPageNumber)="onChangeDrivingEventPage($event)">
  </app-driving-event-list>

  <app-payment-form *ngIf="!navMobile" (oNewPayment)="onRefreshPayment($event)" [iStudent]="student"
                    [iPaymentMethods]="paymentMethods"></app-payment-form>

  <app-payment-update-form *ngIf="!navMobile" (oPaymentUpdated)="onRefreshPayment($event)" [iPayment]="paymentToUpdate"
                           [iStudent]="student" [iPaymentMethods]="paymentMethods"></app-payment-update-form>

  <app-consumption-form *ngIf="!navMobile" (oNewConsumption)="onAddConsumption($event)"
                        [iStudent]="student" [iArticles]="articles"></app-consumption-form>

  <app-consumption-update-form *ngIf="!navMobile" (oUpdateConsumption)="onRefreshConsumptions($event)"
                               [iStudent]="student" [iArticles]="articles"
                               [iConsumption]="consumptionToUpdate">
  </app-consumption-update-form>

  <app-user-update-pwd *ngIf="student" [iStudent]="student"></app-user-update-pwd>
</div>
