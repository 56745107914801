import { Component, OnInit } from '@angular/core';
import {Monitor} from '../../models/eventRequest';
import {MonitorService} from '../../_services/monitor.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-monitor-list',
  templateUrl: './monitor-list.component.html',
  styleUrls: ['./monitor-list.component.css']
})
export class MonitorListComponent implements OnInit {

  monitors?: Monitor[];

  constructor(private monitorService: MonitorService, private router: Router) {
    this.monitorService.getAllMonitors().subscribe((monitors: Monitor[]) => {
      this.monitors = monitors;
    })
  }

  ngOnInit(): void {
  }

  consultMonitor(id: any) {
    this.router.navigate(['monitors/' + id]);
  }
}
