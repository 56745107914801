<div class="container mt-4">
  <table class="table">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">NOM</th>
      <th scope="col">PRENOM</th>
      <th scope="col">TELEPHONE</th>
      <th scope="col">EMAIL</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let m of monitors" (click)="consultMonitor(m.id)">
      <th scope="row">{{m.id}}</th>
      <td>{{m.lastname}}</td>
      <td>{{m.firstname}}</td>
      <td>{{m.phoneNumber}}</td>
      <td>{{m.email}}</td>
    </tr>
    </tbody>
  </table>
</div>
