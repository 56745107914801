<div *ngIf="iShowConsumptions">
  <h4>LISTE DES CONSOMMATIONS <span *ngIf="iPageConsumption">({{iPageConsumption.totalElements}})</span></h4>
  <table class="table">
    <thead>
    <tr class="row">
      <th scope="col" class="col-auto" *ngIf="!iNavMobile">#</th>
      <th scope="col" class="col">DATE</th>
      <th scope="col" class="col">ARTICLE</th>
      <th scope="col" class="col">PRIX</th>
      <th scope="col" class="col">QUANTITE</th>
      <th scope="col" class="col">MONTANT</th>
      <th scope="col" class="col" *ngIf="!iNavMobile && !iIsOneStudent">ELEVE</th>
      <th scope="col" class="col-4" *ngIf="!iNavMobile && !iIsStudent">DESCRIPTION</th>
      <th scope="col" class="col" *ngIf="!iNavMobile && iShowUpdateBtn && !iIsStudent">MODIFIER</th>
    </tr>
    </thead>
    <tbody>
    <tr class="row" *ngFor="let c of iConsumptions">
      <th class="col-auto" scope="row" *ngIf="!iNavMobile">{{c.id}}</th>
      <td class="col">{{c.date | date:'dd/MM/YYYY'}}</td>
      <td class="col">{{c.article.name}}</td>
      <td class="col">{{c.article.price}} €</td>
      <td class="col">{{c.quantity}}</td>
      <td class="col">{{c.cost}} €</td>
      <td class="col" *ngIf="!iNavMobile && !iIsOneStudent">{{c.studentName}}</td>
      <td class="col-4" *ngIf="!iNavMobile && !iIsStudent">{{c.description}}</td>
      <td class="col" *ngIf="!iNavMobile && iShowUpdateBtn && !iIsStudent" (click)="displayUpdateConsumptionPopup(c)">
        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
      </td>
    </tr>
    </tbody>
    <tfoot>
    <app-pagination *ngIf="iPageConsumption" [iEmpty]="iPageConsumption.empty" [iFirst]="iPageConsumption.first"
                    [iLast]="iPageConsumption.last" [iNumber]="iPageConsumption.number" [iTotalPages]="iPageConsumption.totalPages"
                    (currentPageChanged)="onChangeConsumptionPage($event)" >
    </app-pagination>
    </tfoot>
  </table>
</div>
