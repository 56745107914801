import {Component, Input, OnInit} from '@angular/core';
import {ToolsService} from '../../../_services/tools.service';

declare let $ :any;

@Component({
  selector: 'app-formula-list',
  templateUrl: './formula-list.component.html',
  styleUrls: ['./formula-list.component.css']
})
export class FormulaListComponent implements OnInit {

  @Input() iFormulas: any;
  @Input() iShowFormulas: boolean = false;
  showAllArticles: boolean = false;

  constructor(private toolsService: ToolsService) {
    toolsService.getAllFormulas().subscribe(data => {
      this.iFormulas = data;
    })
  }

  ngOnInit(): void {
  }

  onFormulaSaved($event: any) {
    this.iFormulas = [...this.iFormulas, $event]
    $('#formulaModal').modal("hide");
  }

  onShowFormula() {
    $('#formulaModal').modal("show");
  }

  activeArticle(id: any, active: boolean) {
    this.toolsService.activeArticle(id, active).subscribe(data => {
      let index = this.iFormulas.findIndex(((a: { id: number; }) => a.id == id));
      this.iFormulas[index] = data;
    })
  }

  checkValue(showAllArticles: boolean) {

  }
}
