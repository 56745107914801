<div class="modal fade" id="newPaymentModal" tabindex="-1" role="dialog" aria-labelledby="newPaymentLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="newPaymentTitle" name="startAt" *ngIf="iStudent">
          AJOUTER UN PAIEMENT - # {{iStudent.lastname}} {{iStudent.firstname}}
        </h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div id="newPaymentError" class="alert alert-danger mt-4 ml-4 mr-4" role="alert" *ngIf="popupError && popupError.length > 0">
        {{popupError}}
      </div>

      <form [formGroup]="paymentForm" #f="ngForm" name="newEventForm" (ngSubmit)="f.form.valid && onCreatePayment()" novalidate>
        <div>
          <div class="form-row ml-4 p-1 mt-4">
            <div class="input-group raw mb-3">
              <div class="col-4">
                <label for="payment-date-u" class="col-form-label">CHOISIR LA DATE</label>
                <input id="payment-date-u" type="date" class="form-control form-control-lg form-control-borderless"
                       name="date" formControlName="date" required aria-describedby="basic-addon2">
                <p class="alert alert-danger p-0" role="alert" *ngIf="paymentSubmited && f.form.controls.date.status == 'INVALID'">
                  Merci de définir la date !
                </p>
              </div>
              <div class="col-6">
                <label for="amount-c" class="col-form-label">MONTANT DU PAIEMENT</label>
                <input id="amount-c" type="number" class="form-control form-control-lg form-control-borderless"
                       name="amount" formControlName="amount" required aria-describedby="basic-addon2" min="1">
                <p class="alert alert-danger p-0" role="alert" *ngIf="paymentSubmited && f.form.controls.amount.status == 'INVALID'">
                  Merci de définir un montant !
                </p>
              </div>
            </div>
          </div>
          <div class="form-row ml-4 p-1 mt-1">
            <div class="input-group raw mb-3">
              <div class="col-4">
                <label for="newPayment-mode" class="col-form-label">MODE DE PAIEMENT</label>
                <select id="newPayment-mode" class="form-control form-control-lg form-control-borderless" formControlName="paymentMethod"> <!--(change)="selectMonitor($event)" -->
                  <option [ngValue]="null" disabled></option>
                  <option value="ESPECE">ESPECE</option>
                  <option value="CHEQUE">CHEQUE</option>
                  <option value="VIREMENT">VIREMENT</option>
                  <option value="CB">CB</option>
                </select>
                <p class="alert alert-danger p-0" role="alert" *ngIf="paymentSubmited && f.form.controls.paymentMethod.status == 'INVALID'">
                  Merci de choisir une méthode de paiement !
                </p>
              </div>
              <div class="col-6">
                <label for="createPaymentDescription" class="col-form-label">DESCRIPTION</label>
                <textarea class="form-control" id="createPaymentDescription" name="description" formControlName="description"  rows="3"></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">ANNULER</button>
          <button type="button" class="btn btn-primary" type="submit" (click)="isPaymentSubmited(f.form)">VALIDER</button>
        </div>
      </form>
    </div>
  </div>
</div>
