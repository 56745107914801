<div class="container mt-4">
  <h4>LISTE DES DEPENSES <span *ngIf="iPagePurchase">({{iPagePurchase.totalElements}})</span></h4>

  <div class="bg-light p-2 pl-5 mb-4 rounded">
    <div class="input-group-append">
      <button type="button" class="btn btn-outline-secondary" (click)="openCalendarForm()">Calendrier</button>
      <button type="button" class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="sr-only">Periode</span>
      </button>
      <div class="dropdown-menu">
        <a class="dropdown-item" (click)="onChangePeriode('today')">Aujourd'hui</a>
        <a class="dropdown-item" (click)="onChangePeriode('week')">Cette Semaine</a>
        <a class="dropdown-item" (click)="onChangePeriode('lastWeek')">Semaine Dernière</a>
        <a class="dropdown-item" (click)="onChangePeriode('month')">Ce mois ci</a>
      </div>
      <h4 class="col">PERIODE : {{periode}}</h4>
    </div>
  </div>

  <table class="table">
    <thead>
    <tr class="row">
      <th scope="col" class="col">#</th>
      <th scope="col" class="col">MONTANT</th>
      <th scope="col" class="col">MODE</th>
      <th scope="col" class="col">RAISON</th>
      <th scope="col" class="col">DESCRIPTION</th>
    </tr>
    </thead>
    <tbody>
    <tr class="row" *ngFor="let p of iPurchases">
      <td class="col">{{p.date | date:'dd/MM/YYYY HH:mm'}}</td>
      <td class="col">{{p.cost}} €</td>
      <td class="col">{{p.paymentMethod}}</td>
      <td class="col">{{p.reason}}</td>
      <td class="col">{{p.description}}</td>
    </tr>
    </tbody>
    <tfoot>
    <app-pagination *ngIf="iPagePurchase" [iEmpty]="iPagePurchase.empty" [iFirst]="iPagePurchase.first"
                    [iLast]="iPagePurchase.last" [iNumber]="iPagePurchase.number" [iTotalPages]="iPagePurchase.totalPages"
                    (currentPageChanged)="onChangePurchasePage($event)" >
    </app-pagination>
    </tfoot>
  </table>
</div>

<div class="modal fade" id="calendarModal" tabindex="-1" role="dialog" aria-labelledby="calendarLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="newEventTitle" name="startAt">
          Choisir la période
        </h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <form [formGroup]="calendarForm" #f="ngForm" name="newEventForm" (ngSubmit)="f.form.valid" novalidate>
        <div>
          <div class="form-row ml-4 p-1 mt-4">
            <div class="input-group raw mb-3">
              <div class="col-4">
                <label for="startDate" class="col-form-label">DEBUT</label>
                <input id="startDate" type="date" class="form-control form-control-lg form-control-borderless"
                       name="startDate" formControlName="startDate" required aria-describedby="basic-addon2">
              </div>
              <div class="col-4">
                <label for="endDate" class="col-form-label">FIN</label>
                <input id="endDate" type="date" class="form-control form-control-lg form-control-borderless"
                       name="endDate" formControlName="endDate" required aria-describedby="basic-addon2">
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">ANNULER</button>
          <button type="button" class="btn btn-primary" type="submit" (click)="isSubmited(f.form)">VALIDER</button>
        </div>
      </form>
    </div>
  </div>
</div>
