<div class="container mt-4">


  <div class="bg-light text-center mb-4 rounded p-1" >
    <h4 *ngIf="this.student">Les Remarques de #{{student.lastname}} {{student.firstname}}
    <button type="button" class="btn btn-block btn-primary"  (click)="addComment()" *ngIf="isMonitor">
      AJOUTER
    </button>
    </h4>
  </div>

  <div *ngFor="let d of drivingComments">
    <div class="p-2 mb-1 bg-secondary text-white">
      #{{d.id}} :  {{d.date | date:'dd/MM/YYYY HH:mm'}} par {{d.author}}
      <i class="fa fa-pencil-square-o fa-1x" aria-hidden="true" (click)="editComment(d.id, d.date, d.comment)" *ngIf="d.userId == userId"></i>
    </div>
    <p class="p-2" id="c-{{d.id}}">{{d.comment}}</p>
  </div>

  <div class="modal fade" id="drivingCommentModal" tabindex="-1" role="dialog" aria-labelledby="newArticleLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" name="startAt" *ngIf="student && !commentForm.value.id">
            AJOUTER UNE REMARQUE - # {{student.lastname}} {{student.firstname}}
          </h3>
          <h3 class="modal-title" name="startAt" *ngIf="student && commentForm.value.id">
            MODIFIER LA REMARQUE - #{{commentForm.value.id}} {{student.lastname}} {{student.firstname}}
          </h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <form [formGroup]="commentForm" #f2="ngForm" name="commentForm" (ngSubmit)="f2.form.valid && validComment()" novalidate>
          <input id="comment-date" type="date" class="form-control form-control-lg form-control-borderless m-4 col-8"
                   name="date" formControlName="date" required aria-describedby="basic-addon2" readonly>
          <textarea class="form-control col-10 m-4" id="d-comment" name="comment" formControlName="comment" rows="3"></textarea>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">ANNULER</button>
            <button type="button" class="btn btn-primary" type="submit">VALIDER</button>
          </div>
        </form>
      </div>
    </div>
  </div>


</div>

