import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Payment} from '../../models/eventRequest';
import {PaymentService} from '../../_services/payment.service';
import {ToolsService} from '../../_services/tools.service';
//import 'rxjs/Rx';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.css']
})
export class PaymentListComponent implements OnInit {

  @Input()
  iShowPayments: boolean = false;

  @Input()
  iPagePayment: any;

  @Input()
  iPayments: Payment[] = [];

  @Input()
  iTotalPaymentDetails: any;

  @Input()
  iShowStudent: boolean = false;

  @Input()
  iIsStudent: boolean = false;

  @Input()
  iNavMobile: boolean = false;

  @Input()
  iShowUpdateBtn: boolean = true;

  @Output()
  iPageNumber: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  oPaymentToUpdate: EventEmitter<Payment> = new EventEmitter<Payment>();

  @Output()
  oPaymentDisabled: EventEmitter<bigint> = new EventEmitter<bigint>();

  userRoles = [];

  constructor(private paymentService: PaymentService, private toolsService: ToolsService) {
    toolsService.getUserRoles().subscribe(
      (data: any) => {
        // @ts-ignore
        this.userRoles = data;
      },
      err => {
      });
  }

  ngOnInit(): void {
  }

  onChangePaymentPage($event: number) {
    this.iPageNumber.emit($event)
  }

  displayUpdatePaymentPopup(p: Payment) {
    this.oPaymentToUpdate.emit(p);
  }

  displayDisabledPayment(p: bigint) {
    this.oPaymentDisabled.emit(p);
  }

  uploadInvoice(paymentId: bigint) {
    this.paymentService.uploadInvoice(paymentId).subscribe(
      data => {
        var file = new Blob([data], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
          },
      error => {
        console.log('Error downloading the file.')
        console.log(error)
          });
  }

  isSuperAdmin() {
    // @ts-ignore
    return this.userRoles.indexOf('ROLE_SUPER_ADMIN') > -1;
  }
}
