import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ToolsService} from '../../../_services/tools.service';

declare let $ : any;

@Component({
  selector: 'app-formula-form',
  templateUrl: './formula-form.component.html',
  styleUrls: ['./formula-form.component.css']
})
export class FormulaFormComponent implements OnInit {

  formulaForm = this.resetForm();
  popupError: string = "";
  submited: boolean = false;

  @Output() oFormulaSaved: EventEmitter<any> = new EventEmitter();

  constructor(private fb:UntypedFormBuilder, private toolsService: ToolsService) { }

  ngOnInit(): void {
  }

  onSaveFormula() {
    this.toolsService.saveFormula(this.formulaForm.value).subscribe(data => {
      this.oFormulaSaved.emit(data);
      this.formulaForm = this.resetForm();
    })
    return true;
  }

  isSubmited(form: UntypedFormGroup) {
    this.submited = true;
  }

  ngAfterViewInit() {
    $("#formulaModal").on("hidden.bs.modal", () => {
      this.submited = false;
      this.popupError = "";
    });
  }

  resetForm() : UntypedFormGroup{
    return this.fb.group({
      name: [null, Validators.required],
      price: [null, Validators.required],
      nbHours: [0, Validators.required],
      roomAccess: [false, Validators.required],
      aac: [false, Validators.required],
      description: [null],
      boxType: [null, Validators.required],
    });
  }
}
