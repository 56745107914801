<div class="container">
  <app-event-new [newEventDriving]="newEventDriving" (newEventDrivingChanged)="addNewEvent($event)"></app-event-new>
  <app-events-monitor></app-events-monitor>
  <app-event-edit [editEventDriving]="editEventDriving" (editEventDrivingChanged)="updateEvent($event)"
                  (deleteEventChanged)="deleteEvent($event)"></app-event-edit>
  <full-calendar [options]="calendarOptions"></full-calendar>
</div>

<div class="modal" id="calendarInformationsModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Nombre d'heure par moniteur</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="list-group">
          <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let info of calendarInfos">
            #{{info[0].id}}  {{info[0].firstname}}  {{info[0].lastname}}
            <span class="badge badge-primary badge-pill">{{info[1]}}</span>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">FERMER</button>
      </div>
    </div>
  </div>
</div>
