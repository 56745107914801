import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BetweenDates, Purchase} from '../../models/eventRequest';
import {PurchaseService} from '../../_services/purchase.service';
import {Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {DateService} from '../../_services/date.service';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {GlobalService} from '../../_services/global.service';


declare let $: any;

@Component({
  selector: 'app-purchase-list',
  templateUrl: './purchase-list.component.html',
  styleUrls: ['./purchase-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PurchaseListComponent implements OnInit, OnDestroy {

  subscription: Subscription;

  pageNumber = 0;
  pageSize = 100;
  messageError = '';
  periode = '';
  periodeName = 'Cette semaine';
  betweenDates: BetweenDates = this.dateService.getCurrentWeekBoundaries();

  calendarForm = this.fb.group({
    startDate: [new Date(), Validators.required],
    endDate: [new Date(), Validators.required],
  });

  @Input()
  iStartDate = new Date();
  @Input()
  iEndDate = new Date();
  @Input()
  iPurchases?: Purchase[];

  @Input()
  iPagePurchase: any;

  @Output()
  iPageNumber: EventEmitter<number> = new EventEmitter<number>();
  private showCalendarForm = false;

  constructor(
    private fb: FormBuilder,
    public purchaseService: PurchaseService,
    private router: Router,
    private dateService: DateService,
    private cdr: ChangeDetectorRef,
    private globalService: GlobalService

  ) {
    const { start, end } = this.dateService.getWeekBoundaries(new Date());
    this.calendarForm = this.fb.group({
      startDate: [start, Validators.required],
      endDate: [end, Validators.required],
    });
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.betweenDates = this.dateService.getCurrentWeekBoundaries();
    this.updatePeriode(this.betweenDates);
    this.getPurchases(this.betweenDates);
    this.subscription = this.globalService.expenseAdded$.subscribe(
      (added: boolean) => {
        if (added) {
          this.getPurchases(this.betweenDates);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getPurchases(betweenDates: BetweenDates): void {

    this.purchaseService.getPurchasesBetweenDates(betweenDates).subscribe(
      pageable => {
        this.iPagePurchase = pageable;
        this.iPurchases = pageable.content;
        this.cdr.detectChanges();
      },
      error => {
        console.error('Error fetching purchases:', error);
        this.messageError = 'Erreur lors de la récupération des achats.';
      }
    );
  }

  onChangePurchasePage($event: number): void {
    this.iPageNumber.emit($event);
    this.pageNumber = $event;

    this.getPurchases(this.betweenDates);
  }

  onChangePeriode(periode: string): void {
    switch (periode) {
      case 'today':
        this.periodeName = 'Aujourd\'hui';
        this.betweenDates = this.dateService.getDayBoundaries();
        break;
      case 'week':
        this.periodeName = 'Cette Semaine';
        this.betweenDates = this.dateService.getCurrentWeekBoundaries();
        break;
      case 'lastWeek':
        this.periodeName = 'La Semaine Dernière';
        this.betweenDates = this.dateService.getLastWeekBoundaries();
        break;
      case 'month':
        this.periodeName = 'Ce Mois ci';
        this.betweenDates = this.dateService.getCurrentMonthBoundaries();
        break;
      default:
        console.error('Unknown period:', periode);
        return;
    }
    this.updatePeriode(this.betweenDates);
    this.getPurchases(this.betweenDates);
  }

  updatePeriode(betweenDates: BetweenDates): void {
    this.periode = `DU ${moment(betweenDates.startAt).format('DD/MM/YYYY')} AU ${moment(betweenDates.endAt).format('DD/MM/YYYY')}`;
  }
  openCalendarForm(): void {
    this.showCalendarForm = true;
    $('#calendarModal').modal('show');
  }

  onSubmitCalendarForm(): boolean {
    $('#calendarModal').modal('hide');
    this.betweenDates = new BetweenDates(this.calendarForm.value.startDate ?? new Date(), this.calendarForm.value.endDate ?? new Date());
    this.getPurchases(this.betweenDates);
    this.updatePeriode(this.betweenDates);
    return true;
  }

  isSubmited(form: any): void {
    if (form.valid) {
      // Convertit les objets Date en chaînes de caractères formatées
      const startDate = this.calendarForm.get('startDate')?.value;
      const endDate = this.calendarForm.get('endDate')?.value;
      if (startDate && endDate) {
        this.getPurchases(new BetweenDates(startDate, endDate));
        $('#calendarModal').modal('hide');  // Ferme le modal après la validation
      } else {
        console.error('Les dates ne sont pas définies');
      }
    } else {
      console.error('Le formulaire n’est pas valide');
    }
  }

}
