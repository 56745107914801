import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {StudentService} from '../../_services/student.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {EventService} from '../../_services/event.service';
import {ProfileService} from '../../_services/profile.service';

declare let $: any;

@Component({
  selector: 'app-driving-book',
  templateUrl: './driving-book.component.html',
  styleUrls: ['./driving-book.component.css']
})
export class DrivingBookComponent implements OnInit {

  isPhone = false;
  isStudent = false;

  studentId: string = this.router.url.split('/')[2];
  student?: any;
  messageError = '';
  numberHoursConsumed = 0;
  driveBook: any;
  suiviDriveForm: UntypedFormGroup = this.fb.group({});
  questionIdsMap = new Map<string, number>();
  driveBookId = '-1';

  questionsRequest: Array<any> = [];
  competenceId = 0;

  // SCORE
  scoreC1 = 0;
  scoreC2 = 0;
  scoreC3 = 0;
  scoreC4 = 0;



  constructor(private studentService: StudentService, private eventService: EventService, private profileService: ProfileService,
              private router: Router, private fb: UntypedFormBuilder) {
    if (screen.width < 450) {
      this.isPhone = true;
    }
    if (this.router.url.match('/my-drive')) {
      this.isStudent = true;
    }

    if (this.router.url.match('/my-drive')) {
      this.getMyProfile();
      this.getMyNumberHoursConsumed();
      this.getMyDriveBookScore();
      this.getMyDriveBook();
    } else {
      this.getStudentById(this.studentId);
      this.getStudentNumberHoursConsumed(this.studentId);
      this.getDriveBookScore();
    }
  }

  ngOnInit(): void {
  }

  private getStudentById(studentId: string): void {
    this.studentService.getStudentsById(studentId).subscribe(
      student => {
        this.student = student;
        this.getDriveBookByStudentId(studentId);
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  getStudentNumberHoursConsumed(studentId: string): void {
    this.studentService.getNumberHoursConsumedByStudentId(this.studentId)
      .subscribe(
      number => {
        this.numberHoursConsumed = number;
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  getDriveBookByStudentId(studentId: string): void {
    this.studentService.getSuiviDrive(this.studentId).subscribe(
      driveBook => {
        if (driveBook.id === null) {
          this.submit();
        }
        this.driveBookId = driveBook.id === 'null' ? '-1' : driveBook.id;
        if (driveBook.questions != null) {
          this.questionsRequest = driveBook.questions;
        }
        this.resetForm();
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  getDriveBookScore(): void {
    this.studentService.getDriveBookScore(this.studentId).subscribe(
      driveBookScore => {
        driveBookScore.forEach((d: { questionId: string; score: number}) => {
          this.changeScore(d.questionId, d.score);
        });
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  getMyDriveBook(): void {
    this.profileService.getMySuiviDrive().subscribe(
      driveBook => {
        if (driveBook.id == null) {
          this.submit();
        }
        this.driveBookId = driveBook.id === 'null' ? '-1' : driveBook.id;
        if (driveBook.questions != null) {
          this.questionsRequest = driveBook.questions;
        }
        this.resetForm();
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  getMyDriveBookScore(): void {
    this.profileService.getDriveBookScore().subscribe(
      driveBookScore => {
        driveBookScore.forEach((d: { questionId: string; score: number}) => {
          this.changeScore(d.questionId, d.score);
        });
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  changeScore(qId: string, score: number): void {
    switch (qId) {
      case 'c1': this.scoreC1 = (score / 72) * 100; break;
      case 'c2': this.scoreC2 = (score / 45) * 100; break;
      case 'c3': this.scoreC3 = (score / 84) * 100; break;
      case 'c4': this.scoreC4 = (score / 48) * 100; break;
    }
  }

  submit(): boolean {
    this.driveBookId = this.driveBookId === 'null' ? '-1' : this.driveBookId;
    this.studentService.saveSuiviDrive(this.studentId, this.driveBookId, this.questionsRequest).subscribe(
      driveBook => {
        this.driveBookId = driveBook.id;
        if (driveBook.questions === null) {
          this.questionsRequest = [];
        } else {
          this.questionsRequest = driveBook.questions;
        }
        this.resetForm();
        this.getDriveBookScore();
      },
      err => {
        this.messageError = err.error.message;
      });
    this.competenceId = 0;
    return true;
  }

  resetForm(): UntypedFormGroup {
    this.suiviDriveForm = this.fb.group({});
    this.questionsRequest?.forEach(q => {
      this.questionIdsMap.set(q.questionId, q.id);
      this.suiviDriveForm.addControl(q.questionId, new UntypedFormControl(q.score, Validators.required));
    });
    return this.suiviDriveForm;
  }


  editForm($event: any): void {
    const q = this.questionsRequest?.find(this.findIndexToUpdate, $event.qId);
    const qId = this.questionIdsMap.get($event.qId);
    if (q === undefined) {
      this.questionsRequest.push({id: qId, questionId: $event.qId, score: $event.score});
    } else {
      const index = this.questionsRequest.indexOf(q);
      this.questionsRequest[index] = {id: qId, questionId: $event.qId, score: $event.score};
    }
    this.suiviDriveForm.setControl($event.qId, new UntypedFormControl($event.score, Validators.required));
  }

  findIndexToUpdate(newItem: any): boolean {
    return newItem.questionId === this;
  }

  changeCompetencePage(number: number): void {
    const newPage = this.competenceId + number;
    if ( newPage <= 0 || newPage >= 5 ) {
      return;
    }
    this.competenceId += number;
  }

  goToComments(): void {
    if (this.isStudent) {
      this.router.navigate(['/my-book-comments']);
    } else {
      this.router.navigate(['students/' + this.studentId + '/book-comments']);
    }
  }

  getMyProfile(): void {
    this.profileService.getMyProfile().subscribe(
      student => {
        this.student = student;
        this.studentId = student.id;
      },
      err => {
        this.messageError = JSON.parse(err.error).message;
      }
    );
  }

  getMyNumberHoursConsumed(): void {
    this.profileService.getMyTotalHoursConsumed().subscribe(
      number => {
        this.numberHoursConsumed = number;
      },
      err => {
        this.messageError = err.error.message;
      });
  }
}
