<div class="container mt-4">
  <div class="bg-light p-2 pl-5 mb-4 rounded" >
    <h3>CREER UN MONITEUR</h3>
  </div>

  <div class="alert alert-danger" role="alert" *ngIf="messageError != null && messageError.length > 0">
    <p class="alert-heading mb-0">Une erreur a été rencontrée : {{messageError}}</p>
    <hr>
  </div>

  <form class="needs-validation" id="saveMonitorForm" [formGroup]="monitorForm" #f="ngForm"
        (ngSubmit)="f.form.valid && onSaveMonitor()" novalidate>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label for="madam"></label>
        <div>
          <div class="form-check form-check-inline">
            <input class="form-check-input input-form" type="radio" name="sexe" formControlName="sexe" id="madam" value="F">
            <label class="form-check-label" for="madam">MADAME</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input input-form" type="radio" name="sexe" formControlName="sexe" id="sir" value="M">
            <label class="form-check-label" for="sir">MONSIEUR</label>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-3">
        <label for="name">Couleur</label>
        <input type="color" class="form-control input-form" name="color" ngModel="{{defaultColor}}" formControlName="color">
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label for="name">Nom</label>
        <input type="text" class="form-control input-form" id="name" formControlName="lastname" placeholder="Nom" required>
        <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.lastname.status == 'INVALID')">
          Merci de définir le nom !
        </p>
      </div>
      <div class="col-md-4 mb-3">
        <label for="firstname">Prénom</label>
        <input type="text" class="form-control input-form" id="firstname" formControlName="firstname" placeholder="Prénom" required>
        <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.firstname.status == 'INVALID')">
          Merci de définir le prénom !
        </p>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label for="email">Email</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroupPrepend">@</span>
          </div>
          <input type="email" class="form-control" id="email" formControlName="email" placeholder="Email" required>
        </div>
        <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.email.status === 'INVALID')">
          Email invalide ou déjà utilisé !
        </p>
      </div>
      <div class="col-md-4 mb-3">
        <label for="phoneNumber">Téléphone</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">T</span>
          </div>
          <input type="text" class="form-control input-form" id="phoneNumber" formControlName="phoneNumber" placeholder="Téléphone" required>
        </div>
        <p class="p-alert" role="alert" *ngIf="submited && (f.form.controls.phoneNumber.status == 'INVALID')">
          Merci de définir un numéro de téléphone !
        </p>
      </div>
    </div>

    <button class="btn btn-primary input-form" type="submit" (click)="isSubmited(f.form)">CREER UN MONITEUR</button>
  </form>
</div>
