import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Event} from '../../models/eventRequest';

@Component({
  selector: 'app-driving-event-list',
  templateUrl: './driving-event-list.component.html',
  styleUrls: ['./driving-event-list.component.css']
})
export class DrivingEventListComponent implements OnInit {

  toDay: Date = new Date();

  @Input()
  iShowDrivingEvents: boolean = false;

  @Input()
  iPageDrivingEvent: any;

  @Input()
  iDrivingEvents: Event[] = [];

  @Input()
  iShowStudent: boolean = false;

  @Input()
  iNavMobile: boolean = false;

  @Output()
  iPageNumber: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  getDate(stringDate: string) : Date {
    return new Date(stringDate)
  }

  onChangeConsumptionPage($event: number) {
    this.iPageNumber.emit($event);
  }
}
