import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {StudentService} from '../../../_services/student.service';
import {MonitorService} from '../../../_services/monitor.service';
import {EventService} from '../../../_services/event.service';
import {CalendarRequest, Monitor} from '../../../models/eventRequest';
import * as moment from 'moment';

declare let $: any;

@Component({
  selector: 'app-events-monitor',
  templateUrl: './events-monitor.component.html',
  styleUrls: ['./events-monitor.component.css']
})
export class EventsMonitorComponent implements OnInit {

  monitors?: Monitor[];
  popupError?: string;
  calendarForm = this.fb.group({
    monitorId: [null, Validators.required],
    boxType: [null, Validators.required],
    dateAt: [null, Validators.required],
    hourAt: [null, Validators.required],
    dateEnd: [null, Validators.required],
    hourEnd: [null, Validators.required],
    hourBreakAt: [null],
    hourBreakEnd: [null]
  });
  hours: string[] = ["07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00"]
  breakHours: number[] = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  submited: boolean = false;

  @Input() eventsMonitorDriving: any;
  @Output() eventsMonitorDrivingChanged: EventEmitter<any> = new EventEmitter();

  constructor(private fb:UntypedFormBuilder, private studentService: StudentService, private monitorService: MonitorService,
              private eventService: EventService) { }

  ngOnInit(): void {
  }

  initEventsMonitorForm(monitors: Monitor[]) {
    this.monitors = monitors;
  }

  onCreateMonitorCalendar(): void {
    let calendarForm = this.calendarForm?.value;
    let breakAt = calendarForm.hourBreakAt > 9 ? calendarForm.hourBreakAt : "0" + calendarForm.hourBreakAt;
    let breakEnd = calendarForm.hourBreakEnd > 9 ? calendarForm.hourBreakEnd : "0" + calendarForm.hourBreakEnd;
    let calendar: CalendarRequest = new CalendarRequest(
      calendarForm.monitorId,
      calendarForm.boxType,
      moment.utc(calendarForm.dateAt + 'T' + calendarForm.hourAt).format('YYYY-MM-DDTHH:mm'),
      moment.utc(calendarForm.dateEnd + 'T' + calendarForm.hourEnd).format('YYYY-MM-DDTHH:mm'),
      moment.utc(calendarForm.dateAt + 'T' + breakAt).format('YYYY-MM-DDTHH:mm'),
      moment.utc(calendarForm.dateAt + 'T' + breakEnd).format('YYYY-MM-DDTHH:mm'),
    );
    this.eventService.createMonitorCalendar(calendar).subscribe(
      data => {
        $('#calendarMonitorModal').modal('hide');
        $('#modal-calendar-created').modal('show');
      },
      err => {
        this.popupError = err.error.message;
      }
    );
  }

  ngAfterViewInit() {
    $('#calendarMonitorModal').on('hidden.bs.modal', () => {
      this.submited = false;
      this.calendarForm.reset();
      $('#monitorInput').val('');
      this.popupError = '';
    });

    $('#modal-calendar-created').on('hidden.bs.modal', () => {
      window.location.reload();
    });
  }

  isSubmited(form: UntypedFormGroup) {
    this.submited = true;
  }
}
