import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Consumption, Student} from '../../models/eventRequest';
import {StudentService} from '../../_services/student.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-student-list',
  templateUrl: './student-list.component.html',
  styleUrls: ['./student-list.component.css']
})
export class StudentListComponent implements OnInit {

  pageNumber = 0;
  pageSize = 50;
  messageError = '';
  inputSearchName = '';

  @Input()
  iStudents?: Student[];

  @Input()
  iPageStudent: any;

  @Input()
  iIsArchived = false;

  @Output()
  iPageNumber: EventEmitter<number> = new EventEmitter<number>();


  constructor(public studentService: StudentService, private router: Router) {
  }

  ngOnInit(): void {
    if (!this.router.url.match("/admin")) {
      this.getAllStudent();
    }
  }

  ngOnChanges() {
  }

  onSearchChange(input: any) {
    this.inputSearchName = input.value;
    this.searchStudent(this.inputSearchName, 0);
    return;
  }

  consultStudent(id: bigint) {
    this.router.navigate(['students/' + id]);
  }

  searchStudent(name: string, pageNumber: number) {
    this.studentService.searchStudentByName(name, pageNumber, this.iIsArchived).subscribe(
      studentPageable => {
        this.iPageStudent = studentPageable;
        this.iStudents = studentPageable.content;
      },
      err => {
        this.messageError = err;
      }
    );
  }

  getAllStudent() {
    this.studentService.searchStudentByName('', this.pageNumber, this.iIsArchived).subscribe(studentPageable => {
      this.iPageStudent = studentPageable;
      this.iStudents = studentPageable.content;
    });
  }

  onChangeStudentPage($event: number) {
    this.iPageNumber.emit($event);
  }

  getStatusValue(status: string) : string{
    var statusValue = "NON DEFINI";
    switch (status) {
      case "TO_CREATE" : statusValue = "A CREER"; break;
      case "IN_PROGRESS" : statusValue = "EN COURS"; break;
      case "PERMIS_VALIDATED" : statusValue = "A EU LE PERMIS"; break;
      case "FINISH" : statusValue = "A QUITTER"; break;
      case "CANCEL" : statusValue = "ANNULE"; break;
    }
    return statusValue;
  }
}
