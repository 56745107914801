import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Article, Payment, PaymentMethod, PaymentUpdate, Student} from "../../models/eventRequest";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {PaymentService} from "../../_services/payment.service";
import * as moment from "moment";

declare let $ : any;

@Component({
  selector: 'app-payment-update-form',
  templateUrl: './payment-update-form.component.html',
  styleUrls: ['./payment-update-form.component.css']
})
export class PaymentUpdateFormComponent implements OnInit {
  paymentForm = this.initForm();

  @Input()
  iNavMobile: boolean = false;

  @Input()
  iStudent?: Student;

  @Input()
  iPayment?: PaymentUpdate;

  @Input()
  iPaymentMethods: PaymentMethod[] = [];

  @Output()
  oPaymentUpdated: EventEmitter<Payment> = new EventEmitter();

  paymentSubmited: boolean = false;
  popupError: string = "";
  updateInvoice: boolean = true;

  constructor(private fb:UntypedFormBuilder, private paymentService: PaymentService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.paymentForm = this.resetForm();
  }

  private resetForm() : UntypedFormGroup {
    return  this.fb.group({
      paymentId: [this.iPayment?.paymentId, Validators.required],
      invoiceNumber: [this.iPayment?.invoiceNumber],
      date: [moment.utc(this.iPayment?.date).format('YYYY-MM-DD'), Validators.required],
      amount: [this.iPayment?.amount, Validators.required],
      paymentMethod: [this.iPayment?.paymentMethod, Validators.required],
      description: [this.iPayment?.description]
    });
  }

  onUpdatePayment() {
    let payment = this.paymentForm?.value;
    this.paymentService.updatePayment(payment).subscribe(
      updatedPayment => {
        this.oPaymentUpdated.emit(updatedPayment);
        this.paymentForm = this.resetForm();
        this.paymentSubmited = false;
        $("#updatePaymentModal").modal("hide");
      },
      err => {
        this.popupError = err.error.message;
      }
    );
  }

  isPaymentSubmited(form: UntypedFormGroup) {
    this.paymentSubmited = true;
  }

  private initForm() {
    return  this.fb.group({
      paymentId: this.iPayment?.paymentId,
      invoiceNumber: this.iPayment?.invoiceNumber,
      date: this.iPayment?.date,
      amount: this.iPayment?.amount,
      paymentMethod: this.iPayment?.paymentMethod,
      description: this.iPayment?.description
    });
  }
}
