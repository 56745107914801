import {Injectable} from '@angular/core';
import { Router } from '@angular/router';
import {TokenStorageService} from './token-storage.service';
import {Observable, of} from 'rxjs';
import {AuthService} from './auth.service';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IsAdmin  {

  constructor(private tokenStorage: TokenStorageService, private authService: AuthService, private router: Router) {
  }

  canActivate(): boolean | Observable<any> {
    if (this.tokenStorage.getToken()) {
      var roles = this.tokenStorage.getUser().roles;
      if (roles.includes("ROLE_ADMIN")) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }
}
