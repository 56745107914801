<div class="container mt-4">
  <app-note></app-note>

  <div class="bg-light p-2 pl-5 mb-4 rounded">
      <div class="input-group-append">
        <button type="button" class="btn btn-outline-secondary" (click)="openCalendarForm()">Calendrier</button>
        <button type="button" class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="sr-only">Periode</span>
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item" (click)="onChangePeriode('today')">Aujourd'hui</a>
          <a class="dropdown-item" (click)="onChangePeriode('week')">Cette Semaine</a>
          <a class="dropdown-item" (click)="onChangePeriode('lastWeek')">Semaine Dernière</a>
          <a class="dropdown-item" (click)="onChangePeriode('month')">Ce mois ci</a>
        </div>
        <h4 class="col">PERIODE : {{periode}}</h4>
      </div>

  </div>

  <div class="card-deck mb-3 text-center">
    <div class="card mb-4 shadow-sm">
      <div class="card-header">
        <h4 class="my-0 font-weight-normal">INSCRIPTIONS</h4>
      </div>
      <div class="card-body">
        <h1 class="card-title pricing-card-title">{{totalRegistrations}} <small class="text-muted">Inscriptions</small></h1>
        <button type="button" class="btn btn-lg btn-block" [ngClass]="showRegistrations ? 'btn-outline-primary' : 'btn-primary'"
                (click)="onShowRegistrations()">
          {{showRegistrations ? 'CACHER' : 'AFFICHER'}}
        </button>
      </div>
    </div>
    <div class="card mb-4 shadow-sm">
      <div class="card-header">
        <h4 class="my-0 font-weight-normal">PAIEMENTS</h4>
      </div>
      <div class="card-body">
        <h1 class="card-title pricing-card-title">{{totalPayments}}<small class="text-muted"> EUROS</small></h1>
        <button type="button" class="btn btn-lg btn-block" [ngClass]="showPayments ? 'btn-outline-primary' : 'btn-primary'"
                (click)="onShowPayments()">
          {{showPayments ? 'CACHER' : 'AFFICHER'}}
        </button>
      </div>
    </div>
    <div class="card mb-4 shadow-sm">
      <div class="card-header">
        <h4 class="my-0 font-weight-normal">ACHATS</h4>
      </div>
      <div class="card-body" [title]="totalByPaymentMethodTooltip">
        <h1 class="card-title pricing-card-title">{{ purcharseDetails?.totalAmount }}<small class="text-muted"> EUROS</small></h1>
      </div>
    </div>
  </div>

  <app-payment-list [iShowStudent]="true" [iShowPayments]="showPayments" [iPayments]="paymentPage?.content"
                    [iPagePayment]="paymentPage" [iShowUpdateBtn]="false" [iTotalPaymentDetails]="totalPaymentDetails"
                    (iPageNumber)="onChangePaymentPage($event)">
  </app-payment-list>

  <app-student-list *ngIf="showRegistrations"
                    [iPageStudent]="studentPage" [iStudents]="studentPage?.content"
                    (iPageNumber)="onChangeStudentsPage($event)">
  </app-student-list>

</div>


<div class="modal fade" id="calendarModal" tabindex="-1" role="dialog" aria-labelledby="calendarLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="newEventTitle" name="startAt">
          Choisir la période
        </h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <form [formGroup]="calendarForm" #f="ngForm" name="newEventForm" (ngSubmit)="f.form.valid && onSubmitCalendarForm()" novalidate>
        <div>
          <div class="form-row ml-4 p-1 mt-4">
            <div class="input-group raw mb-3">
              <div class="col-4">
                <label for="startDate" class="col-form-label">DEBUT</label>
                <input id="startDate" type="date" class="form-control form-control-lg form-control-borderless"
                       name="startDate" formControlName="startDate" required aria-describedby="basic-addon2">
              </div>
              <div class="col-4">
                <label for="endDate" class="col-form-label">FIN</label>
                <input id="endDate" type="date" class="form-control form-control-lg form-control-borderless"
                       name="endDate" formControlName="endDate" required aria-describedby="basic-addon2">
              </div>
            </div>
          </div>
          <p class="alert alert-danger ml-4 mr-4 p-0" role="alert" *ngIf="submited && f.form.value.startDate == null">
            Merci de définir la date de début !
          </p>
          <p class="alert alert-danger ml-4 mr-4 p-0" role="alert" *ngIf="submited && (f.form.value.endDate == null)">
            Merci de définir la date de fin !
          </p>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">ANNULER</button>
          <button type="button" class="btn btn-primary" type="submit" (click)="isSubmited(f.form)">VALIDER</button>
        </div>
      </form>
    </div>
  </div>
</div>
