import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MonitorService} from '../../_services/monitor.service';
import {Router} from '@angular/router';

declare let $: any;

@Component({
  selector: 'app-create-monitor',
  templateUrl: './monitor-create.component.html',
  styleUrls: ['./monitor-create.component.css']
})
export class MonitorCreateComponent implements OnInit {

  monitorForm = this.fb.group({
    sexe: ["F", Validators.required],
    firstname: [null, Validators.required],
    lastname: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
    phoneNumber: [null, [Validators.required, Validators.pattern("0[0-9]{9}")]],
    color: ["#40f81b"]
  });
  submited: boolean = false;
  messageError: string = "";
  defaultColor = "#40f81b";


  constructor(private fb:UntypedFormBuilder, private monitorService: MonitorService, private router: Router) {
  }

  ngOnInit(): void {

  }

  onSaveMonitor() {
    let monitorForm = this.monitorForm?.value;
    this.monitorService.createMonitor(monitorForm).subscribe(
      data => {
        this.router.navigate(['monitors/' + data.id])
      },
      err => {
        this.messageError = err.error.message;
      }
    );
    return true;
  }

  private getMonitorForm(data: any) {
    return this.fb.group({
      id: data.id,
      sexe: [data.sexe, Validators.required],
      firstname: [data.firstname, Validators.required],
      lastname: [data.lastname, Validators.required],
      email: [data.email, [Validators.required, Validators.email]],
      phoneNumber: [data.phoneNumber, [Validators.required, Validators.pattern('0[0-9]{9}')]],
      color: [data.color]
    });
  }

  isSubmited(form: UntypedFormGroup) {
    this.submited = true;
  }

  onEditActive() {
    $(".input-form").removeAttr('disabled');
  }
}

