<div *ngIf="iShowDrivingEvents">
  <h4>LISTE DES HEURES <span *ngIf="iPageDrivingEvent">({{iPageDrivingEvent.totalElements}})</span></h4>
  <table class="table">
    <thead>
    <tr>
      <th scope="col" *ngIf="!iNavMobile && iShowStudent">#</th>
      <th scope="col">DATE ET HEURE</th>
      <th scope="col">MONITEUR</th>
      <th scope="col">TYPE</th>
      <th scope="col">DESCRIPTION</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let d of iDrivingEvents" [ngClass]="getDate(d.start).getTime() < toDay.getTime() ? 'event-consumed' : 'event-reserved'">
      <th scope="row" *ngIf="!iNavMobile && iShowStudent">{{d.id}}</th>
      <td>{{d.start | date:'dd/MM/YYYY HH:mm'}}</td>
      <td>{{d.extendedProps.monitor.firstname}}</td>
      <td>{{d.extendedProps.boxType == 'AUTOMATIQUE' ? 'AUTOMATIQUE' : 'MANUELLE'}}</td>
      <td>{{d.extendedProps.description}}</td>
    </tr>
    </tbody>
    <tfoot>
    <app-pagination *ngIf="iPageDrivingEvent" [iEmpty]="iPageDrivingEvent.empty" [iFirst]="iPageDrivingEvent.first"
                    [iLast]="iPageDrivingEvent.last" [iNumber]="iPageDrivingEvent.number" [iTotalPages]="iPageDrivingEvent.totalPages"
                    (currentPageChanged)="onChangeConsumptionPage($event)" >
    </app-pagination>
    </tfoot>
  </table>
</div>
