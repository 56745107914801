import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Article, Consumption, ConsumptionUpdate, PaymentUpdate, Student} from '../../models/eventRequest';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ConsumptionService} from '../../_services/consumption.service';
import * as moment from 'moment';

declare let $ : any;

@Component({
  selector: 'app-consumption-update-form',
  templateUrl: './consumption-update-form.component.html',
  styleUrls: ['./consumption-update-form.component.css']
})
export class ConsumptionUpdateFormComponent implements OnInit {


  consumptionForm = this.resetForm();

  @Input()
  iStudent?: Student;

  @Input()
  iArticles: Article[] = [];

  @Input()
  iNavMobile: boolean = false;

  @Input()
  iConsumption?: Consumption;

  @Output()
  oUpdateConsumption: EventEmitter<Consumption> = new EventEmitter();

  consumptionSubmited: boolean = false;
  popupError: string = "";

  constructor(private fb:UntypedFormBuilder, private consumptionService: ConsumptionService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.consumptionForm = this.resetForm();
  }

  onUpdateeConsumption() {
    let consumption = this.consumptionForm?.value;
    this.consumptionService.updateConsumption(consumption).subscribe(
      consumption => {
        this.oUpdateConsumption.emit(consumption);
        this.consumptionForm = this.resetForm();
        this.consumptionSubmited = false;
        $("#updateConsumptionModal").modal("hide");
      },
      err => {
        this.popupError = err.error.message;
      }
    );
  }

  isConsumptionSubmited(form: UntypedFormGroup) {
    this.consumptionSubmited = true;
    this.consumptionForm.controls['studentId'].setValue(this.iStudent?.id)
  }

  resetForm() : UntypedFormGroup{
    return this.fb.group({
      id: [this.iConsumption?.id, Validators.required],
      date: [moment.utc(this.iConsumption?.date).format('YYYY-MM-DD'), Validators.required],
      studentId: [this.iStudent?.id, Validators.required],
      articleId: [this.iConsumption?.article.id, Validators.required],
      quantity: [this.iConsumption?.quantity, Validators.required],
      description: [this.iConsumption?.description]
    });
  }
}
