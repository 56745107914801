import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {BetweenDates, BetweenDatesString} from '../models/eventRequest';
import * as moment from 'moment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class DateService {

  getWeekBoundaries(date: Date): {start: Date, end: Date} {
    const start = new Date(date);
    start.setDate(start.getDate() - start.getDay());
    const end = new Date(start);
    end.setDate(start.getDate() + 6);
    return {start, end};
  }

  getStartOfWeek(date: Date): Date {
    const result = new Date(date);
    result.setDate(result.getDate() - result.getDay());
    return result;
  }

  getEndOfWeek(date: Date): Date {
    const result = new Date(date);
    result.setDate(result.getDate() - result.getDay() + 6);
    return result;
  }

  getDayBoundaries(): BetweenDates {
    const date = new Date();
    const offset = date.getTimezoneOffset() * 60 * 1000;  // Convertir le décalage horaire de minutes à millisecondes
    const start = new Date();
    start.setHours(0, 0, 0, 0);
    const end = new Date();
    start.setHours(23, 59, 59, 999);
    return new BetweenDates(start, end);
  }


  getCurrentWeekBoundaries(): BetweenDates {
    const date = new Date();
    const start = new Date(date);
    // Ajustement pour que la semaine commence le lundi
    const daysToLastMonday = date.getDay() === 0 ? 6 : date.getDay() - 1;
    start.setDate(date.getDate() - daysToLastMonday);
    start.setHours(0, 0, 0, 0);
    const end = new Date(start);
    end.setDate(start.getDate() + 6);
    end.setHours(23, 59, 59, 999);
    return new BetweenDates(start, end);
  }

  getLastWeekBoundaries(): BetweenDates {
    const date = new Date();
    const start = new Date(date);
    // Ajustement pour que la semaine commence le lundi
    const daysToLastMonday = date.getDay() === 0 ? 6 : date.getDay() - 1;
    start.setDate(date.getDate() - daysToLastMonday - 7);
    start.setHours(0, 0, 0, 0);
    const end = new Date(start);
    end.setDate(start.getDate() + 6);
    end.setHours(23, 59, 59, 999);
    return new BetweenDates(start, end);
  }


  getCurrentMonthBoundaries(): BetweenDates {
    const date = new Date();
    const start = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999);
    return new BetweenDates(start, end);
  }


  getBetweenDatesString(dates: BetweenDates) {
    // Utilisez moment.utc pour travailler en UTC et éviter le décalage horaire.
    const startMoment = moment(dates.startAt).startOf('day');
    const endMoment = moment(dates.endAt).endOf('day');
    // Format dates to strings
    const startDate = startMoment.format('YYYY-MM-DDTHH:mm:ss[Z]');
    const endDate = endMoment.format('YYYY-MM-DDTHH:mm:ss[Z]');
    return new BetweenDatesString(startDate, endDate);
  }

  getDateNow() {
    return moment().format('YYYY-MM-DDTHH:mm:ssZ');
  }

  getTodayStart(): Date {
    return new Date(new Date().setHours(0, 0, 0, 0));
  }

  getTodayEnd(): Date {
    return new Date(new Date().setHours(23, 59, 59, 999));
  }

  getStartWeek(): Date {
    const date = new Date();
    if (date.getDay() === 1) {
      return date;
    }
    date.setDate(date.getDate() - 6 + date.getDay());
    return date;
  }

  getEndWeek(): Date {
    const date = this.getStartWeek();
    date.setDate(date.getDate() + 6);
    return date;
  }

  getStartLastWeek(): Date {
    const date = this.getStartWeek();
    date.setDate(date.getDate() - 7);
    return date;
  }

  getEndLastWeek(): Date {
    const date = this.getStartWeek();
    date.setDate(date.getDate() - 1);
    return date;
  }

  getStartMonth(): Date {
    const year = moment(new Date()).year();
    let month = '' + (moment(new Date()).month() + 1);
    month = month.length === 1 ? '0' + month : month;
    return new Date(year + '-' + month + '-01');
  }

  getEndMonth(): Date {
    const year = moment(new Date()).year();
    let month = '' + (moment(new Date()).month() + 1);
    month = month.length === 1 ? '0' + month : month;
    return new Date(year + '-' + month + '-' + moment(new Date()).daysInMonth());
  }
}
