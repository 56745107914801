<nav aria-label="..." *ngIf="!iEmpty" id="nav-pagination">
  <ul class="pagination">
    <li id="firstPage" [ngClass]="iFirst ? 'page-item disabled' : 'page-item'">
      <a class="page-link" (click)="changePage('first')" tabindex="-1">0</a>
    </li>
    <li [ngClass]="iFirst ? 'page-item disabled' : 'page-item'">
      <a class="page-link" (click)="changePage('previous')">
        <span aria-hidden="true">&laquo;</span>
      </a>
    </li>
    <li class="page-item active">
      <a class="page-link">
        {{iNumber}}<span class="sr-only">(current)</span>
      </a>
    </li>
    <li [ngClass]="iLast ? 'page-item disabled' : 'page-item'">
      <a class="page-link" (click)="changePage('next')">
        <span aria-hidden="true">&raquo;</span>
      </a>
    </li>
    <li id="lastPage" [ngClass]="iLast ? 'page-item disabled' : 'page-item'">
      <a class="page-link" (click)="changePage('last')">{{iTotalPages == 0 ? 0 : iTotalPages-1}}</a>
    </li>
  </ul>
</nav>
