import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {RegisterComponent} from './register/register.component';
import {LoginComponent} from './login/login.component';
import {HomeComponent} from './home/home.component';
import {ProfileComponent} from './profile/profile.component';
import {BoardStudentComponent} from './board-student/board-student.component';
import {BoardMonitorComponent} from './board-monitor/board-monitor.component';
import {BoardAdminComponent} from './board-admin/board-admin.component';
import {CalendarComponent} from './calendar/calendar.component';
import {IsAdmin} from './_services/IsAdmin';
import {IsMonitor} from './_services/IsMonitor';
import {IsStudent} from './_services/IsStudent';
import {CreateStudentComponent} from './student/create-student/create-student.component';
import {MonitorCreateComponent} from './monitor/monitor-create/monitor-create.component';
import {MonitorSaveComponent} from './monitor/monitor-save/monitor-save.component';
import {MonitorListComponent} from './monitor/monitor-list/monitor-list.component';
import {StudentListComponent} from './student/student-list/student-list.component';
import {StudentSaveComponent} from './student/student-save/student-save.component';
import {ConfigurationComponent} from './configuration/configuration.component';
import {StudentDetailsComponent} from './student/student-details/student-details.component';
import {HoursComponent} from './board-student/hours/hours.component';
import {StudentsArchivedComponent} from './student/students-archived/students-archived.component';
import {DrivingBookComponent} from './student/driving-book/driving-book.component';
import {CommentsComponent} from './student/driving-book/comments/comments.component';
import {IsMonitorOrAdmin} from './_services/IsMonitorOrAdmin';
import {PurchaseListComponent} from './purchase/purchase-list/purchase-list.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'calendar', component: CalendarComponent, canActivate: [IsAdmin] },
  { path: 'new-student', component: CreateStudentComponent, canActivate: [IsAdmin] },
  { path: 'students', component: StudentListComponent, canActivate: [IsAdmin] },
  { path: 'students/:id/details', component: StudentSaveComponent, canActivate: [IsAdmin] },
  { path: 'students/:id', component: StudentDetailsComponent, canActivate: [IsAdmin] },
  { path: 'students/:id/drive-book', component: DrivingBookComponent, canActivate: [IsMonitorOrAdmin] },
  { path: 'my-drive', component: DrivingBookComponent, canActivate: [IsStudent] },
  { path: 'students/:id/book-comments', component: CommentsComponent, canActivate: [IsMonitorOrAdmin] },
  { path: 'students-archived', component: StudentsArchivedComponent, canActivate: [IsAdmin] },
  { path: 'my-profile', component: StudentDetailsComponent, canActivate: [IsStudent] },
  { path: 'my-book-comments', component: CommentsComponent, canActivate: [IsStudent] },
  { path: 'my-calendar', component: HoursComponent, canActivate: [IsStudent] },
  { path: 'my-board', component: BoardStudentComponent, canActivate: [IsStudent] },
  { path: 'new-monitor', component: MonitorCreateComponent, canActivate: [IsAdmin] },
  { path: 'monitors', component: MonitorListComponent, canActivate: [IsAdmin] },
  { path: 'monitors/:id', component: MonitorSaveComponent, canActivate: [IsAdmin] },
  { path: 'configurations', component: ConfigurationComponent, canActivate: [IsAdmin] },
  { path: 'register', component: RegisterComponent },
  { path: 'profile', component: ProfileComponent, canActivate: [IsAdmin]},
  { path: 'user', component: BoardStudentComponent, canActivate: [IsStudent]},
  { path: 'monitor-calendar', component: BoardMonitorComponent, canActivate: [IsMonitor] },
  { path: 'admin', component: BoardAdminComponent, canActivate: [IsAdmin] },
  { path: 'purchases', component: PurchaseListComponent, canActivate: [IsAdmin] },
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
