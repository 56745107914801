
<div style="border: solid" *ngIf="iShowArticle">
  <div class="bg-light p-2 pl-5 mb-4 rounded" >
    <h5>LISTE DES ARTICLES
      <button type="button" class="ml-5 btn btn-success"
              (click)="onShowArticle()">
        AJOUTER
      </button>
      <div class="form-check form-check-inline" style="margin-left: 20%">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" checked [(ngModel)]="showAllArticles"
               (change)="checkValue(showAllArticles)">
        <label class="form-check-label" for="inlineCheckbox1">Afficher tous les articles</label>
      </div>
    </h5>
  </div>
  <table class="table">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">ARTICLE</th>
      <th scope="col">PRIX</th>
      <th scope="col">NB HEURES</th>
      <th scope="col">BOITE</th>
      <th scope="col">DESCRIPTION</th>
      <th scope="col">STATUT</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let a of iArticles">
      <th *ngIf="a.active || showAllArticles" scope="row">{{a.id}}</th>
      <td *ngIf="a.active || showAllArticles">{{a.name}}</td>
      <td *ngIf="a.active || showAllArticles">{{a.price}} €</td>
      <td *ngIf="a.active || showAllArticles">{{a.nbHours}}</td>
      <td *ngIf="a.active || showAllArticles">{{a.boxType}}</td>
      <td *ngIf="a.active || showAllArticles">{{a.description}}</td>
      <td *ngIf="a.active || showAllArticles">
        <button type="button" class="btn btn-primary" *ngIf="a.active" (click)="activeArticle(a.id, true)">Activé</button>
        <button type="button" class="btn btn-danger" *ngIf="!a.active" (click)="activeArticle(a.id, false)">Désactivé</button>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<app-article-form (oArticleSaved)="onArticleSaved($event)"></app-article-form>
