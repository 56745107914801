import {Component, OnInit} from '@angular/core';
import {BetweenDates, Purchase} from '../models/eventRequest';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {PaymentService} from '../_services/payment.service';
import {ConsumptionService} from '../_services/consumption.service';
import {StudentService} from '../_services/student.service';
import {DateService} from '../_services/date.service';
import {PurchaseService} from '../_services/purchase.service';

declare let $: any;

@Component({
  selector: 'app-board-admin',
  templateUrl: './board-admin.component.html',
  styleUrls: ['./board-admin.component.css']
})
export class BoardAdminComponent implements OnInit {

  totalRegistrations: any;
  studentPage: any;
  showRegistrations = false;
  totalPayments: any;
  totalPaymentDetails: any;
  paymentPage: any;
  showPayments = false;
  purcharseDetails: any;
  showPurchases = false;
  messageError = '';
  betweenDates: BetweenDates = this.dateService.getCurrentWeekBoundaries();
  startDate: Date = this.dateService.getStartWeek();
  endDate: Date = this.dateService.getEndWeek();
  periodeName = 'Cette semaine';
  periode = '';
  calendarForm = this.fb.group({
    startDate: [null, Validators.required],
    endDate: [null, Validators.required],
  });
  showCalendarForm = false;
  submited = false;

  constructor(private fb: UntypedFormBuilder, private studentService: StudentService, private paymentService: PaymentService,
              private consumptionService: ConsumptionService, private purchaseService: PurchaseService, private dateService: DateService) {
    this.updatePeriode(this.betweenDates);
    this.loadAllDataPage(this.betweenDates, 0);
  }


  ngOnInit(): void {
    this.updatePeriode(this.dateService.getCurrentWeekBoundaries());
  }

  updatePeriode(betweenDates: BetweenDates): void {
    this.periode = `DU ${moment(betweenDates.startAt).format('DD/MM/YYYY')} AU ${moment(betweenDates.endAt).format('DD/MM/YYYY')}`;
  }

  onShowRegistrations(): void {
    this.showRegistrations = !this.showRegistrations;
  }

  onShowPayments(): void {
    this.showPayments = !this.showPayments;
  }

  onShowPurchases(): void {
    this.showPurchases = !this.showPurchases;
  }

  onChangeStudentsPage($event: number): void {
    this.getStudentsPage(this.betweenDates, $event);
  }

  onChangePaymentPage($event: number): void {
    this.getPaymentsPage(this.betweenDates, $event);
  }

  onChangePeriode(periode: string): void {
    switch (periode) {
      case 'today':
        this.periodeName = 'Aujourd\'hui';
        this.betweenDates = this.dateService.getDayBoundaries();
        break;
      case 'week':
        this.periodeName = 'Cette Semaine';
        this.betweenDates = this.dateService.getCurrentWeekBoundaries();
        break;
      case 'lastWeek':
        this.periodeName = 'La Semaine Dernière';
        this.betweenDates = this.dateService.getLastWeekBoundaries();
        break;
      case 'month':
        this.periodeName = 'Ce Mois ci';
        this.betweenDates = this.dateService.getCurrentMonthBoundaries();
        break;
      default:
        console.error('Unknown period:', periode);
        return;
    }
    this.updatePeriode(this.betweenDates);
    this.loadAllDataPage(this.betweenDates, 0);
  }


  private loadAllDataPage(betweenDates: BetweenDates, page: number): void {
    this.getStudentsPage(betweenDates, page);
    this.getPaymentsPage(betweenDates, page);
    this.getTotalPayments(betweenDates);
    this.getPurchases(betweenDates);
  }

  private getTotalPayments(dates: BetweenDates): void {
    this.paymentService.getTotalPaymentsBetweenDates(dates).subscribe(
      data => {
        this.totalPayments = data;
      }, error => {
        this.messageError = error;
      }
    );
    this.paymentService.getTotalGroupByPaymentsBetweenDates(dates).subscribe(
      data => {
        this.totalPaymentDetails = data;
      }, error => {
        this.messageError = error;
      }
    );
  }

  private getStudentsPage(dates: BetweenDates, page: number): void {
    this.studentService.getStudentsBetweenDates(dates, page, 10).subscribe(
      data => {
        this.studentPage = data;
        this.totalRegistrations = data.totalElements;
      },
      err => {
        this.messageError = err;
      }
    );
  }

  private getPaymentsPage(dates: BetweenDates, page: number): void {
    this.paymentService.getPaymentsBetweenDates(dates, page, 10).subscribe(
      data => {
        this.paymentPage = data;
      },
      err => {
        this.messageError = err;
      }
    );
  }

  private getPurchases(dates: BetweenDates): void {
    this.purchaseService.getPurchasesBetweenDates(dates).subscribe(
      response => {
        // Initialisez l'objet purchases avec les valeurs par défaut appropriées.
        const purchases: {
          totalAmount: number;
          totalByPaymentMethod: Map<string, number>;
          purchases: Purchase[];
        } = {
          totalAmount: 0,
          totalByPaymentMethod: new Map<string, number>(),
          purchases: response.content
        };

        // Accumuler les totaux par mode de paiement et le montant total
        response.content.forEach((purchase: any) => {
          const totalCost = purchase.cost * purchase.quantity;
          const currentTotal = purchases.totalByPaymentMethod.get(purchase.paymentMethod) || 0;
          purchases.totalByPaymentMethod.set(purchase.paymentMethod, currentTotal + totalCost);
          purchases.totalAmount += totalCost;
        });
        this.purcharseDetails = purchases;
      },
      error => {
        this.messageError = error;
      }
    );
  }

  openCalendarForm(): void {
    this.showCalendarForm = true;
    $('#calendarModal').modal('show');
  }

  onSubmitCalendarForm(): boolean {
    $('#calendarModal').modal('hide');
    this.betweenDates = new BetweenDates(this.calendarForm.value.startDate, this.calendarForm.value.endDate);
    this.getPaymentsPage(this.betweenDates, 0);
    this.getTotalPayments(this.betweenDates);
    this.getStudentsPage(this.betweenDates, 0);
    this.updatePeriode(this.betweenDates);
    return true;
  }

  isSubmited(form: UntypedFormGroup): void {
    this.submited = true;
  }

  get totalByPaymentMethodTooltip(): string {
    if (!this.purcharseDetails) {
      return 'Tu as ajouté les achats ? :)';
    }
    const entries: [string, number][] = Array.from(this.purcharseDetails.totalByPaymentMethod.entries());
    return entries.map(([method, amount]) => `${method}: ${amount.toFixed(2)}€`).join('\n');
  }

  ngAfterViewInit(): void {
    $('#calendarModal').on('hidden.bs.modal', () => {
      this.submited = false;
      this.calendarForm.reset();
    });
  }
}
