import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {

  @Input()
  id?: any;

  @Input()
  qId?: string;

  @Input()
  score = 0;

  @Input()
  text = '';

  @Input()
  isStudent = false;

  @Output() scoreChanged: EventEmitter<any> = new EventEmitter();



  constructor() { }

  ngOnInit(): void {
  }

  incrment(): void {
    this.score++;
    if (this.score > 3) {
      this.score = 0;
    }
    this.scoreChanged.emit(this);
  }

  getColor(): string {
    let color = 'btn';
    switch (this.score) {
      case 1: color = 'btn btn-danger'; break;
      case 2: color = 'btn btn-warning'; break;
      case 3: color = 'btn btn-success'; break;
      default: color = 'btn';
    }
    return color;
  }
}
