import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BetweenDates, CalendarRequest, EventRequest, NewComment} from '../models/eventRequest';
import {environment} from './../../environments/environment';
import {DateService} from './date.service';

const EVENT_API = environment.APIEndpoint + '/api/events';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class EventService {
  constructor(private dateService: DateService, private http: HttpClient) { }


  createEvent(event: EventRequest): Observable<any> {
    return this.http.post(EVENT_API , event, httpOptions);
  }

  updateEvent(event: EventRequest): Observable<any> {
    return this.http.put(EVENT_API , event, httpOptions);
  }

  updateEventComment(eventId: string, newComment: NewComment): Observable<any> {
    return this.http.put(EVENT_API + '/' + eventId + '/comment', newComment, httpOptions);
  }

  deleteEvent(eventId: string): Observable<any> {
    return this.http.delete(EVENT_API + '/' + eventId);
  }

  getEventsBetweenDates(dates: BetweenDates, monitorId?: string): Observable<any> {
    const datesString = this.dateService.getBetweenDatesString(dates);
    if (monitorId === null || monitorId?.length === 0) {
      return this.http.post(EVENT_API + '/between-dates' , datesString, httpOptions);
    } else {
      return this.http.post(EVENT_API + '/between-dates?monitorId=' + monitorId , datesString, httpOptions);
    }
  }

  getInfosBetweenDates(dates: BetweenDates, monitorId?: string): Observable<any> {
    const datesString = this.dateService.getBetweenDatesString(dates);
    return this.http.post(EVENT_API + '/between-dates/infos' , datesString, httpOptions);
  }

  createMonitorCalendar(calendar: CalendarRequest): Observable<any> {
    return this.http.post(EVENT_API + '/monitors/', calendar, httpOptions);
  }
}
