<div class="container mt-4">


  <div *ngIf="student" class="text-left text-light bg-dark p-1 rounded">
    <h5>{{student.lastname}} {{student.firstname}}</h5>
    <span>{{numberHoursConsumed}} Leçons</span>
    <button type="button" class="btn btn-primary ml-3" (click)="goToComments()">REMARQUES</button>
  </div>
  <div class="bg-light text-center mb-4 rounded p-1" >
    <h4>SUIVI DRIVE #{{driveBookId ? driveBookId : 'A CREER'}}</h4>
  </div>

  <div *ngIf="competenceId == 0">
    <div *ngIf="isPhone">
      <div class="row">
        <div class="col" (click)="competenceId=1">
          <circle-percentage [title]="'C1'" [progress]="scoreC1" [color]="'#26c4ec'" [text]="'MAITRISER LE MANIEMENT DU VEHICULE DANS UN TRAFIC FAIBLE OU NUL.'"></circle-percentage>
        </div>
        <div class="col" (click)="competenceId=2">
          <circle-percentage [title]="'C2'" [progress]="scoreC2" [color]="'#fd3f92'" [text]="'APPREHENDER LA ROUTE ET CIRCULER DANS DES CONDITIONS NORMALES.'"></circle-percentage>
        </div>
      </div>
      <div class="row">
        <div class="col" (click)="competenceId=3">
          <circle-percentage [title]="'C3'" [progress]="scoreC3" [color]="'#57d53b'" [text]="'CIRCULER DANS DES CONDITIONS DIFFICILES ET PARTAGER LA ROUTE..'"></circle-percentage>
        </div>
        <div class="col" (click)="competenceId=4">
          <circle-percentage [title]="'C4'" [progress]="scoreC4" [color]="'#ff7f14'" [text]="'PRATIQUER UNE CONDUITE AUTONOME, SÛRE ET ECONOMIQUE.'"></circle-percentage>
        </div>
      </div>
    </div>
    <div *ngIf="!isPhone">
    <div class="row">
      <div class="col" (click)="competenceId=1">
        <circle-percentage [title]="'C1'" [progress]="scoreC1" [color]="'#26c4ec'" [text]="'MAITRISER LE MANIEMENT DU VEHICULE DANS UN TRAFIC FAIBLE OU NUL.'"></circle-percentage>
      </div>
      <div class="col" (click)="competenceId=2">
        <circle-percentage [title]="'C2'" [progress]="scoreC2" [color]="'#fd3f92'" [text]="'APPREHENDER LA ROUTE ET CIRCULER DANS DES CONDITIONS NORMALES.'"></circle-percentage>
      </div>
      <div class="col" (click)="competenceId=3">
        <circle-percentage [title]="'C3'" [progress]="scoreC3" [color]="'#57d53b'" [text]="'CIRCULER DANS DES CONDITIONS DIFFICILES ET PARTAGER LA ROUTE.'"></circle-percentage>
      </div>
      <div class="col" (click)="competenceId=4">
        <circle-percentage [title]="'C4'" [progress]="scoreC4" [color]="'#ff7f14'" [text]="'PRATIQUER UNE CONDUITE AUTONOME, SÛRE ET ECONOMIQUE.'"></circle-percentage>
      </div>
    </div>
  </div>
  </div>
  <form *ngIf="competenceId != 0" class="needs-validation" [formGroup]="suiviDriveForm" #f="ngForm" name="editEventForm"
        (ngSubmit)="f.form.valid && submit()" novalidate>

    <div class="">
      <div class="d-flex justify-content-center" style="margin-bottom: 5px">
        <button type="button" class="btn btn-info" [ngClass]="competenceId <= 1 ? 'disabled' : ''" (click)="changeCompetencePage(-1)">
          <<
        </button>
        <button type="button" class="btn btn-info" [ngClass]="competenceId >= 4 ? 'disabled' : ''" (click)="changeCompetencePage(1)">
          >>
        </button>
        <button class="btn btn-primary" type="submit" (click)="submit()" *ngIf="!isStudent">ENREGISTRER</button>
      </div>
      <div *ngIf="competenceId == 1" class="p-2 pl-5 mb-4 rounded" style="background-color: #26c4ec;">
        <h5 class="c-title">C1 : MAITRISER LE MANIEMENT DU VEHICULE DANS UN TRAFIC FAIBLE OU NUL.</h5>
      </div>
      <div *ngIf="competenceId == 2" class="p-2 pl-5 mb-4 rounded" style="background-color: #fd3f92">
        <h5 class="c-title">C2 : APPREHENDER LA ROUTE ET CIRCULER DANS DES CONDITIONS NORMALES.</h5>
      </div>
      <div *ngIf="competenceId == 3" class="p-2 pl-5 mb-4 rounded" style="background-color: #57d53b">
        <h5 class="c-title">C3 : CIRCULER DANS DES CONDITIONS DIFFICILES ET PARTAGER LA ROUTE AVEC LES AUTRES USAGERS.</h5>
      </div>
      <div *ngIf="competenceId == 4" class="p-2 pl-5 mb-4 rounded" style="background-color: #ff7f14">
        <h5 class="c-title">C4 : PRATIQUER UNE CONDUITE AUTONOME, SÛRE ET ECONOMIQUE.</h5>
      </div>
    </div>

    <div *ngIf="competenceId === 1" id="form-c1" style="padding-bottom: 40px">
      <p class="q-title">1. Connaître les principaux organes et commandes du véhicule, effectuer des vérifications intérieures et extérieures</p>
      <div class="form-group">
        <app-question [isStudent]="isStudent" class="c1s1" [qId]="'c1s1q1'" [score]="suiviDriveForm.controls['c1s1q1'] === undefined ? 0 : suiviDriveForm.controls['c1s1q1'].value" [text]="'Connaître les principaux organes du véhicule'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c1s1q2'" [score]="suiviDriveForm.controls['c1s1q2'] === undefined ? 0 : suiviDriveForm.controls['c1s1q2'].value" [text]="'Connaître les principales commandes et savoir les utiliser'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c1s1q3'" [score]="suiviDriveForm.controls['c1s1q3'] === undefined ? 0 : suiviDriveForm.controls['c1s1q3'].value" [text]="'Savoir effectuer les vérifications intérieures et extérieures'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">2. Entrer, s'installer au poste de conduite et en sortir</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c1s2q1'" [score]="suiviDriveForm.controls['c1s2q1'] === undefined ? 0 : suiviDriveForm.controls['c1s2q1'].value" [text]="'Savoir effectuer les contrôles autour du véhicule'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c1s2q2'" [score]="suiviDriveForm.controls['c1s2q2'] === undefined ? 0 : suiviDriveForm.controls['c1s2q2'].value" [text]="'Savoir entrer, s\'installer et sortir du véhicule en sécurité'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c1s2q3'" [score]="suiviDriveForm.controls['c1s2q3'] === undefined ? 0 : suiviDriveForm.controls['c1s2q3'].value" [text]="'Savoir installer les passagers, animaux et bagages'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c1s2q4'" [score]="suiviDriveForm.controls['c1s2q4'] === undefined ? 0 : suiviDriveForm.controls['c1s2q4'].value" [text]="'Connaître l\'importance du rôle de la ceinture'" (scoreChanged)="editForm($event)"></app-question>
      </div>


      <p class="q-title">3. Tenir, tourner le volant et maintenir la trajectoire</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c1s3q1'" [score]="suiviDriveForm.controls['c1s3q1'] === undefined ? 0 : suiviDriveForm.controls['c1s3q1'].value" [text]="'Savoir tenir et tourner le volant en ligne droite, virage'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c1s3q2'" [score]="suiviDriveForm.controls['c1s3q2'] === undefined ? 0 : suiviDriveForm.controls['c1s3q2'].value" [text]="'Savoir regarder pour diriger le véhicules'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">4. Démarrer et s'arrêter</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c1s4q1'" [score]="suiviDriveForm.controls['c1s4q1'] === undefined ? 0 : suiviDriveForm.controls['c1s4q1'].value" [text]="'Savoir faire démarrer le véhicule sur terrain plat, en montée, en descente'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c1s4q2'" [score]="suiviDriveForm.controls['c1s4q2'] === undefined ? 0 : suiviDriveForm.controls['c1s4q2'].value" [text]="'Savoir se déplacer à allure lente'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c1s4q3'" [score]="suiviDriveForm.controls['c1s4q3'] === undefined ? 0 : suiviDriveForm.controls['c1s4q3'].value" [text]="'Savoir s\'arrêter avec précision'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">5. Doser l'accélération et le freinage à diverses allures</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c1s5q1'" [score]="suiviDriveForm.controls['c1s5q1'] === undefined ? 0 : suiviDriveForm.controls['c1s5q1'].value" [text]="'Savoir doser l\'accélération'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c1s5q2'" [score]="suiviDriveForm.controls['c1s5q2'] === undefined ? 0 : suiviDriveForm.controls['c1s5q2'].value" [text]="'Savoir doser le freinage à diverses allures'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">6. Utiliser la boîte de vitesses</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c1s6q1'" [score]="suiviDriveForm.controls['c1s6q1'] === undefined ? 0 : suiviDriveForm.controls['c1s6q1'].value" [text]="'Savoir manipuler le livier sans le regarder'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c1s6q2'" [score]="suiviDriveForm.controls['c1s6q2'] === undefined ? 0 : suiviDriveForm.controls['c1s6q2'].value" [text]="'Savoir monter les vitesses et rétrograder'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c1s6q3'" [score]="suiviDriveForm.controls['c1s6q3'] === undefined ? 0 : suiviDriveForm.controls['c1s6q3'].value" [text]="'Savoir choisir le rapport de vitesse convenable'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">7. Diriger la voiture en avant en ligne droite et en courbe en adaptant allure et trajectoire</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c1s7q1'" [score]="suiviDriveForm.controls['c1s7q1'] === undefined ? 0 : suiviDriveForm.controls['c1s7q1'].value" [text]="'Savoir maintenir une trajectoire rectiligne et en courbe à diverses allures'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">8. Regarder autour de soi et avertir</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c1s8q1'" [score]="suiviDriveForm.controls['c1s8q1'] === undefined ? 0 : suiviDriveForm.controls['c1s8q1'].value" [text]="'Connaître les angles morts'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c1s8q2'" [score]="suiviDriveForm.controls['c1s8q2'] === undefined ? 0 : suiviDriveForm.controls['c1s8q2'].value" [text]="'Savoir regarder dans les rétroviseurs et avertir'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c1s8q3'" [score]="suiviDriveForm.controls['c1s8q3'] === undefined ? 0 : suiviDriveForm.controls['c1s8q3'].value" [text]="'Avoir des notions sur la direction et la mobilité du regard'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">9. Effectuer une marche arrière et un demi-tour en sécurité</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c1s9q1'" [score]="suiviDriveForm.controls['c1s9q1'] === undefined ? 0 : suiviDriveForm.controls['c1s9q1'].value" [text]="'Savoir effectuer une marche arrière en sécurité'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c1s9q2'" [score]="suiviDriveForm.controls['c1s9q2'] === undefined ? 0 : suiviDriveForm.controls['c1s9q2'].value" [text]="'Savoir effectuer un demi-tour en sécurité'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c1s9q3'" [score]="suiviDriveForm.controls['c1s9q3'] === undefined ? 0 : suiviDriveForm.controls['c1s9q3'].value" [text]="'Savoir faire une marche arrière en ligne droite et en courbe'" (scoreChanged)="editForm($event)"></app-question>
      </div>
    </div>

    <div *ngIf="competenceId === 2" id="form-c2" style="padding-bottom: 40px">
      <p class="q-title">1. Rechercher la signalisation, les indices utiles et en tenir compte</p>
      <div class="form-group">
        <app-question [isStudent]="isStudent" [qId]="'c2s1q1'" [score]="suiviDriveForm.controls['c2s1q1'] === undefined ? 0 : suiviDriveForm.controls['c2s1q1'].value" [text]="'Savoir rechercher la signalisation et en tenir compte'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c2s1q2'" [score]="suiviDriveForm.controls['c2s1q2'] === undefined ? 0 : suiviDriveForm.controls['c2s1q2'].value" [text]="'Savoir rechercher les indices utiles et en tenir compte'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">2. Positionner le véhicule sur la chaussée et choisir la voie de circulation</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c2s2q1'" [score]="suiviDriveForm.controls['c2s2q1'] === undefined ? 0 : suiviDriveForm.controls['c2s2q1'].value" [text]="'Savoir positionner le véhicule sur la chaussée'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c2s2q2'" [score]="suiviDriveForm.controls['c2s2q2'] === undefined ? 0 : suiviDriveForm.controls['c2s2q2'].value" [text]="'Savoir choisir la voie de circulation'" (scoreChanged)="editForm($event)"></app-question>
      </div>


      <p class="q-title">3. Adapter l'allure aux situations</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c2s3q1'" [score]="suiviDriveForm.controls['c2s3q1'] === undefined ? 0 : suiviDriveForm.controls['c2s3q1'].value" [text]="'Connaître la réglementation liée à la vitesse'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c2s3q2'" [score]="suiviDriveForm.controls['c2s3q2'] === undefined ? 0 : suiviDriveForm.controls['c2s3q2'].value" [text]="'Savoir adapter l\'allure aux situations'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">4. Tourner à droite et à gauche en aglomération</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c2s4q1'" [score]="suiviDriveForm.controls['c2s4q1'] === undefined ? 0 : suiviDriveForm.controls['c2s4q1'].value" [text]="'Savoir se placer pour tourner à droite ou à gauche'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c2s4q2'" [score]="suiviDriveForm.controls['c2s4q2'] === undefined ? 0 : suiviDriveForm.controls['c2s4q2'].value" [text]="'Connaître et appliquer les règles de priorité avant de tourner'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c2s4q3'" [score]="suiviDriveForm.controls['c2s4q3'] === undefined ? 0 : suiviDriveForm.controls['c2s4q3'].value" [text]="'Savoir tourner en tenant compte de la présence des autres usagers'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">5. Détecter, identifier et franchir les intersections suivant le régime de priorité</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c2s5q1'" [score]="suiviDriveForm.controls['c2s5q1'] === undefined ? 0 : suiviDriveForm.controls['c2s5q1'].value" [text]="'Connaître les différentes règles de priorité des intersections'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c2s5q2'" [score]="suiviDriveForm.controls['c2s5q2'] === undefined ? 0 : suiviDriveForm.controls['c2s5q2'].value" [text]="'Savoir détecter, identifier et franchir les différents types d\'intersection'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">6. Franchir les ronds-points et les carrefours à sens giratoire</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c2s6q1'" [score]="suiviDriveForm.controls['c2s6q1'] === undefined ? 0 : suiviDriveForm.controls['c2s6q1'].value" [text]="'Savoir franchir un rond-point'" (scoreChanged)="editForm($event)"></app-question>
        <app-question  [isStudent]="isStudent" [qId]="'c2s6q2'" [score]="suiviDriveForm.controls['c2s6q2'] === undefined ? 0 : suiviDriveForm.controls['c2s6q2'].value" [text]="'Savoir Savoir franchir un carrefour à sens giratoire'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">7. S'arrêter et stationner en épi, en batailler et en créneau</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c2s7q1'" [score]="suiviDriveForm.controls['c2s7q1'] === undefined ? 0 : suiviDriveForm.controls['c2s7q1'].value" [text]="'Connaître la réglementation de l\'arrêt et du stationnement'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c2s7q2'" [score]="suiviDriveForm.controls['c2s7q2'] === undefined ? 0 : suiviDriveForm.controls['c2s7q2'].value" [text]="'Savoir se ranger en épi, en batailler, dans un créneau'" (scoreChanged)="editForm($event)"></app-question>
      </div>
    </div>

    <div *ngIf="competenceId === 3" id="form-c3" style="padding-bottom: 40px">
      <p class="q-title">1. Evaluer et maintenir les distances de sécurité</p>
      <div class="form-group">
        <app-question [isStudent]="isStudent" [qId]="'c3s1q1'" [score]="suiviDriveForm.controls['c3s1q1'] === undefined ? 0 : suiviDriveForm.controls['c3s1q1'].value" [text]="'Savoir évaluer et respecter les distances de sécurité'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c3s1q2'" [score]="suiviDriveForm.controls['c3s1q2'] === undefined ? 0 : suiviDriveForm.controls['c3s1q2'].value" [text]="'Savoir évaluer les distances latéralement avant de croiser ou de dépasser'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">2. Croiser, dépasser, être dépassé</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c3s2q1'" [score]="suiviDriveForm.controls['c3s2q1'] === undefined ? 0 : suiviDriveForm.controls['c3s2q1'].value" [text]="'Conapitre la réglementation concernant le croisement et le dépassement'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c3s2q2'" [score]="suiviDriveForm.controls['c3s2q2'] === undefined ? 0 : suiviDriveForm.controls['c3s2q2'].value" [text]="'Savoir choisir le moment et l\'endroit pour dépasser et se rabattre'" (scoreChanged)="editForm($event)"></app-question>
      </div>


      <p class="q-title">3. Passer des virages et conduire en déclivité</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c3s3q1'" [score]="suiviDriveForm.controls['c3s3q1'] === undefined ? 0 : suiviDriveForm.controls['c3s3q1'].value" [text]="'Connaître la signalisation des virages'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c3s3q2'" [score]="suiviDriveForm.controls['c3s3q2'] === undefined ? 0 : suiviDriveForm.controls['c3s3q2'].value" [text]="'Connaître les effets de l\'energie cinétique et de la force centrifuge'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c3s3q3'" [score]="suiviDriveForm.controls['c3s3q3'] === undefined ? 0 : suiviDriveForm.controls['c3s3q3'].value" [text]="'Savoir adapter allure et trajectoire avant et pendant le virage'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c3s3q4'" [score]="suiviDriveForm.controls['c3s3q4'] === undefined ? 0 : suiviDriveForm.controls['c3s3q4'].value" [text]="'Savoir conduire en déclivité'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">4. Connaître les caractéristiques des autres usagers et savoir se comporter à leur égard avec respect et courtoisie</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c3s4q1'" [score]="suiviDriveForm.controls['c3s4q1'] === undefined ? 0 : suiviDriveForm.controls['c3s4q1'].value" [text]="'Connaître les particularités des diverses catégories d\'usagers et savoir en tenir compte : piétons, deux-roues, transports en commun, véhicules lourds, véhicules agricoles'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c3s4q2'" [score]="suiviDriveForm.controls['c3s4q2'] === undefined ? 0 : suiviDriveForm.controls['c3s4q2'].value" [text]="'Connaître les moyens de communiquer'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c3s4q3'" [score]="suiviDriveForm.controls['c3s4q3'] === undefined ? 0 : suiviDriveForm.controls['c3s4q3'].value" [text]="'Savoir utiliser les moyens de communiquer'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">5. S'insérer, circuler et sortir d'une voie rapide</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c3s5q1'" [score]="suiviDriveForm.controls['c3s5q1'] === undefined ? 0 : suiviDriveForm.controls['c3s5q1'].value" [text]="'Savoir s\'insérer sur une voie rapide'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c3s5q2'" [score]="suiviDriveForm.controls['c3s5q2'] === undefined ? 0 : suiviDriveForm.controls['c3s5q2'].value" [text]="'Savoir circuler et dépasser sur une voie rapide'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c3s5q3'" [score]="suiviDriveForm.controls['c3s5q3'] === undefined ? 0 : suiviDriveForm.controls['c3s5q3'].value" [text]="'Savoir quitter une voie rapide'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">6. Conduire dans une file de véhicules et dans une circulation dense</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c3s6q1'" [score]="suiviDriveForm.controls['c3s6q1'] === undefined ? 0 : suiviDriveForm.controls['c3s6q1'].value" [text]="'Connaître les risques particuliers de la circulation dans une file'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c3s6q2'" [score]="suiviDriveForm.controls['c3s6q2'] === undefined ? 0 : suiviDriveForm.controls['c3s6q2'].value" [text]="'Connaître les risques particuliers de la circulation dense'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c3s6q3'" [score]="suiviDriveForm.controls['c3s6q3'] === undefined ? 0 : suiviDriveForm.controls['c3s6q3'].value" [text]="'Savoir conduire dans une file de véhicule'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c3s6q4'" [score]="suiviDriveForm.controls['c3s6q4'] === undefined ? 0 : suiviDriveForm.controls['c3s6q4'].value" [text]="'Savoir conduire en circulation dense'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">7. Connaître les régles relatives à la circulation inter-files des motocyclistes. Savoir en tenir compte</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c3s7q1'" [score]="suiviDriveForm.controls['c3s7q1'] === undefined ? 0 : suiviDriveForm.controls['c3s7q1'].value" [text]="'Connaître les règles et les pratiques de la circulation interfile des motocyclistes'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c3s7q2'" [score]="suiviDriveForm.controls['c3s7q2'] === undefined ? 0 : suiviDriveForm.controls['c3s7q2'].value" [text]="'Savoir circuler en tenant compte des motocyclistes'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">8. Connaître les régles relatives à la circulation inter-files des motocyclistes. Savoir en tenir compte</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c3s8q1'" [score]="suiviDriveForm.controls['c3s8q1'] === undefined ? 0 : suiviDriveForm.controls['c3s8q1'].value" [text]="'Connaître les règles et les pratiques de la circulation interfile des motocyclistes'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c3s8q2'" [score]="suiviDriveForm.controls['c3s8q2'] === undefined ? 0 : suiviDriveForm.controls['c3s8q2'].value" [text]="'Savoir circuler en tenant compte des motocyclistes'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">9. Conduire quand l'adhérence et la visibilité sont réduites</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c3s9q1'" [score]="suiviDriveForm.controls['c3s9q1'] === undefined ? 0 : suiviDriveForm.controls['c3s9q1'].value" [text]="'Connaître les risques de l\'adhérence et de la visibilité réduite'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c3s9q2'" [score]="suiviDriveForm.controls['c3s9q2'] === undefined ? 0 : suiviDriveForm.controls['c3s9q2'].value" [text]="'Savoir conduire quand l\'adhérence est réduite'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c3s9q3'" [score]="suiviDriveForm.controls['c3s9q3'] === undefined ? 0 : suiviDriveForm.controls['c3s9q3'].value" [text]="'Savoir conduire lorsque la visibilité est réduite'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c3s9q4'" [score]="suiviDriveForm.controls['c3s9q4'] === undefined ? 0 : suiviDriveForm.controls['c3s9q4'].value" [text]="'Savoir utiliser les équipements du véhicule dans ces situations dégradées'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">10. Conduire à l'abord et dans la traversée d'ouvrages routiers tels que les tunnels, les ponts...</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c3s10q1'" [score]="suiviDriveForm.controls['c3s10q1'] === undefined ? 0 : suiviDriveForm.controls['c3s10q1'].value" [text]="'Connaître les dangers particuliers des zones de chantiers, tunnels, pont'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c3s10q2'" [score]="suiviDriveForm.controls['c3s10q2'] === undefined ? 0 : suiviDriveForm.controls['c3s10q2'].value" [text]="'Savoir adapter sa conduite à l\'approche de ces zones'" (scoreChanged)="editForm($event)"></app-question>
      </div>
    </div>

    <div *ngIf="competenceId === 4" id="form-c4" style="padding-bottom: 40px">
      <p class="q-title">1. Suivre un itinéraire de manière autonome</p>
      <div class="form-group">
        <app-question [isStudent]="isStudent" [qId]="'c4s1q1'" [score]="suiviDriveForm.controls['c4s1q1'] === undefined ? 0 : suiviDriveForm.controls['c4s1q1'].value" [text]="'Connaître et savoir interpréter la signalisation de direction'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c4s1q2'" [score]="suiviDriveForm.controls['c4s1q2'] === undefined ? 0 : suiviDriveForm.controls['c4s1q2'].value" [text]="'Savoir suivre les directions'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c4s1q3'" [score]="suiviDriveForm.controls['c4s1q3'] === undefined ? 0 : suiviDriveForm.controls['c4s1q3'].value" [text]="'Savoir utiliser une carte routière et un GPS'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">2. Préparer et effectuer un voyage longue distance en autonomie</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c4s2q1'" [score]="suiviDriveForm.controls['c4s2q1'] === undefined ? 0 : suiviDriveForm.controls['c4s2q1'].value" [text]="'Savoir préparer le véhicule et son chargement avant le voyage'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c4s2q2'" [score]="suiviDriveForm.controls['c4s2q2'] === undefined ? 0 : suiviDriveForm.controls['c4s2q2'].value" [text]="'Savoir organiser les pauses et les temps de conduite'" (scoreChanged)="editForm($event)"></app-question>
      </div>


      <p class="q-title">3. Connaître les principaux facteurs de risque au volant et les recommandations à appliquer</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c4s3q1'" [score]="suiviDriveForm.controls['c4s3q1'] === undefined ? 0 : suiviDriveForm.controls['c4s3q1'].value" [text]="'Connaître les risques liés aux produits stupéfiants, à l\'alcool, la fatique'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c4s3q2'" [score]="suiviDriveForm.controls['c4s3q2'] === undefined ? 0 : suiviDriveForm.controls['c4s3q2'].value" [text]="'Connaître les risques lié aux distracteurs : téléphone, GPS, passagers...'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">4. Connaître les comportements à adopter en cas d'accident : protéger, alerter, secourir</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c4s4q1'" [score]="suiviDriveForm.controls['c4s4q1'] === undefined ? 0 : suiviDriveForm.controls['c4s4q1'].value" [text]="'Savoir protéger'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c4s4q2'" [score]="suiviDriveForm.controls['c4s4q2'] === undefined ? 0 : suiviDriveForm.controls['c4s4q2'].value" [text]="'Savoir alerter'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c4s4q3'" [score]="suiviDriveForm.controls['c4s4q3'] === undefined ? 0 : suiviDriveForm.controls['c4s4q3'].value" [text]="'Savoir secourir'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">5. Faire l'expérience des aides à la conduite du véhicule (régulateur, limiteur de vitesse, ABS, GPS...)</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c4s5q1'" [score]="suiviDriveForm.controls['c4s5q1'] === undefined ? 0 : suiviDriveForm.controls['c4s5q1'].value" [text]="'Connaître le fonctionnement et savoir utiliser en roulant les différents équipements et aides à la conduite du véhicule'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">6. Avoir des notions sur l'entretien, le dépannage et les situations d'urgence</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c4s6q1'" [score]="suiviDriveForm.controls['c4s6q1'] === undefined ? 0 : suiviDriveForm.controls['c4s6q1'].value" [text]="'Savoir entretenir son véhicule'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c4s6q2'" [score]="suiviDriveForm.controls['c4s6q2'] === undefined ? 0 : suiviDriveForm.controls['c4s6q2'].value" [text]="'Savoir changer une roue'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c4s6q3'" [score]="suiviDriveForm.controls['c4s6q3'] === undefined ? 0 : suiviDriveForm.controls['c4s6q3'].value" [text]="'Savoir utiliser les équipements de sécurité : gilet de haute visibilité et triangle de présignalisation'" (scoreChanged)="editForm($event)"></app-question>
      </div>

      <p class="q-title">7. Pratiquer l'écoconduite</p>
      <div>
        <app-question [isStudent]="isStudent" [qId]="'c4s7q1'" [score]="suiviDriveForm.controls['c4s7q1'] === undefined ? 0 : suiviDriveForm.controls['c4s7q1'].value" [text]="'Connaître les principes de la conduite économe'" (scoreChanged)="editForm($event)"></app-question>
        <app-question [isStudent]="isStudent" [qId]="'c4s7q2'" [score]="suiviDriveForm.controls['c4s7q2'] === undefined ? 0 : suiviDriveForm.controls['c4s7q2'].value" [text]="'Savoir appliquer les principes de la consuite économe'" (scoreChanged)="editForm($event)"></app-question>
      </div>
    </div>
  </form>
</div>
