<div class="modal fade" id="articleModal" tabindex="-1" role="dialog" aria-labelledby="boxTypeLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="boxTypeTitle" name="startAt">
          CREER UNE NOUVELLE BOITE
        </h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div id="newEventError" class="alert alert-danger mt-4 ml-4 mr-4" role="alert" *ngIf="popupError">
        {{popupError}}
      </div>

      <form [formGroup]="articleForm" #f="ngForm" name="newEventForm" (ngSubmit)="f.form.valid && onSaveArticle()" novalidate>
        <div>
          <div class="form-row ml-4 p-1 mt-4">
            <div class="input-group raw mb-3">
              <div class="col-6">
                <label for="name" class="col-form-label">NOM DE L'ARTICLE</label>
                <input id="name" class="form-control form-control-lg form-control-borderless"
                       name="name" formControlName="name" required aria-describedby="basic-addon2">
                <p class="alert alert-danger p-0" role="alert" *ngIf="submited && f.form.controls.name.status == 'INVALID'">
                  Merci de définir le nom de l'article !
                </p>
              </div>
              <div class="col-4">
                <label for="boxType" class="col-form-label">SELECTIONNER LA BOITE</label>
                <select id="boxType" name="hourAt" formControlName="boxType"
                        class="form-control form-control-lg form-control-borderless overflow-auto update-modal">
                  <option value="MANUELLE">MANUELLE</option>
                  <option value="AUTOMATIQUE">AUTOMATIQUE</option>
                </select>
                <p class="alert alert-danger p-0" role="alert" *ngIf="submited && f.form.controls.boxType.status == 'INVALID'">
                  Merci de définir la boîte
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row ml-4 p-1 mt-1">
          <div class="input-group raw mb-3">
            <div class="col-4">
              <label for="nbHours" class="col-form-label">NOMBRE D'HEURES</label>
              <input id="nbHours" class="form-control form-control-lg form-control-borderless"
                     name="nbHours" formControlName="nbHours" required aria-describedby="basic-addon2">
              <p class="alert alert-danger p-0" role="alert" *ngIf="submited && f.form.controls.nbHours.status == 'INVALID'">
                Merci de définir le nombre d'heure de conduite !
              </p>
            </div>
            <div class="col-4">
              <label for="price" class="col-form-label">PRIX</label>
              <input id="price" type="number" class="form-control form-control-lg form-control-borderless"
                     name="price" formControlName="price" required aria-describedby="basic-addon2" min="1">
              <p class="alert alert-danger p-0" role="alert" *ngIf="submited && f.form.controls.price.status == 'INVALID'">
                Merci de définir un prix !
              </p>
            </div>
          </div>
          <div class="input-group raw mb-3">
            <div class="col-6">
              <label for="article-description-c" class="col-form-label">DESCRIPTION</label>
              <textarea class="form-control" id="article-description-c" name="description" formControlName="description"  rows="3"></textarea>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">ANNULER</button>
          <button type="button" class="btn btn-primary" type="submit" (click)="isSubmited(f.form)">VALIDER</button>
        </div>
      </form>
    </div>
  </div>
</div>
