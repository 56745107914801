'use strict';

export const COLOR_MANUAL_AVAILABLE = "#77B5FE";

export const COLOR_MANUAL_RESERVED = "#5075AE";

export const COLOR_AUTOMATIC_AVAILABLE = "#F9DB1A";

export const COLOR_AUTOMATIC_RESERVED = "#F9731A";

export const COLOR_PERMIS = "red";
