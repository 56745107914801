<div *ngIf="iShowPayments">
  <h4>LISTE DES PAIEMENTS <span *ngIf="iPagePayment">({{iPagePayment.totalElements}})</span>
    <span *ngFor="let details of iTotalPaymentDetails">
      [{{details.paymentMethod}} : {{details.total}}€]
    </span>
  </h4>
  <table class="table">
    <thead>
    <tr class="row">
      <th scope="col" class="col-auto">#</th>
      <th scope="col" class="col">DATE</th>
      <th scope="col" class="col">MONTANT</th>
      <th scope="col" class="col" *ngIf="!iNavMobile">MODE</th>
      <th scope="col" class="col" *ngIf="iShowStudent">ELEVE</th>
      <th scope="col" class="col" *ngIf="!iNavMobile && !iIsStudent">DESCRIPTION</th>
      <th scope="col" class="col" *ngIf="!iNavMobile && iShowUpdateBtn && !iIsStudent">MODIFIER</th>
      <th scope="col" class="col" *ngIf="!iNavMobile && isSuperAdmin()">SUPPRIMER</th>
      <th scope="col" class="col" *ngIf="!iNavMobile && iShowUpdateBtn && !iIsStudent">FACTURE</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let p of iPayments" class="row">
      <th class="col-auto">{{p.paymentId}}</th>
      <td class="col">{{p.date | date:'dd/MM/YYYY'}}</td>
      <td class="col">{{p.amount}} €</td>
      <td class="col" *ngIf="!iNavMobile">{{p.paymentMethod}}</td>
      <td class="col" *ngIf="iShowStudent">{{p.studentName}}</td>
      <td class="col" *ngIf="!iNavMobile && !iIsStudent">{{p.description}}</td>
      <td class="col" *ngIf="!iNavMobile && iShowUpdateBtn && !iIsStudent" (click)="displayUpdatePaymentPopup(p)">
        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
      </td>
      <td class="col" *ngIf="!iNavMobile && isSuperAdmin()" (click)="displayDisabledPayment(p.paymentId)">
        <i class="fa fa-trash-o" style="font-size:130%;color:red"></i>
      </td>
      <td class="col" *ngIf="!iNavMobile && iShowUpdateBtn && !iIsStudent" (click)="uploadInvoice(p.paymentId)">
        <i class="fa fa-download" aria-hidden="true"></i>
      </td>
    </tr>
    </tbody>
    <tfoot>
    <app-pagination *ngIf="iPagePayment" [iEmpty]="iPagePayment.empty" [iFirst]="iPagePayment.first"
                    [iLast]="iPagePayment.last" [iNumber]="iPagePayment.number" [iTotalPages]="iPagePayment.totalPages"
                    (currentPageChanged)="onChangePaymentPage($event)" >
    </app-pagination>
    </tfoot>
  </table>
</div>
