import {Component, OnInit} from '@angular/core';
import {EventService} from '../../../_services/event.service';
import {StudentService} from '../../../_services/student.service';
import {Router} from '@angular/router';
//  import {BigInteger} from '@angular/compiler/esm2022/src/i18n/b';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {TokenStorageService} from '../../../_services/token-storage.service';
import * as moment from 'moment';
import {ProfileService} from '../../../_services/profile.service';

declare let $: any;

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit {

  commentForm = this.initForm();
  drivingComments: any;
  // @ts-ignore
  userId: bigint = 1;
  student: any;
  studentId: string = this.router.url.split("/")[2];
  messageError: string = "";
  isPhone: boolean = false;
  isMonitor: boolean = false;
  isStudent: boolean = false;

  constructor(private fb:UntypedFormBuilder, private eventService: EventService, private tokenStorageService: TokenStorageService,
              private studentService: StudentService, private profileService: ProfileService, private router: Router) {
    if(screen.width < 450) {
      this.isPhone = true;
    }

    if (this.router.url.match("/my-book-comments")) {
      this.isStudent = true;
    } else {
      this.studentId = this.router.url.split("/")[2];
    }

    let user = this.tokenStorageService.getUser();
    this.userId =  user.id;
    // @ts-ignore
    this.isMonitor = user.roles.includes("ROLE_SUPER_ADMIN") || user.roles.includes("ROLE_MONITOR");

    this.commentForm = this.initForm();
    if (!this.isStudent) {
      studentService.getStudentsById(this.studentId).subscribe(
        data => {
          this.student = data;
          this.getStudentDrivingComments(this.studentId);
        }, error => {
          console.log(error)
        })
    } else {
      profileService.getMyProfile().subscribe(
        data => {
          this.student = data;
          this.getMyDrivingComments();
        }, error => {
          console.log(error)
        })
    }

  }

  ngOnInit(): void {
  }

  validComment() {
    this.studentService.saveDrivingComment(this.commentForm?.value).subscribe(
      data => {
        $("#drivingCommentModal").modal("hide");
        this.getStudentDrivingComments(this.studentId);
      }, error => {
        console.log(error);
      }
    )
  }

  editComment(id: string, date: Date, comment: string) {
    this.commentForm = this.fb.group({
      id: [id, Validators.required],
      date: [moment.utc(date ? date : new Date()).format('YYYY-MM-DD'), Validators.required],
      userId: [this.userId, Validators.required],
      studentId: [this.studentId, Validators.required],
      comment: [comment, Validators.required]
    });
    $("#drivingCommentModal").modal("show");
  }


  getStudentDrivingComments(studentId: string) {
    this.studentService.getStudentDrivingComments(studentId).subscribe(
        (data: any) => {
        this.drivingComments = data;
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  getMyDrivingComments() {
    this.profileService.getMyDrivingComments().subscribe(
      (data: any) => {
        this.drivingComments = data;
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  private initForm() {
    return this.fb.group({
      id: null,
      date: [moment.utc(new Date()).format('YYYY-MM-DD'), Validators.required],
      userId: [this.userId, Validators.required],
      studentId: [this.studentId, Validators.required],
      comment: [null, Validators.required]
    });
  }

  addComment() {
    $("#drivingCommentModal").modal("show");
    this.commentForm = this.initForm();
  }
}
