import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BetweenDates, Payment, PaymentUpdate} from '../models/eventRequest';
import {environment} from './../../environments/environment';
import {DateService} from './date.service';

const PAYMENT_API = environment.APIEndpoint + '/api/payments';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(private dateService: DateService, private http: HttpClient) { }

  createPayment(paymentRequest: Payment): Observable<any> {
    return this.http.post(PAYMENT_API, paymentRequest, httpOptions);
  }

  updatePayment(paymentRequest: PaymentUpdate): Observable<any> {
    return this.http.put(PAYMENT_API, paymentRequest, httpOptions);
  }

  disabledPayment(paymentId: number): Observable<any> {
    return this.http.delete(PAYMENT_API + '/' + paymentId, httpOptions);
  }

  getPaymentsBetweenDates(dates: BetweenDates, page: number, size: number): Observable<any> {
    const datesString = this.dateService.getBetweenDatesString(dates);
    return this.http.post(PAYMENT_API + '/between-dates?page=' + page + '&size=' + size + '&sort=date,DESC' , datesString, httpOptions);
  }

  getTotalPaymentsBetweenDates(dates: BetweenDates): Observable<any> {
    const datesString = this.dateService.getBetweenDatesString(dates);
    return this.http.post(PAYMENT_API + '/total' , datesString, httpOptions);
  }

  getTotalGroupByPaymentsBetweenDates(dates: BetweenDates): Observable<any> {
    const datesString = this.dateService.getBetweenDatesString(dates);
    return this.http.post(PAYMENT_API + '/total-details' , datesString, httpOptions);
  }

  uploadInvoice(paymentId: bigint): Observable<any> {
    return this.http.get(PAYMENT_API + '/' + paymentId + '/invoice', { responseType: 'arraybuffer' });
  }
}
