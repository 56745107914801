import {Component, Input, OnInit, Output} from '@angular/core';
import {ToolsService} from '../../../_services/tools.service';

declare let $ :any;

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.css']
})
export class ArticleListComponent implements OnInit {

  @Input() iArticles: any;
  @Input() iShowArticle: boolean = false;
  showAllArticles: boolean = false;

  constructor(private toolsService: ToolsService) {
    toolsService.getAllArticles().subscribe(data => {
      this.iArticles = data;
    })
  }

  ngOnInit(): void {
  }

  onArticleSaved($event: any) {
    this.iArticles = [...this.iArticles, $event]
    $('#articleModal').modal("hide");
  }

  onShowArticle() {
    $('#articleModal').modal("show");
  }

  activeArticle(id: any, active: boolean) {
    this.toolsService.activeArticle(id, active).subscribe(data => {
      let index = this.iArticles.findIndex(((a: { id: number; }) => a.id == id));
      this.iArticles[index] = data;
    })
  }

  checkValue(isChecked: boolean) {
  }
}
