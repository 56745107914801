import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {HomeComponent} from './home/home.component';
import {ProfileComponent} from './profile/profile.component';
import {BoardAdminComponent} from './board-admin/board-admin.component';
import {BoardMonitorComponent} from './board-monitor/board-monitor.component';
import {BoardStudentComponent} from './board-student/board-student.component';
import {FullCalendarModule} from '@fullcalendar/angular'; // the main connector. must go first

import {authInterceptorProviders} from './_helpers/auth.interceptor';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {EventNewComponent} from './calendar/modals/event-new/event-new.component';
import {EventEditComponent} from './calendar/modals/event-edit/event-edit.component';
import {CalendarComponent} from './calendar/calendar.component';
import {EventsMonitorComponent} from './calendar/modals/events-monitor/events-monitor.component';
import {CreateStudentComponent} from './student/create-student/create-student.component';
import {MonitorCreateComponent} from './monitor/monitor-create/monitor-create.component';
import {MonitorSaveComponent} from './monitor/monitor-save/monitor-save.component';
import {MonitorListComponent} from './monitor/monitor-list/monitor-list.component';
import {StudentListComponent} from './student/student-list/student-list.component';
import {StudentSaveComponent} from './student/student-save/student-save.component';
import {StudentDetailsComponent} from './student/student-details/student-details.component';
import {PaginationComponent} from './pagination/pagination.component';
import {PaymentFormComponent} from './payment/payment-form/payment-form.component';
import {PurchaseListComponent} from './purchase/purchase-list/purchase-list.component';
import {ConsumptionFormComponent} from './consumption/consumption-form/consumption-form.component';
import {PaymentListComponent} from './payment/payment-list/payment-list.component';
import {ConsumptionListComponent} from './consumption/consumption-list/consumption-list.component';
import {DrivingEventListComponent} from './driving-event/driving-event-list/driving-event-list.component';
import {NoteComponent} from './note/note.component';
import {ConfigurationComponent} from './configuration/configuration.component';
import {ArticleFormComponent} from './forms/article/article-form/article-form.component';
import {ArticleListComponent} from './forms/article/article-list/article-list.component';
import {FormulaFormComponent} from './forms/formulas/formula-form/formula-form.component';
import {FormulaListComponent} from './forms/formulas/formula-list/formula-list.component';
import {StudentCalendarComponent} from './student/student-calendar/student-calendar.component';
import {RemoveWhiteSpace} from './pipes/RemoveWhiteSpace';
import {PaymentUpdateFormComponent} from './payment/payment-update-form/payment-update-form.component';
import {UserUpdatePwdComponent} from './login/user-update-pwd/user-update-pwd.component';
import {ConsumptionUpdateFormComponent} from './consumption/consumption-update-form/consumption-update-form.component';
import {HoursComponent} from './board-student/hours/hours.component';
import {StudentsArchivedComponent} from './student/students-archived/students-archived.component';
import {CirclePercentageComponent} from './percentage/circle/circle-percentage.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {DrivingBookComponent} from './student/driving-book/driving-book.component';
import {QuestionComponent} from './student/driving-book/question/question.component';
import {CommentsComponent} from './student/driving-book/comments/comments.component';

// FullCalendarModule.registerPlugins([ // register FullCalendar plugins
//   dayGridPlugin,
//   resourceTimelinePlugin,
//   resourceTimeGridPlugin,
//   interactionPlugin
// ]);




@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RegisterComponent,
        HomeComponent,
        ProfileComponent,
        BoardAdminComponent,
        BoardMonitorComponent,
        BoardStudentComponent,
        EventNewComponent,
        EventEditComponent,
        CalendarComponent,
        EventsMonitorComponent,
        CreateStudentComponent,
        MonitorCreateComponent,
        MonitorSaveComponent,
        MonitorListComponent,
        StudentListComponent,
        StudentSaveComponent,
        StudentDetailsComponent,
        PaginationComponent,
        PaymentFormComponent,
        PurchaseListComponent,
        ConsumptionFormComponent,
        PaymentListComponent,
        ConsumptionListComponent,
        DrivingEventListComponent,
        NoteComponent,
        ConfigurationComponent,
        ArticleFormComponent,
        ArticleListComponent,
        FormulaFormComponent,
        FormulaListComponent,
        StudentCalendarComponent,
        RemoveWhiteSpace,
        PaymentUpdateFormComponent,
        UserUpdatePwdComponent,
        ConsumptionUpdateFormComponent,
        HoursComponent,
        StudentsArchivedComponent,
        CirclePercentageComponent,
        DrivingBookComponent,
        QuestionComponent,
        CommentsComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    FullCalendarModule,
    NgbModule,
    ReactiveFormsModule,
    NgCircleProgressModule.forRoot({})
    // register FullCalendar with you app
  ],
  providers: [
    authInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
