
<div style="border: solid" *ngIf="iShowFormulas">
  <div class="bg-light p-2 pl-5 mb-4 rounded" >
    <h5>LISTE DES FORMULES
      <button type="button" class="ml-5 btn btn-success"
              (click)="onShowFormula()">
        AJOUTER
      </button>
      <div class="form-check form-check-inline" style="margin-left: 20%">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" checked [(ngModel)]="showAllArticles"
               (change)="checkValue(showAllArticles)">
        <label class="form-check-label" for="inlineCheckbox1">Afficher tous les articles</label>
      </div>
    </h5>
  </div>
  <div class="container">
    <table class="table">
      <thead>
      <tr class="row">
        <th scope="col" class="col-auto">#</th>
        <th scope="col" class="col">ARTICLE</th>
        <th scope="col" class="col">PRIX</th>
        <th scope="col" class="col">NB HEURES</th>
        <th scope="col" class="col">BOITE</th>
        <th scope="col" class="col">DESCRIPTION</th>
        <th scope="col">STATUT</th>
      </tr>
      </thead>
      <tbody>
      <tr class="row" *ngFor="let f of iFormulas">
        <th *ngIf="f.active || showAllArticles" class="col-auto" scope="row">{{f.id}}</th>
        <td *ngIf="f.active || showAllArticles" class="col">{{f.name}}</td>
        <td *ngIf="f.active || showAllArticles" class="col">{{f.price}} €</td>
        <td *ngIf="f.active || showAllArticles" class="col">{{f.nbHours}}</td>
        <td *ngIf="f.active || showAllArticles" class="col">{{f.boxType}}</td>
        <td *ngIf="f.active || showAllArticles" class="col">{{f.description}}</td>
        <td *ngIf="f.active || showAllArticles">
          <button type="button" class="btn btn-primary" *ngIf="f.active" (click)="activeArticle(f.id, true)">Activé</button>
          <button type="button" class="btn btn-danger" *ngIf="!f.active" (click)="activeArticle(f.id, false)">Désactivé</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</div>

<app-formula-form (oFormulaSaved)="onFormulaSaved($event)"></app-formula-form>
