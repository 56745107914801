import {Component, OnInit} from '@angular/core';
import {Article, Consumption, Event, EventTO, ExtendedProps, Payment, PaymentMethod, Student} from '../../models/eventRequest';
import {UntypedFormBuilder} from '@angular/forms';
import {StudentService} from '../../_services/student.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {PaymentService} from '../../_services/payment.service';
import {ToolsService} from '../../_services/tools.service';
import {ConsumptionService} from '../../_services/consumption.service';
import {TokenStorageService} from '../../_services/token-storage.service';

declare let $: any;

@Component({
  selector: 'app-student-details',
  templateUrl: './student-details.component.html',
  styleUrls: ['./student-details.component.css']
})
export class StudentDetailsComponent implements OnInit {

  studentId = this.getStudentId();
  student?: Student;
  paymentToUpdate?: Payment;
  payments: Payment[] = [];
  pagePayment: any;
  totalPayments: number = 0;
  consumptionToUpdate?: Consumption;
  consumptions: Consumption[] = [];
  pageConsumption: any;
  totalConsumptions: number = 0;
  toDay : Date = new Date();
  drivingEvents: Event[] = [];
  totalDrivingEventsOfStudent: number = 0;
  numberHoursPurchased: any = 0;
  numberHoursConsumed: number = 0;
  navMobile: boolean = screen.width < 577;
  articles: Article[] = [];
  paymentMethods: PaymentMethod[] = [];
  messageError: string = "";
  popupError: string = "";
  showPayments: boolean = false;
  showConsumptions: boolean = false;
  toPay: any;
  showDrivingEvents: boolean = false;
  pageDrivingEvent: any;
  showDrivingEventsReserved: boolean = false;
  studentStatusValue: string = "NON DEFINI";
  btnValideStatusActive: boolean = false;
  isArchived: boolean = false;
  userRoles = [];


  constructor(private fb:UntypedFormBuilder, private studentService: StudentService,
              private paymentService: PaymentService, private toolsService: ToolsService,
              private consumptionService: ConsumptionService, private token: TokenStorageService,
              private router: Router) {

    this.getStudentById(this.studentId);
    toolsService.getUserRoles().subscribe(
      (data: any) => {
        // @ts-ignore
        this.userRoles = data;
      },
      err => {
        this.messageError = err.error.message;
      });

  }

  ngOnChanges() {
  }


  ngOnInit(): void {
  }

  onOpenUserForm() {
    $("#updateUserForm").modal("show");
  }

  onOpenStatusForm() {
    $("#updateStudentStatus").modal("show");
  }

  onOpenPaymentForm() {
    $("#newPaymentModal").modal("show");
  }

  onOpenConsumptionForm() {
    $("#newConsumptionModal").modal("show");
  }

  onChangePaymentPage($event: number) {
    this.getStudentPayments(this.studentId, $event, 5);
  }

  onRefreshPayment($event: Payment) {
    let totalPages = this.pagePayment.totalPages;
    let lastPage = totalPages > 0 ? totalPages-1 : totalPages;
    this.getStudentPayments(this.studentId, lastPage, 5);
    this.getStudentTotalPayments(this.studentId);
  }

  onRefreshConsumptions($event: Consumption) {
    let totalPages = this.pageConsumption.totalPages;
    let lastPage = totalPages > 0 ? totalPages-1 : totalPages;
    this.getStudentConsumptions(this.studentId, lastPage, 5);
    this.getStudentTotalConsumptions(this.studentId);
    this.getStudentNumberHoursPurchased(this.studentId);
  }

  onAddConsumption($event: Consumption) {
    let totalPages = this.pageConsumption.totalPages;
    let lastPage = totalPages > 0 ? totalPages-1 : totalPages;
    this.getStudentConsumptions(this.studentId, lastPage, 5);
    this.getStudentTotalConsumptions(this.studentId);
    this.getStudentNumberHoursPurchased(this.studentId);
  }

  onChangeConsumptionPage($event: number) {
    this.getStudentConsumptions(this.studentId, $event, 5);
  }

  onChangeDrivingEventPage($event: number) {
    this.getStudentDrivingEvents(this.studentId, $event, 5);
  }

  getStudentPayments(studentId: string, page: number, size: number) {
     this.studentService.getStudentPayments(studentId, page, size).subscribe(
      paymentPages => {
        this.payments = paymentPages.content;
        this.pagePayment = paymentPages;
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  getStudentTotalPayments(studentId: string) {
    this.studentService.getStudentTotalPayments(this.studentId).subscribe(
      total => {
        this.totalPayments = total;
        this.toPay = this.totalConsumptions - this.totalPayments;
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  getStudentConsumptions(studentId: string, page: number, size: number) {
    this.studentService.getStudentConsumptions(this.studentId, page, size).subscribe(
      consumptionPage => {
        this.consumptions = consumptionPage.content;
        this.pageConsumption = consumptionPage;
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  getStudentTotalConsumptions(studentId: string) {
    this.studentService.getStudentTotalConsumptions(this.studentId).subscribe(
      total => {
        this.totalConsumptions = total;
        this.toPay = this.totalConsumptions - this.totalPayments;
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  getStudentNumberHoursPurchased(studentId: string) {
    this.studentService.getNumberHoursPurchasedByStudentId(this.studentId).subscribe(
      number => {
        this.numberHoursPurchased = number;
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  getStudentNumberHoursConsumed(studentId: string) {
    this.studentService.getNumberHoursConsumedByStudentId(this.studentId).subscribe(
      number => {
        this.numberHoursConsumed = number;
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  getStudentDrivingEvents(studentId: string, page: number, size: number) {
    this.studentService.getStudentDrivingEvents(studentId, page, size).subscribe(
      (data: any) => {
          this.pageDrivingEvent = data;
          this.totalDrivingEventsOfStudent = data.totalElements;
          let drivingEvents = data.content.map((item:EventTO) => {
              let props = new ExtendedProps(item.boxType, item.monitor, item.student, item.active, item.description);
              let student = item.student ? item.student.firstname + " " + item.student.lastname : "";
              let monitorName = item.monitor.firstname;
              let eventTitle = student != "" ? student + " | " + monitorName : monitorName;
              let color = student == "" ? "green" : item.monitor.color;
              return new Event(
                item.id,
                eventTitle,
                moment.utc(item.startAt).format('YYYY-MM-DD HH:mm'),
                moment.utc(item.startAt).add(1, "hours").format('YYYY-MM-DD HH:mm'),
                props, color
              );
          });
        this.drivingEvents = drivingEvents;
      },
      err => {
        console.log(err);
      }
    )
  }

  checkWindowSizeChange() {
    if(screen.width < 577) {
      this.navMobile = true;
    } else {
      this.navMobile = false;
    }
  }

  private getStudentById(studentId: string) {
    this.studentService.getStudentsById(studentId).subscribe(
      student => {
        this.student = student;
        this.studentStatusValue = this.getStatusValue(student.status);
        this.getArticles();
        this.getStudentPayments(this.studentId, 0, 5);
        this.getStudentConsumptions(this.studentId, 0, 5);
        this.getStudentTotalPayments(this.studentId);
        this.getStudentTotalConsumptions(this.studentId);
        this.getStudentDrivingEvents(this.studentId, 0, 5);
        this.getStudentNumberHoursPurchased(this.studentId);
        this.getStudentNumberHoursConsumed(this.studentId);
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  /**
   * Recupére la liste des articles existant
   * @private
   */
  private getArticles() {
    this.toolsService.getActiveArticles().subscribe(
      articles => {
        this.articles = articles;
      },
      err => {
        this.messageError = err.error.message;
      });
  }

  onShowConsumptions() {
    this.showConsumptions = !this.showConsumptions;
  }

  onShowPayments() {
    this.showPayments = !this.showPayments;
  }

  onShowDrivingEvent() {
    this.showDrivingEvents = !this.showDrivingEvents;
  }

  private getStudentId() : string {
    if (this.router.url.match("/my-profile")) {
      return this.token.getUser()?.id;
    }
    return this.router.url.split("/")[2];
  }

  initPaymenToUpdate($event: Payment) {
    this.paymentToUpdate = $event;
    $("#updatePaymentModal").modal("show");
  }

  onShowDetails() {
    this.router.navigate(['students/' + this.studentId + "/details"]);
  }

  initConsumptionToUpdate($event: Consumption) {
    this.consumptionToUpdate = $event;
    $("#updateConsumptionModal").modal("show");
  }

  getStatusValue(status: string) : string{
    var statusValue = "NON DEFINI";
    switch (status) {
      case "TO_CREATE" : statusValue = "A CREER"; this.isArchived=false; break;
      case "IN_PROGRESS" : statusValue = "EN COURS"; this.isArchived=false; break;
      case "PERMIS_VALIDATED" : statusValue = "A EU LE PERMIS"; this.isArchived=true; break;
      case "FINISH" : statusValue = "A QUITTER"; this.isArchived=true; break;
      case "CANCEL" : statusValue = "ANNULE"; this.isArchived=true; break;
      case "REMOVE" : statusValue = "SUPPRIMER"; this.isArchived= true; break;
    }
    return statusValue;
  }

  getStatusKey(status: string) : string{
    var statusKey = "NON DEFINI";
    switch (status) {
      case "A CREER" : statusKey = "TO_CREATE"; break;
      case "EN COURS" : statusKey = "IN_PROGRESS"; break;
      case "A EU LE PERMIS" : statusKey = "PERMIS_VALIDATED"; break;
      case "A QUITTER" : statusKey = "FINISH"; break;
      case "ANNULE" : statusKey = "CANCEL"; break;
    }
    return statusKey;
  }

  initNewStudentStatus(newStatus: string) {
    if (newStatus == 'CANCEL_UPDATE') {
      this.btnValideStatusActive = false;
    } else {
      this.btnValideStatusActive = true;
      this.studentStatusValue = this.getStatusValue(newStatus);
    }
  }

  valideNewStudentStatus() {
    if (this.student == null) {
      return;
    }
    var statusKey = this.getStatusKey(this.studentStatusValue);
    if (statusKey == "") {
      this.studentStatusValue = this.getStatusValue(this.student.status);
      this.btnValideStatusActive = false;
      return;
    }
    this.studentService.updateStudentStatus(statusKey, this.studentId).subscribe(
      statusKey => {
        if (this.student) this.student.status = statusKey;
        this.studentStatusValue = this.getStatusValue(statusKey)
        this.btnValideStatusActive = false;
      },
      err => {
        this.messageError = err.message;
        var oldStatusKey = this.student? this.student.status : "NON DEFINI";
        this.studentStatusValue = this.getStatusValue(oldStatusKey);
        this.btnValideStatusActive = false;
      });
  }

  deleteMessageError() {
    this.messageError = "";
  }

  onShowDrivingBook() {
    this.router.navigate(['students/' + this.studentId + "/drive-book"]);
  }

  removeStudent() {
    this.studentService.deleteStudentById(this.studentId).subscribe(
      res => {
        this.router.navigate(['students/']);
      },
      err => {
        this.messageError = err.message;
      });
  }

  isSuperAdmin() {
    // @ts-ignore
    return this.userRoles.indexOf('ROLE_SUPER_ADMIN') > -1;
  }

  disabledPayment(paymentId: any) {
    this.paymentService.disabledPayment(paymentId).subscribe(
      data => {
        this.getStudentById(this.studentId);
      }, err => {

      }
    )
  }

  isMobilePhone() : boolean {
    return window.screen.width < 600;
  }

  updateFolderStatus(toRemove: any) {
    this.studentService.updateFolderStatus(this.studentId, toRemove).subscribe(
      data => {
      }, err => {
      }
    );
  }

  getFolderStatus(status: string): number {
    switch (status) {
      case 'TO_COMPLETE':
        return 0;
      case 'IN_PROGRESS':
        return 1;
      case 'VALIDATED':
        return 2;
      case 'TO_REMOVE':
        return 3;
      default:
        return 0;
    }
  }
}
