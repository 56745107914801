<div>
  <circle-progress
    [percent]="progress"
    [titleColor]="color"
    [subtitle]="title"
    [subtitleFontSize]="'80'"
    [subtitleColor]="color"
    [showSubtitle] = "true"
    [radius]="100"
    [outerStrokeWidth]="25"
    [innerStrokeWidth]="25"
    [space] = "-25"
    [outerStrokeColor]="color"
    [innerStrokeColor]="'#e7e8ea'"
    [titleFontSize]= "'45'"
    [titleFontWeight]="'900'"
    [unitsFontSize]= "'35'"
    [unitsColor]="color"
    [animation]="true"
    [animationDuration]="500"
    [startFromZero]="false"
    [responsive]="true"
  ></circle-progress>
  <p class="text-center" style="font-size: xx-small"><b>{{text}}</b></p>
</div>

