<div class="modal fade" id="newEventModal" tabindex="-1" role="dialog" aria-labelledby="newEventLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="newEventTitle" name="startAt">
          CREER 1H DE CONDUITE !
        </h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div id="newEventError" class="alert alert-danger mt-4 ml-4 mr-4" role="alert" *ngIf="popupError">
        {{popupError}}
      </div>

      <form [formGroup]="newEventForm" #f="ngForm" name="newEventForm" (ngSubmit)="f.form.valid && onCreateNewEvent()" novalidate>
        <div>
          <div class="form-row ml-4 p-1 mt-4">
            <div class="input-group raw mb-3">
              <div class="col-md-4">
                <label for="event-date" class="col-form-label">CHOISIR LA DATE</label>
                <input id="event-date" type="date" class="form-control form-control-lg form-control-borderless"
                       name="dateAt" formControlName="dateAt" required aria-describedby="basic-addon2">
              </div>
              <div class="col-md-4">
                <label for="hour" class="col-form-label">SELECTIONNER UNE HEURE</label>
                <select id="hour" name="hourAt" formControlName="hourAt"
                        class="form-control form-control-lg form-control-borderless overflow-auto" >
                  <option *ngFor="let h of hours" value="{{h}}">{{h}}</option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="boxType" class="col-form-label">SELECTIONNER LA BOITE</label>
                <select id="boxType" name="hourAt" formControlName="boxType"
                        class="form-control form-control-lg form-control-borderless overflow-auto" >
                  <option value="MANUELLE">MANUELLE</option>
                  <option value="AUTOMATIQUE">AUTOMATIQUE</option>
                  <option value="PERMIS">PERMIS</option>
                </select>
              </div>
            </div>

          </div>
          <p class="alert alert-danger ml-4 mr-4 p-0" role="alert" *ngIf="submited && f.form.value.dateAt == null">
            Merci de définir la date !
          </p>
          <p class="alert alert-danger ml-4 mr-4 p-0" role="alert" *ngIf="submited && (f.form.value.hourAt == null || f.form.value.hourAt.length < 4)">
            Merci de définir l'heure !
          </p>
        </div>

        <div class="form-row ml-4 p-1" *ngIf="monitors">
          <label for="monitorSearch" class="col-form-label">LE MONITEUR</label>
          <div id="monitorSearch" class="input-group col-md-4">
            <select id="selectMonitor" class="input-group form-control form-control-lg form-control-borderless"
                    formControlName="monitorId">
              <option [ngValue]="null" disabled>Choisir un moniteur</option>
              <option *ngFor="let m of monitors" [ngValue]="m.id">{{m.lastname}} {{m.firstname}} - {{m.phoneNumber}}</option>
            </select>
          </div>
        </div>

        <div *ngIf="f.form.value.boxType != 'PERMIS'">
          <div class="form-row ml-4 p-1">
            <label for="studentSearch" class="col-form-label">RECHERCHER UN ELEVE</label>
            <div id="studentSearch" class="input-group col-md-12">
              <input type="text" id="studentInput" #studentInput type="search" class="form-control form-control-lg
            form-control-borderless col-md-8" placeholder="Rechercher un éléve" aria-label="Rechercher un éléve" aria-describedby="basic-addon2">
              <div class="input-group-append">
                <button class="btn btn-outline-success" type="button" (click)="searchStudent(studentInput.value)">Rechercher</button>
              </div>
            </div>
          </div>
          <div class="form-row ml-4 p-1" *ngIf="newEventStudents">
            <select class="form-control form-control-lg form-control-borderless col-md-12" formControlName="studentId"> <!--(change)="selectMonitor($event)" -->
              <option [ngValue]="null" disabled>Choisir un éléve</option>
              <option *ngFor="let s of newEventStudents" [ngValue]="s.id" >{{s.lastname}} {{s.firstname}} - {{s.phoneNumber}}</option>
            </select>
          </div>
        </div>
        <div class="form-row ml-4 p-1 col-10">
          <label for="newEventDescription" class="col-form-label">NOTES</label>
          <textarea class="form-control" id="newEventDescription" name="description" formControlName="description"  rows="3"></textarea>
        </div>


        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">ANNULER</button>
          <button type="button" class="btn btn-primary" type="submit" (click)="isSubmited(f.form)">VALIDER</button>
        </div>
      </form>
    </div>
  </div>
</div>
