<div class="modal fade" id="calendarMonitorModal" tabindex="-1" role="dialog" aria-labelledby="calendarMonitorLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="calendarMonitorTitle" name="startAt">
          CREER LE CALENDRIER D'UN MONITEUR
        </h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div id="calendarMonitorError" class="alert alert-danger mt-4 ml-4 mr-4" role="alert" *ngIf="popupError">
        {{popupError}}
      </div>

      <form [formGroup]="calendarForm" #f="ngForm" name="editEventForm" (ngSubmit)="f.form.valid && onCreateMonitorCalendar()" novalidate>

        <div>
          <div class="form-row ml-5 p-1" *ngIf="monitors">
            <div class="col-6">
              <label for="monitorSearch" class="col-form-label">LE MONITEUR</label>
              <div id="monitorSearch" class="input-group mb-3">
                <select id="selectMonitor" class="input-group form-control form-control-lg form-control-borderless col-md-9 update-modal"
                        formControlName="monitorId">
                  <option [ngValue]="null" disabled>Choisir un moniteur</option>
                  <option *ngFor="let m of monitors" [ngValue]="m.id">{{m.lastname}} {{m.firstname}}</option>
                </select>
              </div>
            </div>
            <div class="col-4">
              <label for="boxType" class="col-form-label">SELECTIONNER LA BOITE</label>
              <select id="boxType" name="hourAt" formControlName="boxType"
                      class="form-control form-control-lg form-control-borderless overflow-auto" >
                <option value="MANUELLE">MANUELLE</option>
                <option value="AUTOMATIQUE">AUTOMATIQUE</option>
              </select>
            </div>
          </div>
          <p class="alert alert-danger ml-4 mr-4 p-0" role="alert" *ngIf="submited && f.form.value.monitorId == null">
            Merci de choisir le moniteur !
          </p>
        </div>

        <div>
          <div class="form-row ml-4 p-1 mt-0">
            <div class="input-group raw mb-1">
              <div class="col-4">
                <label for="inputDateAt" class="col-form-label">PREMIER JOUR</label>
                <input id="inputDateAt" type="date" class="form-control form-control-lg form-control-borderless update-modal"
                       name="dateAt" formControlName="dateAt" required aria-describedby="basic-addon2" [disabled]="true">
              </div>
              <div class="col-4">
                <label for="inputDateEnd" class="col-form-label">DERNIER JOUR</label>
                <input id="inputDateEnd" type="date" class="form-control form-control-lg form-control-borderless update-modal"
                       name="dateEnd" formControlName="dateEnd" required aria-describedby="basic-addon2">
              </div>
            </div>

          </div>
          <p class="alert alert-danger ml-4 mr-4 p-0" role="alert" *ngIf="submited && f.form.value.dateAt == null">
            Merci de définir la date du début du calendrier !
          </p>
          <p class="alert alert-danger ml-4 mr-4 p-0" role="alert" *ngIf="submited && (f.form.value.dateEnd == null)">
            Merci de définir la date de la fin du calendrier !
          </p>
        </div>

        <div>
          <div class="form-row ml-4 p-1 mt-0">
            <div class="input-group raw mb-1">
              <div class="col-4">
                <label for="selectHour" class="col-form-label">DEBUT DE LA JOURNEE</label>
                <select id="selectHour" name="hourAt" formControlName="hourAt"
                        class="form-control form-control-lg form-control-borderless overflow-auto update-modal">
                  <option *ngFor="let h of hours" [ngValue]="h">{{h}}</option>
                </select>
              </div>
              <div class="col-4">
                <label for="selectHourEnd" class="col-form-label">FIN DE LA JOURNEE</label>
                <select id="selectHourEnd" name="hourEnd" formControlName="hourEnd"
                        class="form-control form-control-lg form-control-borderless overflow-auto update-modal">
                  <option *ngFor="let h of hours" [ngValue]="h">{{h}}</option>
                </select>
              </div>
            </div>

          </div>
          <p class="alert alert-danger ml-4 mr-4 p-0" role="alert" *ngIf="submited && (f.form.value.hourAt == null || f.form.value.hourAt.length < 4)">
            Merci de définir la date de fin !
          </p>
          <p class="alert alert-danger ml-4 mr-4 p-0" role="alert" *ngIf="submited && (f.form.value.hourEnd == null || f.form.value.hourEnd.length < 4)">
            Merci de définir l'heure de fin !
          </p>
        </div>

        <div>
          <div class="form-row ml-4 p-1 mt-0">
            <div class="input-group raw mb-1">
              <div class="col-4">
                <label for="hourBreakAt" class="col-form-label">DEBUT DE PAUSE</label>
                <select id="hourBreakAt" name="hourBreakAt" formControlName="hourBreakAt"
                        class="form-control form-control-lg form-control-borderless overflow-auto update-modal">
                  <option *ngFor="let h of breakHours" [ngValue]="h">{{h}}</option>
                </select>
              </div>
              <div class="col-4">
                <label for="hourBreakEnd" class="col-form-label">FIN DE PAUSE</label>
                <select id="hourBreakEnd" name="hourBreakEnd" formControlName="hourBreakEnd"
                        class="form-control form-control-lg form-control-borderless overflow-auto update-modal">
                  <option *ngFor="let h of breakHours" [ngValue]="h">{{h}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">ANNULER</button>
          <button id="btn-validate-edit" type="button" class="btn btn-primary update-modal" type="submit" (click)="isSubmited(f.form)">VALIDER</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div id="modal-calendar-created" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="alert alert-success" role="alert">
      <h4 class="alert-heading">Le calendrier a été créé !</h4>
      <hr>
      <p class="mb-0">Les heures doublons n'ont pas été créées !
        <button type="button" class="btn btn-primary ml-4">OK</button>
      </p>
    </div>
  </div>
</div>
