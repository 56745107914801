<div class="container mt-4">
  <h4 *ngIf="!iIsArchived">LISTE DES INSCRIPTIONS <span *ngIf="iPageStudent">({{iPageStudent.totalElements}})</span></h4>
  <h4 *ngIf="iIsArchived">LISTE DES ELEVES ARCHIVES <span *ngIf="iPageStudent">({{iPageStudent.totalElements}})</span></h4>

  <div id="search" class="input-group mb-3">
    <input type="text" id="inputStudent" #studentInput type="search"
           class="form-control form-control-lg form-control-borderless col-md-8 update-modal"
           placeholder="Rechercher un éléve"
           (input)="onSearchChange($event.target)">
  </div>


  <table class="table">
    <thead>
    <tr class="row">
      <th scope="col" class="col">#</th>
      <th scope="col" class="col">NOM</th>
      <th scope="col" class="col">PRENOM</th>
      <th scope="col" class="col">TELEPHONE</th>
      <th scope="col" class="col">FORMULE</th>
    </tr>
    </thead>
    <tbody>
    <tr class="row" *ngFor="let s of iStudents" (click)="consultStudent(s.id)" [ngClass]="{'new-student': s.status === 'TO_CREATE'}">
      <td class="col">{{s.registrationDate | date:'dd/MM/yyyy HH:mm'}}</td>
      <td class="col">{{s.lastname}}</td>
      <td class="col">{{s.firstname}}</td>
      <td class="col">{{s.phoneNumber}}</td>
      <td class="col">{{s.formula.name}}</td>
    </tr>
    </tbody>
    <tfoot>
    <app-pagination *ngIf="iPageStudent" [iEmpty]="iPageStudent.empty" [iFirst]="iPageStudent.first"
                    [iLast]="iPageStudent.last" [iNumber]="iPageStudent.number" [iTotalPages]="iPageStudent.totalPages"
                    (currentPageChanged)="onChangeStudentPage($event)" >
    </app-pagination>
    </tfoot>
  </table>
</div>
