import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-board-monitor',
  templateUrl: './board-monitor.component.html',
  styleUrls: ['./board-monitor.component.css']
})
export class BoardMonitorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }
}
