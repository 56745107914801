import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {ToolsService} from '../_services/tools.service';

declare let $ : any;

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {

  articles: any;
  showArticle: boolean = false;
  showFormulas: boolean = false;
  formulas: any;

  constructor(private fb:UntypedFormBuilder, private toolsService: ToolsService) {
  }

  ngOnInit(): void {
  }

  onShowBoxType() {
  }
}
