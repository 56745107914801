import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StudentService} from '../../../_services/student.service';
import {MonitorService} from '../../../_services/monitor.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {EventService} from '../../../_services/event.service';
import {Monitor, EventRequest} from '../../../models/eventRequest';
import * as moment from 'moment';

declare let $: any;

@Component({
  selector: 'app-event-new',
  templateUrl: './event-new.component.html',
  styleUrls: ['./event-new.component.css']
})
export class EventNewComponent implements OnInit {

  monitors: Monitor[] = [];
  newEventStudents: any;
  popupError?: string;
  newEventForm: UntypedFormGroup = this.resetForm();
  hours: string[] = ["07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00",
    "17:00", "18:00", "19:00", "20:00", "21:00"]
  submited: boolean = false;
  isMobilePhone: boolean = false;

  @Input() newEventDriving: any;
  @Output() newEventDrivingChanged: EventEmitter<any> = new EventEmitter();

  constructor(private fb:UntypedFormBuilder, private studentService: StudentService, private monitorService: MonitorService,
              private eventService: EventService) {
    if(screen.width < 577) {
      this.isMobilePhone = true;
    } else {
      this.isMobilePhone = false;
    }
  }

  ngOnInit(): void {
  }

  initNewEventForm(date: string, hour: string, monitors: Monitor[]) {
    this.monitors = monitors;
    if (!this.hours.includes(hour)) {
      hour = "";
    }
    this.newEventForm = this.fb.group({
      id: ["", Validators.min(0)],
      dateAt: [date, Validators.required],
      hourAt: [hour, [Validators.required, Validators.minLength(4)]],
      boxType: ["MANUELLE", Validators.required],
      monitorId: this.monitors.length == 1 ? [this.monitors[0].id, Validators.required] : [null, Validators.required],
      studentId: [null],
      description: [null]})
  }

  searchStudent(name: string) {
    this.studentService.searchStudentByName(name, 0, false).subscribe(
      data => {
        this.newEventStudents = data.content;
      },
      err => {
        this.popupError = err;
      }
    );
  }

  onCreateNewEvent(): void {
    let event = this.newEventForm?.value;

    let isPermis = event.boxType == "PERMIS";
    let eventRequest: EventRequest = new EventRequest( "0", moment.utc(event.dateAt + "T" + event.hourAt).format('YYYY-MM-DDTHH:mm'),
      event.boxType, event.monitorId, isPermis ? null : event.studentId, event.description);
    this.eventService.createEvent(eventRequest).subscribe(
      data => {
        this.newEventDrivingChanged.emit(data);
      },
      err => {
        this.popupError = err.error.message;
      }
    );

  }

  ngAfterViewInit() {
    $("#newEventModal").on("hidden.bs.modal", () => {
      this.submited = false;
      this.newEventForm = this.resetForm();
      $("#studentInput").val("")
      this.newEventStudents = null;
      this.popupError = "";
    });
  }

  isSubmited(form: UntypedFormGroup) {
    this.submited = true;
  }

  resetForm() : UntypedFormGroup {
    return this.fb.group({
      dateAt: [null, Validators.required],
      hourAt: [null, Validators.required],
      monitorId: [null, Validators.required],
      boxType: ["MANUELLE", Validators.required],
      id: [null],
      description: [null]
    });
  }
}
